import React from "react";
import PropTypes from "prop-types";
import { Typography, Icon } from "components";
import { useToggleOpen } from "helpers";
import { Link } from "react-router-dom";
import { clsx } from "helpers";

const DropDown = ({ title = "", menuList = [], className }) => {
  const { node, isOpen, toggleOpen } = useToggleOpen();

  return (
    <div ref={node}>
      <div
        onClick={toggleOpen}
        className={clsx(
          "relative flex items-center h-full cursor-pointer",
          className
        )}
      >
        <Typography
          type="h3"
          color="white"
          noMargin
          className="pr-4 border-l border-white-500 pl-5 uppercase"
        >
          {title}
        </Typography>
        <Icon
          type="hand"
          size="large"
          color={isOpen ? "tertiary" : "secondary"}
        />

        {isOpen ? (
          <div
            className="min-w-full bg-primary z-10 origin-top-right absolute right-0 top-full mt-10 border border-secondary shadow-lg bg-white divide-y divide-gray-100 focus:outline-none w-40"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            <div>
              {menuList.map(
                ({ label, path = "", className = "", props = {} }) => (
                  <Link
                    key={label}
                    to={path}
                    className={clsx(
                      "flex-col text-white group flex items-center justify-center px-4 py-2 text-xs border-b border-secondary",
                      className
                    )}
                    role="menuitem"
                    tabIndex="-1"
                    {...props}
                  >
                    {label}
                  </Link>
                )
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  menuList: PropTypes.array.isRequired,
  className: PropTypes.string,
};
DropDown.defaultProps = {
  className: undefined,
};
export default DropDown;
