import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { paginateLimit, pick } from "helpers";
import { request } from "services";
import { EMPLOYEES_QUERIES } from "queries";
import View from "./AdminUsersPage";

const defaultPostMessage = {
  nom: "",
  email: "",
  prenom: "",
  telephone: "",
  role: "",
};
const defaultPostMessageKeys = Object.keys(defaultPostMessage);

const AdminUsersPage = () => {
  const { loading, error, data = {}, fetchMore, refetch } = useQuery(
    EMPLOYEES_QUERIES,
    {
      variables: {
        first: paginateLimit,
        page: 1,
      },
    }
  );
  const [state, setState] = useState([]);
  const [filteredByRole, setFilteredByRole] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isPost, setIsPost] = useState(true);
  const [openRules, setOpenRules] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [postData, setPostData] = useState(defaultPostMessage);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => {
      const newData = data?.employes?.data || [];
      setState(newData);
      setFilteredByRole(newData);
    });
  const handleSearch = ({ target: { value } }) =>
    fetchMore({ variables: { query: `%${value}%` } }).then(({ data }) =>
      setState(data)
    );
  const handleDelete = () =>
    request({
      path: "employe",
      body: { data: selected },
      method: "DELETE",
    }).then(() =>
      refetch().then((res) => {
        const newData = res.data?.employes?.data || [];

        setSelected([]);
        setState(newData);
        setFilteredByRole(newData);
        handleFilterRole(newData, selectedRole);
      })
    );
  const handleViewClick = (data) => {
    setPostData(data);
    setOpen(true);
    setIsPost(false);
  };
  const hanleSidebar = (roleId) => {
    setSelectedRole(roleId);
    handleFilterRole(state, roleId);
  };
  const handleRules = () => {
    setOpenRules(true);
  };
  const handleFilterRole = (data, roleId) =>
    setFilteredByRole(
      !roleId ? data : data.filter((e) => e.user?.roles[0]?.id == roleId)
    );
  const handleConfirm = () => {
    const data = pick(postData, defaultPostMessageKeys);

    if (!Object.values(data).every((e) => !!e?.length)) return;
    if (!isPost) data.id = postData.id;
    request({ path: `employe`, body: data }).then(() => {
      refetch().then((res) => {
        const newData = res.data?.employes?.data || [];
        setOpen(false);
        setPostData(defaultPostMessage);
        setState(newData);
        handleFilterRole(newData, selectedRole);
      });
    });
  };
  const handleCreate = () => {
    setOpen(true);
    setIsPost(true);
    setPostData(defaultPostMessage);
  };
  const handleCancel = () => {
    setOpen(false);
    setPostData(defaultPostMessage);
  };
  const onChange = ({ target: { name, value } }) =>
    setPostData({ ...postData, [name]: value });
  useEffect(() => {
    if (!error) {
      const newData = data?.employes?.data || [];
      setState(newData);
      setFilteredByRole(newData);
    }
  }, [loading]);

  return (
    <View
      open={open}
      data={data}
      isPost={isPost}
      postData={postData}
      selected={selected}
      openRules={openRules}
      selectedRole={selectedRole}
      filteredByRole={filteredByRole}
      setOpen={setOpen}
      onChange={onChange}
      setPostData={setPostData}
      setSelected={setSelected}
      handleRules={handleRules}
      hanleSidebar={hanleSidebar}
      handleCreate={handleCreate}
      handleCancel={handleCancel}
      setOpenRules={setOpenRules}
      handleConfirm={handleConfirm}
      handlePaginate={handlePaginate}
    />
  );
};

const connectedAdminHomePage = DashboardLayout(AdminUsersPage);
export { connectedAdminHomePage as AdminUsersPage };
