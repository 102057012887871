import { Typography, CustomCheckbox } from "components";
import {
  lotDatas,
  ordinalize,
  spaceCurrency,
  adhesionStepsNb,
  formFields,
} from "helpers";

const ExtraInfos = ({
  data: { manager, fiscalite, souscription_pack } = {
    manager: {},
    fiscalite: {},
    souscription_pack: false,
  },
}) =>
  [
    { label: "PACK TRANQUILLITÉ", checked: souscription_pack },
    { label: "SCI", checked: manager?.sci },
    {
      label: fiscalite?.nom || formFields.fiscalityLater.label,
      checked: true,
    },
  ].map(({ label, checked }) => (
    <CustomCheckbox
      key={label}
      label={label}
      checked={checked}
      className="mb-2"
    />
  ));

const priceSuffix = ["montant_travaux", "budget_asl", "pack", "montant_total"];
const squareSuffix = ["superficie"];
const ordinalizeSuffix = ["etage"];

export const LotTableInfo = ({ data = {}, excludes = [] }) => (
  <div className="flex flex-col w-1/2 pt-6 px-7">
    <Typography color="white" variant="h2" noMargin>
      {data.copropriete?.nom}
    </Typography>
    <Typography color="white" variant="subtitle1">
      {data.copropriete?.adresse}
    </Typography>
    <div className="mt-2">
      {Object.keys(lotDatas)
        .filter((e) =>
          e == "pack" ? data["souscription_pack"] : !excludes.includes(e)
        )
        .map((key) => {
          const isPrice = priceSuffix.includes(key);

          let val = data[key] != undefined ? data[key] : "";
          val = isPrice ? spaceCurrency(val, ".") : val;

          return (
            <Typography
              key={key}
              noMargin
              color="secondary"
              variant="subtitle2"
              className="mt-4"
            >
              {lotDatas[key]} :{" "}
              <span className="text-white">
                {`${val}${
                  priceSuffix.includes(key)
                    ? "€"
                    : squareSuffix.includes(key)
                    ? "m²"
                    : ordinalizeSuffix.includes(key)
                    ? ordinalize(data[key])
                    : ""
                }`}
              </span>
            </Typography>
          );
        })}
      <div className="mt-8">
        <ExtraInfos data={data} />
      </div>
    </div>
  </div>
);

export const Steps = ({ step }) =>
  Array.from({ length: adhesionStepsNb }, (v, i) => i + 1).map((i) => (
    <li key={i}>
      <div className="relative pb-6 select-none">
        {i != adhesionStepsNb ? (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          ></span>
        ) : (
          ""
        )}
        <div className="relative flex space-x-3">
          <div>
            <span
              className={`h-8 w-8 rounded-full border border-secondary ${
                step == i ? "bg-tertiary text-primary" : "bg-primary text-white"
              } flex items-center justify-center`}
            >
              {i}
            </span>
          </div>
        </div>
      </div>
    </li>
  ));
