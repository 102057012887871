import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { toast } from "react-toastify";
import { history } from "stores";
import { isCgp, isEmployee, arrayToObject } from "helpers";
import {
  clientSteps,
  publicRoute,
  privateRoute,
  redirectClientRoute,
  clientHasBeenValidate,
} from "./index";
import { RouteList } from "./routeList";

const useAccessControl = (
  user,
  roles,
  permissionsList = [],
  path,
  computedPath,
  userIsClient
) => {
  const isClientStepsPath = clientSteps.includes(computedPath);
  let pathname = user
    ? userIsClient
      ? privateRoute.clientDashboard
      : privateRoute.home
    : publicRoute.login;
  let grantAccess = false;
  const userHasPermission = arrayToObject(user?.allPermissions);

  if (user && roles) {
    if (
      !!(
        user?.roles &&
        (roles === "*" || roles?.includes(user?.roles[0]?.name))
      ) ||
      isCgp(user)
    ) {
      grantAccess = true;
      if (userIsClient) {
        if (clientHasBeenValidate(user)) {
          grantAccess = !isClientStepsPath;
          pathname = grantAccess ? pathname : privateRoute.clientDashboard;
        } else {
          pathname = redirectClientRoute(user);
          grantAccess = isClientStepsPath && path === pathname;
        }
      } else if (isClientStepsPath) grantAccess = false;
    } else if (roles === "*") grantAccess = true;
  } else if (!user && !roles) grantAccess = true;
  return [grantAccess, pathname, userHasPermission];
};

const PrivateRoute = ({
  components,
  roles,
  title,
  user,
  permissionsList,
  ...rest
}) => {
  const userIsClient = !isEmployee(user);
  const [grantAccess, pathname, userHasPermission] = useAccessControl(
    user,
    roles,
    permissionsList,
    rest?.location?.pathname,
    rest?.computedMatch?.path,
    userIsClient
  );
  const Component = !roles
    ? components
    : userIsClient
    ? components.client
    : components.admin;

  return (
    <Route
      {...rest}
      render={(props) => {
        const crumbs = RouteList.filter(({ path }) =>
          props.match.path.includes(path)
        ).map(({ path, ...rest }) => {
          const params = Object.keys(props.match.params);

          return {
            path: params.length
              ? params.reduce(
                  (path, param) =>
                    path.replace(
                      `:${param}`,
                      props.match.params[param] ? props.match.params[param] : ""
                    ),
                  path
                )
              : path,
            ...rest,
            nom: rest.title == ":nom" ? props?.match?.params["nom"] : null,
          };
        });

        return grantAccess ? (
          <Component
            {...props}
            user={user}
            title={title}
            crumbs={crumbs}
            alert={rest?.alert}
            userHasPermission={userHasPermission}
          />
        ) : (
          <Redirect
            to={pathname}
            from={props.location?.pathname}
            user={user}
            alert={rest?.alert}
            userHasPermission={userHasPermission}
          />
        );
      }}
    />
  );
};

export default ({ user }) => (
  <Router history={history}>
    <Switch>
      {RouteList.map((props, key) => (
        <PrivateRoute key={key} exact {...props} user={user} alert={toast} />
      ))}
      <Redirect to="/" />
    </Switch>
  </Router>
);
