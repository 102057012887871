import React from "react";
import Img from "components/Img";
import { Link } from "react-router-dom";

const PublicLayout = (Component) => ({ alert }) => (
  <div className="min-h-screen bg-white flex">
    <div className="auth-bg tablet:hidden relative w-0 flex-1 border-primary border-r-8" />
    <div className="relative flex-1 flex flex-col w-1/2 justify-center py-12 px-4 sm:px-5 lg:flex-none lg:px-20">
      <div className="px-8 tablet:px-0">
        <Link to="/login" className="block mb-12">
          <Img className="m-auto" src="logo.png" alt="ASL" />
        </Link>
        <Component alert={alert} />
      </div>
    </div>
  </div>
);
export default PublicLayout;
