import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { request, websockets } from "services";
import {
  isvalidPassword,
  isValidConfirmPassword,
  formMessageList,
} from "helpers";
import { MEMBER_QUERIES } from "queries";
import View from "./Member";

const Member = ({
  match: {
    params: { id },
  },
  alert,
}) => {
  const { loading, error, data = {}, refetch } = useQuery(MEMBER_QUERIES, {
    variables: { id: parseInt(id) },
  });
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(data);
  const [viewUrl, setViewUrl] = useState(false);
  const [selected, setSelected] = useState([]);

  const handleViewClick = (url) => {
    setOpen(true);
    setViewUrl(url);
  };
  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => updateState(data));
  const handleUpload = async ({ name, file, data }) => {
    try {
      const body = new FormData();

      body.append(name, file);

      await request({
        path: data?.id
          ? `document/replace/${data.id}`
          : `document/attach/${id}?model=lot`,
        body,
        multiform: true,
      });

      const res = await refetch();

      updateState(res.data);
    } catch (error) {}
  };
  const handleContact = () =>
    (window.location.href = `mailto:${state.manager?.email}`);
  const onChange = ({ target: { name, value } }) =>
    setState({ ...state, [name]: value });
  const handleSubmit = (data) => {
    request({
      path: `message`,
      body: {
        titre: "ASL",
        documents: selected,
        ...data,
        manager_id: 1,
      },
    });
  };
  const handleResetPasswordSubmit = (
    { password, password_confirmation } = {},
    email,
    cb
  ) => {
    if (!isvalidPassword(password))
      return alert.error(formMessageList.password);
    if (!isValidConfirmPassword(password, password_confirmation))
      return alert.error(formMessageList.confirmPasswordDifferent);
    if (!confirm("Confirmez-vous le changement de mots de passe ?")) return;
    request({
      path: `account/reset`,
      body: {
        email,
        password,
        password_confirmation,
      },
    }).then(cb);
  };
  const updateState = (data = {}) =>
    data?.lots?.data ? setState(data?.lots?.data[0]) : "";

  useEffect(() => {
    if (!error) updateState(data);
  }, [loading]);

  useEffect(() => {
    websockets.lotStepChange(() => {
      refetch()?.then(({ data }) => {
        updateState(data);
      });
    });
  }, []);
  const manager = state?.manager;

  return (
    <View
      data={data}
      state={state}
      manager={manager}
      handleContact={handleContact}
      handleViewClick={handleViewClick}
      handleResetPasswordSubmit={handleResetPasswordSubmit}
      onChange={onChange}
      handleUpload={handleUpload}
      setSelected={setSelected}
      handlePaginate={handlePaginate}
      handleSubmit={handleSubmit}
      open={open}
      setOpen={setOpen}
      viewUrl={viewUrl}
    />
  );
};

const connectedMember = DashboardLayout(Member);
export { connectedMember as Member };
