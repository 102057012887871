import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { DashboardLayout } from 'layouts';
import { Button, Modal, Input, Typography, TableMessage } from 'components';
import { paginateLimit, formFields } from 'helpers';
import { MESSAGES_QUERIES } from 'queries';
import { request } from 'services';

const defaultPostMessage = {
  titre: '',
  contenu: '',
};
const Messages = () => {
  const { loading, error, data = {}, fetchMore, refetch } = useQuery(
    MESSAGES_QUERIES,
    {
      variables: {
        first: paginateLimit,
        page: 1,
      },
    }
  );
  const [state, setState] = useState(data);
  const [isPost, setIsPost] = useState(true);
  const [postData, setPostData] = useState(defaultPostMessage);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => setState(data));
  const handleSearch = ({ target: { value } }) =>
    fetchMore({ variables: { query: `%${value}%` } }).then(({ data }) =>
      setState(data)
    );
  const handleViewClick = (data) => {
    setPostData(data);
    setIsPost(false);
    setOpen(true);
  };

  const handleDelete = () =>
    request({
      path: `messagePredefini`,
      body: { data: selected },
      method: 'DELETE',
    }).then(() =>
      refetch().then(({ data }) => {
        setSelected([]);
        setState(data);
      })
    );
  const handleConfirm = () => {
    if (postData.titre.length && postData.contenu.length)
      request({
        path: 'messagePredefini',
        body: postData,
      }).then(() => {
        refetch().then(({ data }) => {
          setState(data);
          setOpen(false);
          setPostData(defaultPostMessage);
        });
      });
  };
  const onChange = ({ target: { name, value } }) =>
    setPostData({ ...postData, [name]: value });

  useEffect(() => {
    if (!error) setState(data);
  }, [loading]);

  return (
    <>
      <div className="grid grid-cols-12 gap-x-4 mobile:flex mobile:flex-col">
        <div className="admin-sidebar col-span-3 mobile:mb-4">
          <div className="border border-white px-4 py-2">
            <Input
              onChange={handleSearch}
              name="search"
              iconName="search"
              placeholder="Rechercher"
              color="white"
              iconProps={{ color: 'white' }}
              placeholderColor="white"
              noBorder
              noMargin
            />
          </div>
        </div>
        <div className="col-span-9 gap-4">
          {state?.messagePredefinis?.data ? (
            <TableMessage
              selected={selected}
              onCheck={setSelected}
              onViewClick={handleViewClick}
              handlePaginate={handlePaginate}
              messagePredefinis={state.messagePredefinis}
            />
          ) : (
            ''
          )}
          <div className="flex justify-end mt-10 gap-8">
            {selected.length ? (
              <Button
                color="fill-tertiary"
                label="Supprimer"
                onClick={handleDelete}
                className="flex-1"
              />
            ) : (
              ''
            )}
            <Button
              color="tertiary"
              label="Créer un message"
              uppercase
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        setOpen={setOpen}
        onConfirm={handleConfirm}
        cancelText="ANNULER"
        confirmText={isPost ? 'CONFIRMER' : 'MODIFIER'}
      >
        <>
          <Typography variant="h2" color="tertiary" className="text-center">
            {`${isPost ? 'Ajouter' : 'Modifier'} un nouveau message`}
          </Typography>
          <Typography color="secondary" className="mt-5">
            Titre
          </Typography>
          <Input
            name={formFields.title.name}
            type="text"
            color="white"
            placeholderColor="secondary"
            placeholder={formFields.title.label}
            value={postData.titre}
            onChange={onChange}
          />
          <Typography color="secondary" className="mt-10">
            Contenu
          </Typography>
          <Input
            name={formFields.content.name}
            type="textarea"
            color="white"
            placeholderColor="secondary"
            placeholder={formFields.content.label}
            className="border h-32"
            value={postData.contenu}
            onChange={onChange}
          />
        </>
      </Modal>
    </>
  );
};

const connectedMessages = DashboardLayout(Messages);
export { connectedMessages as Messages };
