import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon, Typography } from "./index";
import { clsx } from "helpers";

const PaninateButton = ({ onClick, rotate = 0, disabled }) => (
  <p
    onClick={onClick}
    className={clsx(
      "relative inline-flex items-center px-3 py-2 rounded border border-secondary text-sm font-medium bg-primary",
      disabled ? "opacity-50 cursor-default" : "hover:opacity-70 cursor-pointer"
    )}
  >
    <Icon type="triangle" size="small" color="secondary" rotate={rotate} />
  </p>
);

const Pagination = ({ count, onChange, defaultPage, perPage, className }) => {
  const [total, setTotal] = useState(Math.ceil(count / perPage));
  const [currPage, setCurrPage] = useState(defaultPage);

  const handleChange = (index) => {
    if (index < 1 || index > total) return;
    setCurrPage(index);
    onChange(index);
  };
  const handleNextPage = () => handleChange(currPage + 1);
  const handlePrevPage = () => handleChange(currPage - 1);

  useEffect(() => setTotal(Math.ceil(count / perPage)), [count, perPage]);

  return total > 1 ? (
    <div
      className={clsx(
        "sm:flex-1 sm:flex sm:items-center sm:justify-end select-none",
        className
      )}
    >
      <div>
        <nav className="relative z-0 inline-flex rounded-md shadow-sm gap-2">
          <PaninateButton onClick={handlePrevPage} disabled={currPage === 1} />
          {Array.from({ length: total }, (v, i) => {
            const index = i + 1;

            return (
              <Typography
                key={index}
                noMargin
                onClick={() => handleChange(index)}
                color="white"
                className={clsx(
                  "cursor-pointer rounded border-secondary relative inline-flex items-center px-4 py-2 border text-sm font-medium",
                  currPage === index
                    ? "text-primary bg-secondary hover:opacity-70"
                    : "text-secondary bg-primary hover:opacity-70"
                )}
              >
                {index}
              </Typography>
            );
          })}
          <PaninateButton
            onClick={handleNextPage}
            disabled={currPage === total}
            rotate="180"
          />
        </nav>
      </div>
    </div>
  ) : (
    ""
  );
};

Pagination.propTypes = {
  count: PropTypes.number,
  perPage: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  defaultPage: PropTypes.number,
};
Pagination.defaultProps = {
  count: 0,
  perPage: 0,
  defaultPage: 1,
};
export default Pagination;
