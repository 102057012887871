import React, { useState } from "react";
import {
  Typography,
  Input,
  Radio,
  Checkbox,
  Modal,
  Button,
  Icon,
} from "components";
import { formFields } from "helpers";
import { slogan } from "config";
import { generatedPdf } from "helpers";
import { request } from "services";

const ClientStepsPage = ({
  onChange,
  handleCheck,
  state = {},
  onNextPress,
  updateUser,
} = {}) => {
  const [open, setOpen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refusal, setRefusal] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };
  const _handleCheck = (v, e) => {
    setOpen(v === 0);
    handleCheck(v, e);
  };
  const handleNext = () => setOpen(2);
  const handleRefusal = (val) => setRefusal(val);
  const handleStart = () => {
    onNextPress(async () => {
      setLoading(true);

      try {
        const generated = generatedPdf(state?.id);

        for (let index = 0; index < generated.length; index++)
          await fetch(generated[index]?.url);
        await request({ path: `lot/start-sign/${state.id}` });

        updateUser();
      } catch (err) {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div>
        <Input
          name="montant_travaux"
          type="text"
          placeholderColor="white"
          color="white"
          onChange={onChange}
          placeholder={`Prix des travaux : ${state.montant_travaux}€`}
          disabled
        />
      </div>
      <div>
        <Input
          name="budget_asl"
          type="text"
          placeholderColor="white"
          color="white"
          onChange={onChange}
          placeholder={`Budget ASL : ${state.budget_asl}€`}
          disabled
        />
      </div>
      <div className="mb-8">
        <Typography color="tertiary" className="mb-2">
          {`${formFields.pack.label} ${state.pack}€`}
        </Typography>
        <Radio
          defaultValue={state.souscription_pack}
          containerClass="mb-4"
          items={[
            { label: "Oui", name: 1 },
            { label: "Non", name: 0 },
          ]}
          name={formFields.pack.name}
          variant="primary"
          inline
          onChange={_handleCheck}
        />
      </div>
      <div>
        <Input
          name="prix_total"
          type="text"
          placeholderColor="white"
          color="white"
          onChange={onChange}
          placeholder={`MONTANT TOTAL DES TRAVAUX : ${
            state.montant_travaux +
            state.budget_asl +
            (state.souscription_pack ? state.pack : 0)
          }€`}
          className="mobile:hidden"
          disabled
        />
        <Input
          name="prix_total"
          type="text"
          placeholderColor="white"
          color="white"
          onChange={onChange}
          placeholder="MONTANT TOTAL DES TRAVAUX"
          className="hidden mobile:flex"
          disabled
        />
        <p className="hidden mobile:flex text-white text-2xl justify-center">
          {state.montant_travaux +
            state.budget_asl +
            (state.souscription_pack ? state.pack : 0)}
          €
        </p>
      </div>
      <div className="w-full mt-10">
        <Button
          label="SUIVANT"
          color="secondary"
          full
          halfPadding
          className="w-2/4 mobile:w-full"
          onClick={handleNext}
        />
        <Button
          label="SIGNALER UNE ERREUR"
          color="fill-secondary"
          className="mt-6"
          halfPadding
          btnClass="text-xs mobile:w-full"
        />
      </div>
      <Modal open={!!open} onCancel={handleCancel} showCancel={!!refusal}>
        <div className="h-full">
          {open == 2 ? (
            <div className="flex items-center justify-between flex-col h-2/3">
              <div className="bg-primary pt-5">
                <Typography
                  color="white"
                  className="relative intro-public-asl inline-block mt-4 margin py-2 uppercase italic"
                >
                  {slogan}
                </Typography>
              </div>
              <Icon type="logoBlock" size="big" className="my-8" />
              <Typography variant="h3" color="white" className="text-center">
                Merci, vous allez maintenant procéder <br />à la signature des
                documents
              </Typography>
              <div className="my-8">
                <Button
                  disabled={!!loading}
                  className="m-auto"
                  label="COMMENCER"
                  color="secondary"
                  onClick={handleStart}
                />
              </div>
            </div>
          ) : (
            <div className="w-2/3 m-auto">
              <div>
                <Typography
                  variant="h2"
                  color="tertiary"
                  className="border-l-4 pl-5 border-tertiary uppercase"
                >
                  Refus pack tranquillité
                </Typography>
                <Checkbox
                  value="mr"
                  containerClass="mb-4 mt-8"
                  items={[
                    {
                      name: "validate_refusal",
                      label:
                        "Je déclare avoir pris connaissance du Pack Tranquillité et renonce <br /> à la souscription dudit contrat et aux bénéfices qui y sont attachés.",
                    },
                  ]}
                  checked={refusal}
                  name="valide_asl"
                  variant="primary"
                  className="mx-4"
                  inlineIcon
                  onChange={handleRefusal}
                />
                <Typography
                  variant="subtitle1"
                  color="white"
                  className="mt-4 mb-12 pl-5"
                ></Typography>
                <div className="flex pl-5 mt-12">
                  <Icon
                    type="attention"
                    color="tertiary"
                    customSize={{ width: "auto", height: "auto" }}
                  />
                  <Button
                    className="m-auto"
                    label="VALIDER"
                    color="secondary"
                    onClick={() => refusal && handleCancel()}
                    disabled={!refusal}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ClientStepsPage;
