import React, { useState } from 'react';
import { Input, Radio, Modal, Button } from 'components';
import { formFields, gendersObj, isMr } from 'helpers';

const Infos = ({ onChange, handleCheck, state = {}, errors = [], setOpen }) => (
  <>
    <div className="flex gap-x-8">
      <Radio
        disabled
        defaultValue={isMr(state.sexe) ? gendersObj.mr : gendersObj.ms}
        inline
        items={[
          { label: 'Mr', name: gendersObj.mr },
          { label: 'Mme', name: gendersObj.ms },
        ]}
        name={formFields.gender.name}
        variant="primary"
        onChange={handleCheck}
        error={errors.includes(formFields.gender.name)}
      />
      {!!setOpen && (
        <Button label="Second adhérent" onClick={() => setOpen(true)} />
      )}
    </div>
    <div className="flex gap-x-8 mobile:flex-col">
      <div className="w-1/2 mobile:w-full">
        <Input
          name={formFields.firstName.name}
          type="text"
          placeholderColor="white"
          color="white"
          placeholder="Prénom"
          value={state.prenom}
          onChange={onChange}
          error={errors.includes(formFields.firstName.name)}
          disabled
        />
      </div>
      <div className="w-1/2 mobile:w-full">
        <Input
          name={formFields.lastName.name}
          type="text"
          placeholderColor="white"
          color="white"
          placeholder={state.nom}
          onChange={onChange}
          error={errors.includes(formFields.lastName.name)}
          disabled
        />
      </div>
    </div>
    <div className="mt-4">
      <Input
        name={formFields.email.name}
        type="email"
        placeholderColor="white"
        color="white"
        placeholder={state.email}
        onChange={onChange}
        error={errors.includes(formFields.email.name)}
        disabled
      />
    </div>
    <div className="mt-8">
      <Input
        name={formFields.phone.name}
        type="tel"
        placeholderColor="white"
        color="white"
        placeholder={state.telephone}
        onChange={onChange}
        error={errors.includes(formFields.phone.name)}
        disabled
      />
    </div>
  </>
);

const ClientStepsPage = (props = {}) => {
  const [open, setOpen] = useState(false);
  const { clients = [] } = props?.state;
  const second = clients?.find((e) => e.id != props?.state?.client_id) || null;

  return (
    <>
      <Infos {...props} setOpen={second ? setOpen : false} />
      <Modal open={!!open} setOpen={setOpen}>
        <>
          <Infos {...props} state={second} />
        </>
      </Modal>
    </>
  );
};

export default ClientStepsPage;
