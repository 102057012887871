import { Link } from "react-router-dom";
import { Typography, Icon, Button, Input, Carousel } from "components";
import { pluralize } from "helpers";
import { privateRoute } from "routes";

const dataList = {
  nombre_lots: {
    title: "LOT",
    iconType: "lots",
    to: privateRoute.copropertyLots,
  },
  nombre_adherents: {
    title: "ADHÉRENT",
    iconType: "people",
    to: privateRoute.copropertyMembers,
  },
};
export const CopropertyInfo = ({
  apiId,
  userHasPermission,
  coproperty = {},
  isValidata = false,
  onApiIdChange,
  handleValidate,
  handleValidateApiId,
}) => (
  <div className="grid grid-cols-2 mobile:grid-cols-1">
    <div className="mobile:h-60">
      <Carousel dots arrows list={coproperty?.images?.map((e) => e.url)} />
    </div>
    <div className="flex flex-col relative border-2 border-secondary pt-10 pl-5 pb-5">
      <div className="absolute -top-2px left-0 w-full flex justify-end pr-8">
        <div className="bg-tertiary text-primary py-1 px-2 rounded-b-lg text-xs">
          {isValidata ? "VALIDÉ" : "VALIDATION EN ATTENTE"}
        </div>
      </div>
      <Typography color="white" variant="h2" noMargin className="font-bold">
        {coproperty.nom}
      </Typography>
      <Typography color="white">{coproperty.adresse}</Typography>
      <div className="mt-4">
        {Object.keys(dataList).map((key) => (
          <Link
            key={key}
            to={dataList[key].to(coproperty.id, coproperty.nom)}
            className="flex items-center mb-4 text-secondary"
          >
            <Icon
              type={dataList[key].iconType}
              className="mr-3"
              color="secondary"
            />
            {`${coproperty[key]} ${pluralize(
              dataList[key].title,
              coproperty[key],
              "S"
            )}`}
          </Link>
        ))}
        <Link
          to={privateRoute.documentsPage(coproperty.id)}
          className="flex items-center text-secondary"
        >
          <Icon type="folder" className="mr-3" color="secondary" />
          {`${coproperty.documents?.length || 0} ${pluralize(
            "DOCUMENT",
            coproperty.documents?.length || 0,
            "S"
          )}`}
        </Link>
      </div>
      {!userHasPermission ? (
        ""
      ) : isValidata ? (
        <div className="flex justify-between items-center mt-3">
          <div className="flex items-center text-secondary">
            <Icon type="wheel" className="mr-3" color="secondary" />
            <Input
              value={apiId}
              color="white"
              bordered
              noMargin
              onChange={onApiIdChange}
            />
          </div>
          <Button
            small
            noMargin
            color="fill-tertiary"
            label="VALIDATION API"
            className="ml-auto mr-5"
            btnClass="text-_8"
            onClick={handleValidateApiId}
          />
        </div>
      ) : (
        <Button
          small
          noMargin
          color="fill-tertiary"
          label="VALIDER LA COPROPRIÉTÉ"
          className="ml-auto mr-5 mt-3"
          onClick={handleValidate}
        />
      )}
    </div>
  </div>
);

export const WithCoproListSidebar = ({
  children,
  curr = null,
  coproprietes = [],
  isArchive,
  setIsArchive,
  onSelect,
  isLink = false,
}) => (
  <div className="grid grid-cols-12 gap-x-4 tablet:flex tablet:flex-col">
    <div className="col-span-3 admin-sidebar tablet:flex tablet:gap-4 mobile:flex-col">
      <div className="tablet:w-1/2 mobile:w-full">
        <Typography
          noMargin
          className="flex justify-between cursor-pointer bg-white px-6 py-2 font-medium"
          onClick={() => setIsArchive && setIsArchive(false)}
        >
          TOUTES
          <span>{!isArchive ? "-" : "+"}</span>
        </Typography>
        {!!(!isArchive && coproprietes.length) && (
          <div className="border border-white pt-4 pb-1 px-6">
            {coproprietes?.map(({ id, nom }, index) =>
              isLink ? (
                <Link
                  key={id}
                  to={privateRoute.coproperty(id, nom)}
                  className="text-white block mb-3 text-sm font-light hover:text-tertiary-500"
                >
                  {nom}
                </Link>
              ) : (
                <div
                  key={id}
                  className={`block mb-3 text-sm font-light hover:text-tertiary-500 cursor-pointer ${
                    curr?.id == id ? "text-tertiary" : "text-white "
                  }`}
                  onClick={() => onSelect && onSelect(index)}
                >
                  {nom}
                </div>
              )
            )}
          </div>
        )}
      </div>
      <div className="mt-4 mobile:mb-4 tablet:w-1/2 mobile:w-full tablet:mt-0">
        <Typography
          noMargin
          className="flex justify-between cursor-pointer bg-white px-6 py-2 font-medium"
          onClick={() => setIsArchive && setIsArchive(true)}
        >
          ARCHIVES
          <span>{isArchive ? "-" : "+"}</span>
        </Typography>
        {!!(isArchive && coproprietes.length) && (
          <div className="border border-white pt-4 pb-1 px-6">
            {coproprietes.map(({ id, nom }, index) =>
              isLink ? (
                <Link
                  key={id}
                  to={privateRoute.coproperty(id, nom)}
                  className="text-white block mb-3 text-sm font-light hover:text-tertiary-500"
                >
                  {nom}
                </Link>
              ) : (
                <div
                  key={id}
                  className={`block mb-3 text-sm font-light hover:text-tertiary-500 cursor-pointer ${
                    curr?.id == id ? "text-tertiary" : "text-white "
                  }`}
                  onClick={() => onSelect && onSelect(index)}
                >
                  {nom}
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>

    <div className="col-span-9 gap-4 tablet:mt-4">{children}</div>
  </div>
);
