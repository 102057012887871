import { Link, NavLink } from "react-router-dom";
import { Typography, Input } from "components";
import { privateRoute } from "routes";
import { employeeRoleList } from "helpers";

export const AdminMenuLists = ({ menuList = {}, onClick }) => (
  <ul>
    {Object.keys(menuList).map((key) => {
      const menu = menuList[key];

      return (
        <li key={key}>
          <NavLink
            to={menu.path}
            className="block sidebar-menu pl-4 my-2 mr-10 py-2 text-sm text-primary uppercase"
            activeClassName="sidebar-menu-selected"
            onClick={onClick}
            {...menu.props}
          >
            <span className="pl-6">{menu.label}</span>
          </NavLink>
        </li>
      );
    })}
  </ul>
);

export const AdminSidebar = ({
  isSuperAdminOrAdmin = false,
  handleSearch,
  copropertyId,
  copropertyName,
  location,
}) => {
  const links = [
    {
      title: "LISTE LOTS",
      to: privateRoute.copropertyLots(copropertyId, copropertyName),
    },
    {
      title: "LISTE DES ADHÉRENTS",
      to: privateRoute.copropertyMembers(copropertyId, copropertyName),
    },
    {
      title: "TÉLÉCHARGER UN DOCUMENT",
      to: privateRoute.copropertyAddDocument(copropertyId, copropertyName),
    },
    ...(isSuperAdminOrAdmin
      ? [
          {
            title: "PERMISSIONS",
            to: privateRoute.copropertyPermissions(
              copropertyId,
              copropertyName
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <Link
        to={privateRoute.coproperties}
        className="tablet:block hidden text-white mb-2"
      >
        {"<"}
      </Link>
      <div className="border border-white px-4 py-2">
        <Input
          onChange={handleSearch}
          name="search"
          iconName="search"
          placeholder="Rechercher"
          color="white"
          iconProps={{ color: "white" }}
          placeholderColor="white"
          noBorder
          noMargin
        />
      </div>
      <div className="mt-4 border border-white pb-4">
        <Typography
          color="primary"
          className="py-2 pl-4 bg-white font-medium uppercase"
        >
          <Link to={privateRoute.coproperty(copropertyId, copropertyName)}>
            {copropertyName}
          </Link>
        </Typography>
        <div className="mobile:block tablet:flex tablet:gap-x-4 tablet:px-4 pl-4 mt-4">
          {links.map(({ title, to }) => (
            <Link
              key={title}
              to={to}
              className={`${
                location === to ? "text-tertiary font-medium" : "text-white"
              } block mb-3 text-sm font-light hover:text-tertiary-500`}
            >
              {title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export const SidebarLotName = ({ data, curr, onClick }) => (
  <>
    {data
      ? data.map((elem, index) => (
          <Link
            to={privateRoute.coproperty(elem.id, elem.copropriete?.nom)}
            key={elem.id}
            className={`py-2 px-6 bg-white mb-1 block ${
              curr == elem.id ? "bg-tertiary" : ""
            }`}
            onClick={(e) => onClick && onClick(e, index)}
          >
            <Typography noMargin>{elem.copropriete?.nom}</Typography>
          </Link>
        ))
      : ""}
  </>
);

const EmployeeMenuList = [
  { label: "GESTION DES ROLES", to: privateRoute.rulesAndPermissionsPage },
];

export const EmployeWrapper = ({
  curr = 0,
  selectedRole,
  hanleSidebar,
  children,
}) => (
  <>
    <div className="grid grid-cols-12 gap-x-4 mobile:flex mobile:flex-col">
      <div className="admin-sidebar col-span-3">
        <Link
          to={privateRoute.users}
          className={`block border text-white border-white px-4 py-2 font-medium ${
            curr === 0 ? "bg-white text-primary" : "bg-primary"
          }`}
        >
          LISTE UTILISATEURS
        </Link>
        {curr === 0 ? (
          <div className="border border-white border-t-0 p-4 mb-2">
            <Typography
              noMargin
              color="white"
              className="py-1 mb-1 block cursor-pointer font-light"
              color={!selectedRole ? "tertiary" : "white"}
              onClick={() => hanleSidebar(null)}
            >
              TOUTES
            </Typography>
            {employeeRoleList.map((elem, index) => (
              <Typography
                key={elem.id}
                noMargin
                variant="subtitle1"
                className="py-1 mb-1 block cursor-pointer font-light"
                color={selectedRole == elem.id ? "tertiary" : "white"}
                onClick={() => hanleSidebar && hanleSidebar(elem.id, index)}
              >
                {elem.label}
              </Typography>
            ))}
          </div>
        ) : (
          ""
        )}
        {EmployeeMenuList.map(({ label, to }) => (
          <Link
            key={label}
            to={to}
            className={`block border text-white border-white px-4 py-2 font-medium my-2`}
          >
            {label}
          </Link>
        ))}
      </div>
      <div className="col-span-9 gap-4">{children}</div>
    </div>
  </>
);

export const Crumbs = ({ list = [] }) =>
  list
    .filter(({ title }) => title)
    .map(({ title, nom, path }, index) => (
      <Link key={index} to={path}>
        {`${nom || title}${path != list[list.length - 1]?.path ? " > " : ""}`}
      </Link>
    ));
