import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { websockets } from "services";
import { MEMBERS_QUERIES } from "queries";
import { privateRoute } from "routes";
import { TableMembers, WithCoproListSidebar } from "components";

const AdminDocumentPage = ({
  userHasPermission,
  history,
  match: { params: { coproId } = {} } = {},
}) => {
  const { loading, error, data = {}, fetchMore, refetch } = useQuery(
    MEMBERS_QUERIES,
    {
      fetchPolicy: "network-only",
    }
  );
  const [state, setState] = useState([]);
  const [curr, setCurr] = useState(null);

  const handleSidebar = (index) => {
    const curr = state[index];

    if (!curr) return;

    history.push(privateRoute.members(curr.id));
    setCurr(curr);
  };

  useEffect(() => {
    if (!loading && !error && data) {
      const newData = data?.coproprietes?.data || [];

      setState(newData);
      setCurr(coproId ? newData.find((e) => e.id == coproId) : newData[0]);
    }
  }, [data]);

  useEffect(() => {
    websockets.lotStepChange(() => {
      refetch()?.then((data) => setState(data));
    });
  }, []);

  return (
    <WithCoproListSidebar
      curr={curr}
      coproprietes={data?.coproprietes?.data}
      onSelect={handleSidebar}
    >
      {curr ? (
        <TableMembers
          userHasPermission={!!userHasPermission.validate_coproperty}
          autoPaginate
          showMembersList
          refetch={refetch}
          coproperty={curr}
          lots={{ data: curr.lots }}
        />
      ) : (
        ""
      )}
    </WithCoproListSidebar>
  );
};

const connectedAdminHomePage = DashboardLayout(AdminDocumentPage);
export { connectedAdminHomePage as MembersPage };
