import { Fragment } from "react";
import { overrideTailwindClasses } from "tailwind-override";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import { clsx } from "helpers";

const Modal = ({
  children,
  open,
  showCancel = true,
  setOpen,
  title,
  onConfirm,
  onCancel,
  fullView,
  className,
  cancelText = "Annuler",
  confirmText = "Confirmer",
}) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog
      as="div"
      static
      className="fixed z-10 inset-0 overflow-y-auto"
      open={open}
      onClose={() => {}}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className={overrideTailwindClasses(`
              inline-block align-bottom bg-primary rounded-lg px-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:w-full sm:p-6
              ${
                fullView
                  ? "max-w-screen-2xl max-h-screen-2xl w-screen h-screen"
                  : "pt-5 pb-4 sm:my-8 sm:max-w-2xl "
              } ${className}`)}
          >
            {showCancel && (
              <div className="sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    onCancel ? onCancel() : setOpen(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-3 w-3" aria-hidden="true" />
                </button>
              </div>
            )}
            <div className="mt-3 text-center h-full w-full sm:mt-0 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {title}
              </Dialog.Title>
              <div className="h-full w-full rounded overflow-hidden">
                {children}
              </div>
            </div>
            {onConfirm ? (
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onConfirm}
                >
                  {confirmText}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    onCancel ? onCancel() : setOpen(false);
                  }}
                >
                  {cancelText}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

Modal.propTypes = {
  title: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  children: PropTypes.element.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  fullView: PropTypes.bool,
};
Modal.defaultProps = {
  title: undefined,
  fullView: false,
  onConfirm: undefined,
  onConfirm: undefined,
  cancelText: "Annuler",
  confirmText: "Confirmer",
};

export default Modal;
