import { gql } from "@apollo/client";

export const MESSAGES_QUERIES = gql`
  query($first: Int, $page: Int) {
    messagePredefinis(first: $first, page: $page) {
      paginatorInfo {
        total
        perPage
        hasMorePages
      }
      data {
        id
        titre
        contenu
      }
    }
  }
`;
