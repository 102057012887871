import React from "react";
import {
  Table,
  Modal,
  Radio,
  Button,
  Checkbox,
  SidebarUsers,
  AdminUserModal,
  EmployeWrapper,
} from "components";
import { tableHeaders, paginateProps } from "helpers";
import { privateRoute } from "routes";

export default ({
  open,
  data,
  selected,
  openRules,
  setOpen,
  isPost,
  postData,
  setPostData,
  onChange,
  handleRules,
  setSelected,
  handleCancel,
  setOpenRules,
  handleCreate,
  selectedRole,
  hanleSidebar,
  handleConfirm,
  filteredByRole,
  handlePaginate,
}) => (
  <EmployeWrapper
    handleRules={handleRules}
    selectedRole={selectedRole}
    hanleSidebar={hanleSidebar}
  >
    <div className="col-span-9 gap-4">
      <Table
        selected={selected}
        header={tableHeaders.users}
        onCheck={setSelected}
        // onViewClick={handleViewClick}
        paginateProps={paginateProps({
          data: data.employes,
          onChange: handlePaginate,
        })}
        content={filteredByRole?.map(
          ({ id, nom, prenom, email, telephone, user = {} } = {}) => ({
            id,
            nom,
            prenom,
            telephone,
            email,
            roleName: <div className="uppercase">{user?.roles[0]?.name}</div>,
            role: user?.roles[0]?.id,
            viewTitle: "MODIFIER",
            fullName: `${nom?.toUpperCase()} ${prenom?.toUpperCase()}`,
            view: privateRoute.employe(
              id,
              `${nom?.toUpperCase()} ${prenom?.toUpperCase()}`
            ),
          })
        )}
      />
      <div className="flex justify-end mt-10 gap-8">
        {selected?.length ? (
          <Button
            color="fill-secondary"
            label="SUPPRIMER"
            className="flex-1"
            onClick={() =>
              window.confirm("Confirmer ?") ? handleDelete() : ""
            }
          />
        ) : (
          ""
        )}
        <Button color="fill-secondary" label="CRÉER" onClick={handleCreate} />
      </div>
    </div>
    {openRules ? (
      <Modal open={openRules} setOpen={setOpenRules}>
        <>
          <Radio
            value="mr"
            containerClass="mb-4"
            items={[
              { label: "Super admin", name: 1 },
              { label: "Admin", name: 2 },
              { label: "Utilisteur", name: 3 },
            ]}
            name="role"
            variant="primary"
          />
          {data?.permissions?.map((e) => (
            <Checkbox
              value="mr"
              name="gender"
              label={e.display_name}
              checked={true}
              variant="fill-primary"
              // onChange={() => toggleCheck(data.id)}
              inline
            />
          ))}
        </>
      </Modal>
    ) : (
      <AdminUserModal
        open={open}
        setOpen={setOpen}
        isPost={isPost}
        postData={postData}
        onChange={onChange}
        setPostData={setPostData}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
    )}
  </EmployeWrapper>
);
