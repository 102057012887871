import React from "react";
import { connect } from "react-redux";
import { slogan } from "config";
import { userActions } from "stores";
import { request } from "services";
import { ClientLayout } from "layouts";
import View from "./Intro";

const mapStateToProps = (state) => {
  const { authentication } = state;
  const { user } = authentication;

  return {
    user,
  };
};

const IntroStepPage = ({ dispatch, user, data }) => {
  const handleSubmit = () => {
    let newUser = { ...user };
    let { lots } = user.linkable;
    const index = lots.findIndex((e) => (e.id = data.id));

    if (index < 0) return;
    return request({ path: `lot/step/${data.id}/0` }).then(() => {
      lots[index].etape_adhesion = 1;
      newUser.linkable.lots = lots;
      dispatch(userActions.update(newUser));
    });
  };

  return <View data={data} slogan={slogan} handleSubmit={handleSubmit} />;
};

export default connect(mapStateToProps)(
  ClientLayout(IntroStepPage, { hideWrapper: true })
);
