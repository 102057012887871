import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import {
  Typography,
  Icon,
  Button,
  Pagination,
  Carousel,
  WithCoproListSidebar,
} from "components";
import { privateRoute } from "routes";
import { pluralize, paginateLimit, truncate } from "helpers";
import { COPROPERTIES_QUERIES } from "queries";
import { request } from "services";

const Coproperties = () => {
  const { error, data = {}, fetchMore, refetch } = useQuery(
    COPROPERTIES_QUERIES,
    {
      variables: { first: paginateLimit, page: 1 },
      fetchPolicy: "network-only",
    }
  );
  const [state, setState] = useState(data);
  const [isArchive, setIsArchive] = useState(false);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => setState(data));

  const handleArchiveCheck = async (id) => {
    try {
      await request({
        path: `copropriete/archive/${id}`,
      });
      const newData = await refetch();
      setState(newData);
    } catch (error) {}
  };

  useEffect(() => {
    if (!error) setState(data);
  }, [data]);
  const coproprietes = state?.coproprietes?.data
    ? state.coproprietes.data?.filter((e) =>
        isArchive ? e.archived : !e.archived
      )
    : [];

  return (
    <WithCoproListSidebar
      isLink
      isArchive={isArchive}
      coproprietes={coproprietes}
      setIsArchive={setIsArchive}
    >
      <div className="col-span-7">
        <div className="grid gap-6 auto-rows-fr">
          {coproprietes?.map(
            ({
              id,
              nom,
              archived,
              description,
              adresse,
              nombre_lots,
              nombre_adherents,
              images = [],
              validate,
            }) => (
              <div
                key={id}
                className="grid grid-flow-col grid-cols-2 bg-white mobile:flex mobile:flex-col"
              >
                <div className="grid grid-flow-col mobile:grid-cols-1 grid-cols-2">
                  <div
                    className={`relative border-2 mobile:h-60 border-${
                      validate ? "white" : "tertiary"
                    }`}
                  >
                    {!validate ? (
                      <div className="absolute top-0 left-0 w-full flex justify-center z-10">
                        <div className="bg-tertiary text-primary py-1 px-2 rounded-b-lg">
                          NOUVEAU
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <Carousel dots arrows list={images?.map((e) => e.url)} />
                  </div>
                  <div className="p-4 mobile:hidden">
                    <Typography variant="h2" noMargin>
                      ASL - {nom}
                    </Typography>
                    <Typography variant="subtitle2" className="mb-4">
                      {adresse}
                    </Typography>
                    <Link
                      to={privateRoute.copropertyLots(id, nom)}
                      className="flex items-center text-sm text-primary mb-2"
                    >
                      <Icon type="lots" className="mr-3" />
                      {`${nombre_lots} ${pluralize("LOT", nombre_lots, "S")}`}
                    </Link>
                    <Link
                      to={privateRoute.copropertyMembers(id, nom)}
                      className="flex items-center text-sm text-primary"
                    >
                      <Icon type="people" className="mr-3" />
                      {`${nombre_adherents} ${pluralize(
                        "ADHÉRENT",
                        nombre_adherents,
                        "S"
                      )}`}
                    </Link>
                  </div>
                </div>
                <div className="relative col-span-4 flex flex-col justify-between overflow-hidden p-4">
                  <input
                    type="checkbox"
                    checked={!!archived}
                    disabled={!!archived}
                    className="absolute top-1 right-1 focus:ring-transparent cursor-pointer rounded checked:border checked:border-secondary disabled:opacity-75 disabled:cursor-move mobile:hidden"
                    onChange={(e) => {
                      if (window.confirm("Mettre en archive ?"))
                        handleArchiveCheck(id);
                      else {
                        console.log(e.target.checked);
                        e.target.checked = !e?.target?.checked;
                      }
                    }}
                  />
                  <span className="absolute h-5/6 left-0 top-1/6 border-l border-primary" />
                  <Typography
                    className="mobile:hidden"
                    dangerouslySetInnerHTML={{
                      __html: truncate(description, 30),
                    }}
                  />
                  <Typography
                    variant="h2"
                    noMargin
                    className="hidden mobile:block my-5 text-center"
                  >
                    ASL - {nom}
                  </Typography>
                  <div className="flex justify-between">
                    <Button
                      to={privateRoute.coproperty(id, nom)}
                      label={validate ? "CONSULTER" : "VALIDER"}
                      noMargin
                      className="m-auto"
                      btnClass="py-2"
                    />
                    <Button
                      to={privateRoute.copropertyMembers(id, nom)}
                      label="ADHÉRENTS"
                      noMargin
                      color="fill-primary"
                      className="m-auto mobile:hidden"
                      btnClass="py-2"
                    />
                    <Button
                      to={privateRoute.copropertyAddDocument(id, nom)}
                      label="DOCUMENT"
                      noMargin
                      color="fill-primary"
                      className="m-auto mobile:hidden"
                      btnClass="py-2"
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {!!coproprietes && (
          <Pagination
            perPage={state.coproprietes?.paginatorInfo?.perPage}
            count={state.coproprietes?.paginatorInfo?.total}
            onChange={handlePaginate}
            className="mt-10"
          />
        )}
      </div>
    </WithCoproListSidebar>
  );
};

const connectedCoproperties = DashboardLayout(Coproperties);
export { connectedCoproperties as Coproperties };
