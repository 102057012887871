import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { Button, Typography, Carousel, SidebarLotName } from "components";
import { privateRoute } from "routes";
import { MEMBER_LOTS_QUERIES } from "queries";

const Member = ({ memberId }) => {
  const { loading, error, data = {} } = useQuery(MEMBER_LOTS_QUERIES, {
    variables: {
      adherent_id: parseInt(memberId),
    },
  });
  const [state, setState] = useState([]);

  useEffect(() => {
    if (error || !data?.lots?.data) return;
    setState(
      data?.lots?.data.map((newData) => ({
        ...newData,
        manager: {
          ...newData.manager,
          ...newData.manager.actitvites,
          ...newData.manager.user,
        },
      }))
    );
  }, [loading]);

  return (
    <>
      {state.length ? (
        <div>
          <div className="grid grid-cols-12 gap-x-4">
            <div className="col-span-3">
              <SidebarLotName data={state} />
            </div>
            <div className="grid grid-cols-4 col-span-9 gap-4">
              {state.map(({ id, copropriete = {} }) => (
                <div key={id} className="col-span-2">
                  <div
                    style={{ height: 250 }}
                    className="rounded-tl-md overflow-hidden"
                  >
                    <Carousel
                      dots
                      arrows
                      list={copropriete?.images?.map((e) => e.url)}
                    />
                  </div>
                  <div className="border-2 border-secondary p-4">
                    <Typography color="white" variant="h1">
                      {copropriete?.nom}
                    </Typography>
                    <Typography color="white">
                      {copropriete?.adresse}
                    </Typography>
                    <div className="flex justify-center mt-10">
                      <Button
                        to={privateRoute.coproperty(id, copropriete?.nom)}
                        color="fill-secondary"
                        label="ACCEDER"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default DashboardLayout(Member);
