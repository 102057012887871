import Echo from "laravel-echo";
import { wsHost } from "config";
import { ucFirst } from "helpers";
import { authHeader } from "services";
import { useState, useEffect } from "react";
import { socketUrl, socketKey, socketCluster } from "config";

// window.Pusher = require("pusher-js");

const names = [
  { name: "reload-client", event: "reloadClient" },
  { name: "logout-client.{id}", event: "logout-client.{id}" },
  { name: "lot-step-change", event: "lotStepChange" },
  // {
  //   name: "document-signed.{clientUserId}",
  //   event: "document-signed.{clientUserId}",
  // },
  { name: "document-signed", event: "documentSigned" },
  {
    name: "lot-validation.{clientUserId}",
    event: "lot-validation.{clientUserId}",
  },
  {
    name: "update-profile.{userId}",
    event: "update-profile.{userId}",
  },
  { name: "send-message", event: "sendMessage" },
  { name: "notif", event: "notif" },
];

// export const websockets = {
//   init: () =>
//     (window.Echo = new Echo({
//       broadcaster: "socket.io",^app
//       host: wsHost,
//       auth: {
//         headers: { ...authHeader(), Accept: "application/json" },
//       },
//     })),
//   ...names.reduce((obj, { name, event }) => {
//     obj[event] = (cb, id) =>
//       window.Echo.private(name).listen(`${ucFirst(event)}${id || ""}`, cb);

//     return obj;
//   }, {}),
// };

export const websockets = {
  // init: () =>
  //   (window.Echo = new Echo({
  //     broadcaster: "socket.io",
  //     host: wsHost,
  //     auth: {
  //       headers: { ...authHeader(), Accept: "application/json" },
  //     },
  //   })),
  ...names.reduce((obj, { name, event }) => {
    obj[event] = (cb, id) => console.log;

    return obj;
  }, {}),
};

export function useSubscription(channelName, events, callback) {
  const [channel, setChannel] = useState(null);
  const [data, setData] = useState(null);

  const trigger = (data) => channel?.trigger(`client-${events}`, data);

  useEffect(async () => {
    if (!window.Echo) {
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: socketKey,
        cluster: "eu",
        forceTLS: true,
      });
    }

    const newChannel = window.Echo.channel(channelName);

    setChannel(newChannel);

    function handleEvent(newData) {
      setData(newData);
      callback && callback(newData, trigger);
    }
    if (newChannel) {
      if (Array.isArray(events)) {
        events.forEach((event) => newChannel.listen(event, handleEvent));
      } else {
        newChannel.listen(events, handleEvent);
      }
    }

    return () => {
      if (newChannel) {
        // newChannel.unbind_all();
        // pusher.unsubscribe(channelName);
      }
    };
  }, [events]);
  return [data, trigger];
}
