import React from 'react';
import { Input, Typography } from 'components';

const ClientStepsPage = ({ onChange, state = {} } = {}) => (
  <>
    <div className="relative">
      <Input
        name="numero_lot"
        type="text"
        placeholderColor="white"
        color="white"
        placeholder={`N° de lot EDD : ${state.numero_edd}`}
        onChange={onChange}
        disabled
      />
      <div
        id="edd-info"
        className="inline-block absolute top-0 right-0 h-full border border-tertiary rounded-md mobile:relative mobile:h-1"
      >
        <Typography color="tertiary" noMargin>
          État descriptif de division
        </Typography>
      </div>
    </div>
    <div className="mb-8">
      <Input
        name="superficie"
        type="text"
        placeholderColor="white"
        color="white"
        placeholder={`Superficie : ${state.superficie} m²`}
        onChange={onChange}
        disabled
      />
    </div>
    <div className="mb-8">
      <Input
        name="etage"
        type="text"
        placeholderColor="white"
        color="white"
        placeholder={`Étage : ${state.etage}`}
        onChange={onChange}
        disabled
      />
    </div>
  </>
);

export default ClientStepsPage;
