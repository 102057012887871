import { isArray } from "./convertAndCheck";

export const sciFiles = {
  cni: { name: "cni", label: "" },
  kbis: { name: "kbis", label: "" },
  status: { name: "status", label: "" },
  autorisation: { name: "autorisation", label: "" },
};
export const roles = {
  client: "client",
  user: "utilisateur",
  cgp: "cgp",
  admin: "admin",
  superAdmin: "superAdmin",
  consulting: "consultant",
};
export const employeeRolesObj = {
  superAdmin: { label: "Super Admin", id: 1 },
  admin: { label: "Admin", id: 2 },
  user: { label: "Utilisateur", id: 3 },
  consulting: { label: "Consultation", id: 6 },
};
export const employeeAdminSupRoles = [roles.superAdmin, roles.admin];
export const employeeRoles = [...employeeAdminSupRoles, roles.user];
export const employeeRoleList = Object.keys(employeeRolesObj).map((elem) => ({
  value: elem,
  ...employeeRolesObj[elem],
}));

const _roleId = (user) => (isArray(user?.roles) ? user.roles[0]?.id : null);

export const isSuperAdmin = (user) => _roleId(user) === "1";
export const isAdmin = (user) => _roleId(user) === "2";
export const isUser = (user) => _roleId(user) === "3";
export const isCgp = (user) =>
  user?.linkable_type && user?.linkable_type.includes("Cgp");
export const isClient = (user) => _roleId(user) === "5";
export const isEmployee = (user) =>
  user?.roles && ["1", "2", "3"].includes(_roleId(user));
export const isMember = (user) => isClient(user) || isCgp(user);
export const isSuperAdminOrAdmin = (user) =>
  isSuperAdmin(user) || isAdmin(user);

export const permissions = {
  validate_coproperty: "validate_coproperty",
  send_mail: "send_mail",
  manage_new_users: "manage_new_users",
  view_clients: "view_clients",
  update_account: "update_account",
  send_reset_password: "send_reset_password",
  attribute_coproperty_access: "attribute_coproperty_access",
};

export const memberStatus = {
  waiting: "En Attente",
  validate: "Valide",
};

export const memberIsWaiting = (user) =>
  user?.linkable?.lots[0]?.etat_adhesionFr === memberStatus.waiting;
export const memberIsValidate = (user) =>
  isCgp(user)
    ? true
    : user?.linkable?.lots[0]?.etat_adhesionFr === memberStatus.validate;

export const paginateLimit = 10;
export const paginateProps = ({ data, autoPaginate, onChange }) =>
  autoPaginate
    ? {
        autoPaginate,
        perPage: paginateLimit,
        count: data?.length || data?.data?.length,
      }
    : {
        perPage: data?.paginatorInfo?.perPage,
        count: data?.paginatorInfo?.total,
        onChange,
      };
export const adhesionStepsNb = 8;
export const hasValidateAdhesion = (user = {}) =>
  user?.etape_adhesion > adhesionStepsNb || user?.etat_adhesion > 1;
