import React, { useState } from "react";
import Select from "react-select";
import { Button, Wysiwyg, MultiSelect, Input } from "components";

const customStyles = {
  control: (base) => ({
    ...base,
    fontSize: "1rem",
    background: "transparent",
    border: "none",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
    fontSize: ".8rem",
  }),
};
export const MessageAdmin = ({
  predefinedMessage = [],
  receiver = [],
  onSubmit,
  hideReceiver = false,
}) => {
  const [selection, setSelection] = useState(null);
  const [message, setMessage] = useState({});
  const [title, setTitle] = useState("");

  const handleTitle = (e, val) => setTitle(val);
  const handleMessageSelect = ({ value, label }) => {
    setMessage({ contenu: value, titre: label });
    setTitle(label);
  };
  const handleSubmit = () => {
    if ((!hideReceiver && !selection?.length) || !message.contenu) return;
    onSubmit({
      ...message,
      titre: title ? title : message.titre,
      lots: selection?.map((e) => e.email),
    });
  };
  const updateMessage = (contenu) => setMessage({ ...message, contenu });

  return (
    <>
      <div className="border border-secondary mt-4 px-6 py-3">
        {hideReceiver ? (
          ""
        ) : (
          <div className="border border-secondary rounded-t-lg mb-3">
            <MultiSelect
              onChange={setSelection}
              value={selection}
              options={receiver?.map((elem) => ({
                label: `${elem?.manager?.prenom} ${elem?.manager?.nom}`,
                value: elem?.manager?.id,
                email: elem?.id,
              }))}
            />
          </div>
        )}
        <div className="border border-secondary rounded-t-lg mb-3">
          <Input
            name="budget_asl"
            type="text"
            placeholderColor="white"
            color="white"
            noBorder
            noMargin
            value={title}
            onChange={handleTitle}
            placeholder="Titre"
          />
        </div>
        <div className="border border-secondary rounded-t-lg mb-3">
          <Select
            onChange={handleMessageSelect}
            styles={customStyles}
            placeholder="LISTE DE MESSAGES PRÉDÉFINIS"
            options={predefinedMessage?.data?.map((elem) => ({
              label: elem.titre,
              value: elem.contenu,
            }))}
          />
        </div>
        <Wysiwyg onChange={updateMessage} defaultValue={message?.contenu} />
        <div className="flex mt-5 mb-2 justify-end">
          <div className="relative self-end">
            <Button label="AJOUTER UN DOCUMENT" noMargin />
            <input
              type="file"
              className="absolute cursor-pointer w-full h-full top-0 left-0 opacity-0"
            />
          </div>
        </div>
      </div>

      <div className="flex mt-6 justify-end">
        <Button color="fill-secondary" label="ANNULER" className="mr-4" />
        <Button color="tertiary" label="ENVOYER" onClick={handleSubmit} />
      </div>
    </>
  );
};
