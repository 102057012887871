import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { apiUrl } from "config";
import { authHeader } from "./header";

const methods = ["GET", "POST", "PUT", "DELETE"];
const jsonMethods = ["POST", "PUT", "DELETE"];

export const request = ({
  body,
  path = "",
  method = "POST",
  multiform = false,
}) => {
  const requestOptions = {
    method,
    headers: {
      ...authHeader(),
      ...(!multiform && jsonMethods.includes(method)
        ? {
            "Content-Type": "application/json",
          }
        : {}),
    },
    ...(body ? { body: multiform ? body : JSON.stringify(body) } : {}),
  };

  return fetch(`${apiUrl}/api/${path}`, requestOptions)
    .then((response) =>
      response
        .text()
        .then((text) => {
          const data = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
              // auto logout if 401 response returned from api
              location.reload(true);
            }

            throw (data && data.message) || response.statusText;
          }
          return data;
        })
        .catch((err) => {
          throw err;
        })
    )
    .catch((err) => {
      toast.error(err);
      throw err;
    });
};

Request.propTypes = {
  body: PropTypes.object,
  path: PropTypes.string.isRequired,
  method: PropTypes.oneOf(methods),
};
Request.defaultProps = {
  body: undefined,
  method: "POST",
};
