import React, { useState, useEffect } from "react";
import {
  UserInfo,
  LotInfo,
  SciInfo,
  ConfirmBudget,
  ConfirmDocument,
} from "components/client/steps";
import ClientLayout from "layouts/client";
import { request } from "services";
import { userActions } from "stores";
import { pick, unPick, isFn } from "helpers";
import View from "./Steps";

const contents = [
  {
    title:
      "Veuillez confirmer<br /> la personne représentante, <br />la forme juridique et votre option fiscale",
    subTitle:
      "Vous disposez d’un outil de contact au bas de l’écran, pour nous signaler une erreur.",
    iconName: "usersStep",
    Comp: SciInfo,
  },
  {
    title:
      "Veuillez vérifier <br/>vos informations de civilité <br/>et de contact",
    subTitle:
      "Vous disposez d’un outil de contact au bas de l’écran, pour nous signaler une erreur.",
    iconName: "usersStep",
    Comp: UserInfo,
  },
  {
    title: "Veuillez vérifier <br/>les informations du lot réservé",
    subTitle:
      "Vous disposez d’un outil de contact au bas de l’écran, pour nous signaler une erreur.",
    iconName: "houseStep",
    Comp: LotInfo,
  },
  {
    title: "Veuillez prendre connaissance <br/>du document ci-dessous",
    subTitle:
      "Vous disposez d’un outil de contact au bas de l’écran, pour nous signaler une erreur.",
    iconName: "folderStep",
    Comp: ConfirmDocument,
  },
  {
    title:
      "<span>Merci de vérifier les montants <br/>concernant votre budget travaux</span>",
    subTitle: "",
    iconName: "stackStep",
    Comp: ConfirmBudget,
  },
];
const contentLength = contents.length;
const StepsPage = ({ data = {}, user = {}, dispatch, refetch }) => {
  const [state, setState] = useState({});
  const [currStep, setCurrStep] = useState(1);
  const [files, setFiles] = useState([]);
  const { title = "", subTitle = "", iconName = "", Comp = null } =
    contents[currStep - 1] || contents[0];

  const updateUser = () => {
    const nextStep = currStep + 1;
    let newUser = { ...user };
    const index = newUser.linkable.lots.findIndex((e) => (e.id = data.id));

    if (index < 0) return;

    newUser.linkable.lots[index].etape_adhesion = nextStep;
    if (currStep < contentLength) setCurrStep(nextStep);
    dispatch(userActions.update({ ...newUser, etape: nextStep }, nextStep));
  };
  const handleNext = async (cb) => {
    if (currStep == 4 && !state.pack_document)
      return alert(
        "Veuillez cocher la case (J’ai pris connaissance du pack tranquillité)"
      );
    const formData = new FormData();
    const datas = {
      ...state,
      ...(!state?.fiscalite_id && state.fiscalite
        ? { fiscalite_id: state.fiscalite?.id }
        : {}),
      sciId: state?.sci?.id,
    };
    const stateKeys = Object.keys(datas);

    for (let index = 0; index < stateKeys.length; index++) {
      const name = stateKeys[index];
      formData.append(name, datas[name]);
    }

    files.forEach(({ name, file }, index) =>
      formData.append("docs" + index, file, name)
    );

    try {
      await request({
        path: `lot/step/${data.id}/${currStep}`,
        body: formData,
        multiform: true,
      });
      if (isFn(cb)) return await cb();
      await refetch();
      updateUser();
    } catch (err) {}
  };

  const onChange = ({ target: { name, value } }) =>
    setState({ ...state, [name]: value });
  const handleCheck = (value, { target: { name } }) =>
    setState({ ...state, [name]: value });
  const handleLegalForm = (value) => {
    let data = unPick(state, [
      value == "proper_name" ? "is_sci" : "proper_name",
    ]);

    if (value == "proper_name") data.proper_name = true;
    else data.is_sci = value == "available_sci" ? 1 : 2;

    setState(data);
  };

  const updateState = (data) =>
    setState({
      ...pick(data, [
        "id",
        "sexe",
        "prenom",
        "email",
        "nom",
        "telephone",
        "adresse",
        "numero_lot",
        "superficie",
        "etage",
        "numero_edd",
        "montant_travaux",
        "souscription_pack",
        "budget_asl",
        "clients",
        "sci",
        "pack",
        "fiscalite",
        "copropriete",
        "managerStatus",
        "managerKbis",
        "managerIdCard",
        "managerAutorisation",
      ]),
      ...pick(data.sci, [
        "raison_social",
        "capital",
        "rcs",
        "ville",
        "sci_adresse",
        "sci_code_postal",
        "sci_ville",
      ]),
      client_id: user?.linkable?.id,
      proper_name: data.proper_name || true,
      souscription_pack: data.souscription_pack || true,
      sciAlreadyExisted: !!data.sci,
    });
  const handleUpload = async ({ name, file, data }) => {
    try {
      const body = new FormData();

      body.append(name, file);

      await request({
        path: data?.id
          ? `document/replace/${data.id}`
          : `document/attach/${id}?model=lot`,
        body,
        multiform: true,
      });

      const res = await refetch();

      updateState(res.data);
    } catch (error) {}
  };

  useEffect(() => updateState(data), [data]);

  useEffect(
    () =>
      data.etape_adhesion != undefined
        ? setCurrStep(parseInt(data.etape_adhesion) || 1)
        : "",
    [data.etape_adhesion]
  );

  return (
    <View
      Comp={Comp}
      title={title}
      state={state}
      files={files}
      currStep={currStep}
      subTitle={subTitle}
      iconName={iconName}
      onChange={onChange}
      updateUser={updateUser}
      handleNext={handleNext}
      onNextPress={handleNext}
      setCurrStep={setCurrStep}
      handleCheck={handleCheck}
      handleUpload={handleUpload}
      handleLegalForm={handleLegalForm}
    />
  );
};

const connectedStepsPage = ClientLayout(StepsPage);
export default connectedStepsPage;
