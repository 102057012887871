import { apiUrl } from "config";

export const generatedName = {
  adhesion_asl: "Lettre d’adhésion",
  mandat: "Mandat indivision",
  pack_tranquillite: "Pack tranquillité",
};
export const generatedNameList = Object.values(generatedName);
export const generatedPdf = (id, lot = {}) => [
  {
    originalName: generatedName.adhesion_asl,
    name: "yousign_lettre_id",
    description: "Descriptif du document",
    iconType: "letter",
    is_signed: !!lot.yousign_lettre_id,
    url: `${apiUrl}/pdf?model=lot&id=${id}&doc=lettre_adhesion`,
    created_at: "2021-09-17 05:41:23",
  },
  {
    originalName: generatedName.mandat,
    name: "mandat",
    description: "Descriptif du document",
    iconType: "file",
    is_signed: !!lot.mandat,
    url: `${apiUrl}/pdf?model=lot&id=${id}&doc=mandat_indivision`,
    created_at: "2021-09-17 05:41:09",
  },
  {
    originalName: generatedName.pack_tranquillite,
    name: "yousign_pack_id",
    description: "Descriptif du document",
    iconType: "tinyPack",
    is_signed: !!lot.yousign_pack_id,
    url: `${apiUrl}/pdf?model=lot&id=${id}&doc=pack_tranquillité`,
    created_at: "2021-09-17 05:41:37",
  },
];

export const clientUserTypes = {
  client: 1,
  cgp: 2,
  sci: 3,
};
