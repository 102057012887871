import { gql } from "@apollo/client";

export const STEPS_LOT_QUERIES = gql`
  query($lot_id: Int) {
    lots(id: $lot_id) {
      data {
        id
        pack
        etat_adhesion
        souscription_pack
        numero_lot
        numero_edd
        etage
        superficie
        montant_travaux
        budget_asl
        etape_adhesion
        souscription_pack
        copropriete {
          fiscalites {
            id
            nom
          }
        }
        fiscalite {
          id
          nom
        }

        documents {
          id
          url
          name
          signed
          originalName
        }

        clients {
          id
          nom
          type
          sexe
          email
          prenom
          telephone
        }
        manager {
          id
          nom
          prenom
          sexe
          telephone
          adresse
          yousign_client_id
          sci {
            id
            raison_social
            capital
            ville
            adresse
            rcs
            code_postal
            sci_adresse
            sci_ville
            sci_code_postal
          }
          user {
            id
            email
          }
        }
        managerStatus {
          id
          name
          url
        }
        managerKbis {
          id
          name
          url
        }
        managerIdCard {
          id
          name
          url
        }
        managerAutorisation {
          id
          name
          url
        }
      }
    }
  }
`;
export const MEMBER_LOTS_QUERIES = gql`
  query($adherent_id: Int) {
    lots(manager_id: $adherent_id) {
      data {
        copropriete {
          nom
          adresse
          images {
            id
            url
            type
            name
            signed
            location
            created_at
          }
        }
        id
        souscription_pack
        numero_lot
        numero_edd
        etage
        pack
        superficie
        montant_travaux
        montant_total
        budget_asl
        etape_adhesion
        cgp_can_view
        cgp_receive_email

        fiscalite {
          id
          nom
        }

        clients {
          id
          nom
          sexe
          prenom
        }
        manager {
          id
          nom
          prenom
          sexe
          telephone
          adresse
          cgp {
            id
            prenom
            nom
            sexe
            telephone
          }
          sci {
            id
            raison_social
            capital
            adresse
            ville
            rcs
            code_postal
            sci_adresse
            sci_ville
            sci_code_postal
            user {
              id
              email
            }
          }
          user {
            id
            email
          }
        }
      }
    }
  }
`;
