import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { request, websockets } from "services";
import { PERMISSIONS_QUERIES } from "queries";
import View from "./RulesAndPermissions";

const defaultRequestBody = { id: 1, name: "superAdmin" };

const RulesAndPermissions = ({ alert }) => {
  const { loading, error, data = {}, refetch } = useQuery(PERMISSIONS_QUERIES);
  const [state, setState] = useState([]);
  const [selected, setSelected] = useState([]);
  const [requestBody, setRequestBody] = useState(defaultRequestBody);
  const [selectedRole, setSelectedRole] = useState("1");
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const handleSubmit = async () => {
    try {
      await request({
        path: "role",
        body: {
          ...requestBody,
          permissions: selectedPermissions,
        },
      });
      const newData = await refetch();

      updateState(newData?.data);
      setRequestBody(defaultRequestBody);
      setSelectedRole("1");
      alert(`Role ${requestBody.name} mis à jours`);
    } catch (error) {}
  };

  const handleSelectedRole = (id, e, props) => {
    setSelectedRole(id);
    setRequestBody({
      id,
      name: props.value,
    });
  };
  const updateState = (data = {}) => {
    setState(data?.permissions || []);
    setSelectedPermissions(
      data?.roles
        ?.find((e) => e.id == selectedRole)
        ?.permissions?.map((e) => e.id) || []
    );
  };

  useEffect(() => {
    if (!error) updateState(data);
  }, [loading, selectedRole]);

  useEffect(() => {
    websockets.lotStepChange(() => {
      refetch()?.then(({ data }) => {
        updateState(data);
      });
    });
  }, []);

  return (
    <View
      state={state}
      selected={selected}
      selectedRole={selectedRole}
      selectedPermissions={selectedPermissions}
      setSelected={setSelected}
      handleSubmit={handleSubmit}
      handleSelectedRole={handleSelectedRole}
      setSelectedPermissions={setSelectedPermissions}
    />
  );
};

const connectedRulesAndPermissions = DashboardLayout(RulesAndPermissions);
export { connectedRulesAndPermissions as RulesAndPermissions };
