const passwordMinLength = 6;

export const isvalidPassword = (password) =>
  password?.length >= passwordMinLength;

export const isValidConfirmPassword = (password, confirmPassword) =>
  isvalidPassword(password) && confirmPassword === password;

export const formMessageList = {
  // Success
  informationUpdated: `informations mise à jour`,

  // Errors
  password: `Le mot de passe doit contenir au moins ${passwordMinLength} mots`,
  confirmPasswordDifferent:
    "Le mot de passe est différent de la confirmation de mot de passe",
};
