import { Button, Typography, Modal, Icon, Checkbox, Loading } from "components";
import { yousignGetMemberSignatureUrl } from "helpers";

export default ({
  state,
  open,
  data,
  signature,
  setOpen,
  loading = true,
  cgpInfo,
  onFileChange,
  handleValidate,
  handleSignClick,
  handleModalCancel,
  handleCheck,
  handleSubmit,
}) =>
  loading ? (
    <Loading fullView background="primary" />
  ) : (
    <div className="w-full">
      <div className="grid grid-cols-3 grid-rows-3 gap-x-8 gap-y-14 w-full tablet:grid-cols-2 mobile:grid-cols-1">
        {state.map(
          (
            { url, originalName, description, iconType = "file", signed },
            index
          ) => {
            return (
              <div key={index} className="mb-8 lg:mb-0">
                <div className="flex items-center justify-between border border-secondary mb-0.5 pt-2 pb-5">
                  <div className="flex items-center">
                    <Icon type={iconType} color="white" className="mx-6" />
                    <div>
                      <Typography
                        color="tertiary"
                        variant="subtitle1"
                        noMargin
                        bold
                        className="uppercase"
                      >
                        {originalName}
                      </Typography>
                      <Typography
                        color="white"
                        variant="subtitle2"
                        noMargin
                        bold
                      >
                        {description}
                      </Typography>
                    </div>
                  </div>
                  <div
                    className={`rounded-l text-xs px-7 py-2 ${
                      signed
                        ? "bg-secondary text-white"
                        : "bg-tertiary text-primary"
                    }`}
                  >
                    {signed ? "SIGNÉ" : "EN ATTENTE"}
                  </div>
                </div>
                <div className="grid grid-flow-col grid-cols-3 border border-secondary rounded-b-md">
                  <div
                    className="1/3 text-xs text-white p-2 cursor-pointer"
                    onClick={() => setOpen(url)}
                  >
                    <Typography
                      color="white"
                      variant="subtitle2"
                      noMargin
                      className="flex justify-center items-center"
                    >
                      <span className="mr-3">CONSULTER</span>
                      <Icon type="eye" size="tiny" color="white" />
                    </Typography>
                  </div>
                  <div
                    className="1/3 text-xs text-white p-2 cursor-pointer"
                    onClick={signed ? null : handleSignClick}
                  >
                    <Typography
                      color="white"
                      variant="subtitle2"
                      noMargin
                      className="relative flex justify-center items-center"
                    >
                      <span className="mr-3">SIGNÉ</span>
                      <Icon type="sign" size="tiny" color="white" />
                    </Typography>
                  </div>
                  <div
                    className="1/3 text-xs text-white p-2 border-l border-r border-secondary cursor-pointer"
                    onClick={console.log}
                  >
                    <Typography
                      color="white"
                      variant="subtitle2"
                      noMargin
                      className="relative flex justify-center items-center"
                    >
                      <input
                        type="file"
                        // name={name}
                        accept=".png, .jpg, .jpeg, .pdf"
                        onChange={onFileChange}
                        className="absolute top-0 left-0 cursor-pointer opacity-0 w-full h-full z-10"
                      />
                      <span className="mr-3">TELECHARGER</span>
                      <Icon type="download" size="tiny" color="white" />
                    </Typography>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
      {state?.length ? (
        <Button
          label="Valider"
          color="tertiary"
          className="flex justify-center"
          onClick={handleValidate}
        />
      ) : (
        ""
      )}
      <Modal
        open={!!open}
        setOpen={setOpen}
        onCancel={handleModalCancel}
        fullView
      >
        <>
          {open == 2 ? (
            <div className="flex justify-center gap-y-12 max-w-3xl align-middle flex-col mx-auto h-full mobile:justify-start mobile:mt-8">
              <div className="flex justify-between space-x-10 mx-auto md:flex-row mobile:block mobile:mx-0 mobile:space-x-0">
                <div className="w-full mobile:mb-9">
                  <Typography
                    variant="h2"
                    color="white"
                    className="border-l-4 pl-5 border-tertiary"
                  >
                    Gestion de la relation <br />
                    avec votre conseiller
                  </Typography>
                </div>
                <div className="w-full">
                  <Checkbox
                    checked={!!cgpInfo.cgp_can_view}
                    containerClass="mb-4"
                    items={[
                      {
                        name: "cgp_can_view",
                        label:
                          "Je souhaite qu’il puisse consulter l’ensemble de mes courriers et documents relatifs à l’ASL",
                      },
                    ]}
                    variant="primary"
                    className="mx-4"
                    inlineIcon
                    onChange={handleCheck}
                  />
                  <Checkbox
                    checked={!!cgpInfo.cgp_receive_email}
                    containerClass="mb-4"
                    items={[
                      {
                        name: "cgp_receive_email",
                        label:
                          "Je souhaite qu’il puisse recevoir la copie de mes mails et alertes",
                      },
                    ]}
                    variant="primary"
                    className="mx-4"
                    inlineIcon
                    onChange={handleCheck}
                  />
                  <Checkbox
                    checked={!!cgpInfo.no_cgp}
                    containerClass="mb-4"
                    items={[
                      {
                        name: "no_cgp",
                        label: "Je n’ai pas de conseiller",
                      },
                    ]}
                    variant="primary"
                    className="mx-4"
                    inlineIcon
                    onChange={handleCheck}
                  />
                </div>
              </div>
              <Button
                label="FINALISER"
                color="fill-secondary"
                className="flex justify-center"
                onClick={handleSubmit}
              />
              <footer className="client-footer absolute bottom-0 left-0 w-full bg-primary pb-2 pt-6">
                <div></div>
              </footer>
            </div>
          ) : !signature ? (
            <iframe src={signature ?? open} className="w-full h-full"></iframe>
          ) : (
            <iframe
              className="w-full h-full"
              src={yousignGetMemberSignatureUrl(
                data?.manager?.yousign_client_id
              )}
            ></iframe>
          )}
        </>
      </Modal>
    </div>
  );
