import React from "react";
import { Button, Modal, SidebarLotName, TableDocument } from "components";

const ClientLotDocumentPage = ({
  open,
  curr,
  data,
  onCheck,
  setOpen,
  viewUrl,
  isClient,
  onViewClick,
  hanleSidebar,
  handlePaginate,
  handleValidateLot,
}) => (
  <>
    <div className="grid grid-cols-12 gap-x-4">
      <div className="col-span-3">
        <SidebarLotName data={data} onClick={hanleSidebar} curr={curr?.id} />
      </div>
      <div className="col-span-9 gap-4">
        {curr ? (
          <TableDocument
            manager={curr.manager}
            documents={curr.documents}
            onCheck={onCheck}
            onViewClick={onViewClick}
            handlePaginate={handlePaginate}
          />
        ) : (
          ""
        )}
        {isClient && (
          <div className="flex justify-end mt-10 gap-8">
            <Button
              color="fill-secondary"
              label="SUPPRIMER"
              onClick={handleValidateLot}
            />
          </div>
        )}
      </div>
      <Modal open={open} setOpen={setOpen} fullView>
        <iframe src={viewUrl} className="w-full h-full"></iframe>
      </Modal>
    </div>
  </>
);

export default ClientLotDocumentPage;
