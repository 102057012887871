import React from 'react';
import { Typography, Icon, Button } from 'components';

export default ({ data = {}, slogan, handleSubmit }) => (
  <div className="intro-container bg-primary container flex space-x-10 mx-auto min-h-screen min-w-full justify-center">
    <div className="flex flex-col justify-between space-y-20 items-center w-1/2 min-h-screen px-20 bg-primary mobile:w-full">
      <div>
        <Typography
          color="white"
          className="relative intro-public-asl inline-block mt-4 margin py-2 uppercase italic"
        >
          {slogan}
        </Typography>
      </div>
      <div className="flex flex-1 pb-5 flex-col space-y-20 items-center">
        <Icon
          color="white"
          className="text-white"
          type="logoBlock"
          alt="ASL"
          customSize={{ width: 200 }}
        />
        <Typography
          variant="h3"
          color="white"
          className="relative inline-block my-10 margin py-2 leading-loose text-center font-semibold"
        >
          Bonjour {data?.manager?.prenom} {data?.manager?.nom},<br /> Vous allez
          débuter votre adhésion à l’ASL {data?.copropriete?.adresse},
          <br />
          veuillez procéder à la vérification de l’ensemble de vos informations.
        </Typography>
        <Button
          onClick={handleSubmit}
          color="secondary"
          label="COMMENCER"
          halfPadding
        />
      </div>
    </div>
  </div>
);
