import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { DashboardLayout } from "layouts";
import {
  Carousel,
  Typography,
  Modal,
  LotTableInfo,
  TableDocument,
  FileUpload,
} from "components";
import { truncate, isCgp } from "helpers";
import { request } from "services";

const MEMBERS_QUERIES = gql`
  query($id: Int) {
    lots(id: $id) {
      data {
        id
        pack
        etage
        souscription_pack
        numero_lot
        numero_edd
        superficie
        montant_travaux
        budget_asl
        montant_total
        etat_adhesion
        etape_adhesion
        souscription_pack

        copropriete {
          nom
          adresse
          description
          documents {
            id
            url
            type
            location
            originalName
            created_at
          }
          images {
            id
            url
            type
            location
            originalName
            created_at
          }
        }
        manager {
          id
          nom
          prenom
          sexe
          email
          telephone
          adresse
          sci {
            id
            nom
            sexe
            prenom
            telephone
            user {
              id
              email
            }
          }
          cgp {
            id
            nom
            sexe
            email
            prenom
            telephone
            user {
              id
              email
            }
          }
        }
        documents {
          id
          url
          type
          location
          originalName
          created_at
          details {
            signed
            yousign_file_id
          }
        }
      }
    }
  }
`;

const ClientLotDetailPage = ({
  user = {},
  match: {
    params: { id },
  },
}) => {
  const { loading, error, data = {}, fetchMore } = useQuery(MEMBERS_QUERIES, {
    variables: {
      id: parseInt(id),
    },
  });
  const [state, setState] = useState(data);
  const [open, setOpen] = useState(false);
  const [viewUrl, setViewUrl] = useState(false);
  const [selected, setSelected] = useState([]);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => setState(data));
  const handleSearch = ({ target: { value } }) =>
    fetchMore({ variables: { query: `%${value}%` } }).then(({ data }) =>
      setState(data)
    );
  const handleValidateLot = () =>
    request({ path: `lot/valider-admin/${selected}` });
  const handleViewClick = (data) => {
    setOpen(true);
    setViewUrl(data.url);
  };
  useEffect(() => {
    if (!error && data?.lots) setState(data?.lots?.data[0]);
  }, [loading]);
  const isClient = !isCgp(user);

  return (
    <>
      <div className="grid grid-cols-10">
        <div className="col-span-3 pr-4">
          <div style={{ height: 250 }}>
            <Carousel
              dots
              arrows
              list={state.copropriete?.images?.map((e) => e.url)}
            />
          </div>
          <div className="border-2 border-secondary py-4 px-4">
            <Typography color="white" bold variant="h1">
              {state.copropriete?.nom}
            </Typography>
            <Typography color="white">{state.copropriete?.adresse}</Typography>
            <Typography
              color="white"
              variant="subtitle1"
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: truncate(state.copropriete?.description, 30),
              }}
            />
            <div className="mt-2">
              <LotTableInfo data={state} />
            </div>
          </div>
        </div>
        <div className="col-span-7">
          {state?.documents && (
            <TableDocument
              documents={state.documents}
              onCheck={isClient ? setSelected : undefined}
              onViewClick={handleViewClick}
              handlePaginate={handlePaginate}
            />
          )}
          {isClient && (
            <div className="flex justify-end mt-10 gap-8">
              {/* <FileUpload
              id={id}
              type="copropriete"
              selected={selected}
              setSelected={setSelected}
              refetch={refetch}
            /> */}
            </div>
          )}
        </div>
        <Modal open={open} setOpen={setOpen} fullView>
          <iframe src={viewUrl} className="w-full h-full"></iframe>
        </Modal>
      </div>
    </>
  );
};

export default DashboardLayout(ClientLotDetailPage);
