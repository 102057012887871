import { gql } from "@apollo/client";

export const EMPLOYEES_QUERIES = gql`
  query($copropriete_id: Int, $first: Int, $page: Int) {
    employes(first: $first, page: $page) {
      paginatorInfo {
        perPage
        total
      }
      data {
        id
        nom
        prenom
        telephone
        email
        user {
          id
          roles {
            id
            name
          }
        }
      }
    }
    coproprietes(id: $copropriete_id) {
      data {
        usersId
      }
    }
    permissions {
      id
      name
      display_name
    }
  }
`;

export const EMPLOYE_QUERIES = gql`
  query($id: Int, $copropriete_id: Int, $first: Int, $page: Int) {
    employes(id: $id, first: $first, page: $page) {
      paginatorInfo {
        perPage
        total
      }
      data {
        id
        nom
        prenom
        telephone
        email
        user {
          id
          roles {
            id
            name
          }
          employeCoproprietes
        }
      }
    }
    coproprietes(id: $copropriete_id) {
      data {
        id
        nom
        usersId
      }
    }
    permissions {
      id
      name
      display_name
    }
  }
`;

export const PERMISSIONS_QUERIES = gql`
  {
    permissions {
      id
      name
      display_name
    }
    roles {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;
