import React, { useState } from "react";
import { Button, Modal, Input } from "components";
import { formFields } from "helpers";
import { request } from "services";

export const FileUpload = ({
  id,
  type = "lot",
  refetch,
  selected,
  setSelected,
}) => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState(null);
  const [fileTitle, setFileTitle] = useState(null);

  const onChange = ({ target }) => setFileTitle(target.value);
  const handleConfirm = () => {
    if (!fileTitle || !files || !files[0]) return;
    const formData = new FormData();

    formData.append("docs", files[0], fileTitle);

    request({
      path:
        type == "lot"
          ? `document/attach/${id}?model=${type}`
          : `${type}/attach-documents/${id}`,
      body: formData,
      multiform: true,
    }).then(() => {
      refetch && refetch();
      setOpen(false);
      setFiles(null);
      setFileTitle(null);
    });
  };
  const handleOpenDownload = () => {
    setOpen(true);
    setFileTitle(null);
  };
  const handleDelete = () =>
    request({
      path: "documents",
      method: "DELETE",
      body: {
        documents: selected,
      },
    }).then(() => (refetch ? refetch().then(() => setSelected([])) : ""));

  return (
    <>
      <div className="flex justify-end mt-12">
        {selected.length ? (
          <Button
            color="fill-secondary"
            label="SUPPRIMER"
            className="flex-1 mr-5"
            onClick={handleDelete}
          />
        ) : (
          ""
        )}
        <Button
          noMargin
          color="fill-secondary"
          label="TÉLÉCHARGER"
          onClick={handleOpenDownload}
        />
      </div>
      <Modal open={open} setOpen={setOpen} onConfirm={handleConfirm}>
        <>
          <Input
            name={formFields.title.name}
            type="text"
            color="white"
            placeholderColor="secondary"
            placeholder={formFields.title.label}
            onChange={onChange}
          />
          <div className="relative overflow-hidden">
            <Button
              noMargin
              color={files ? "fill-tertiary" : "fill-secondary"}
              label={files ? "Fichier sélectionné" : "Sélectionner un fichier"}
            />
            <input
              type="file"
              onChange={({ target }) => setFiles(target.files)}
              className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
            />
          </div>
        </>
      </Modal>
    </>
  );
};
