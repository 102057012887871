import React from 'react';
import {
  Typography,
  Radio,
  Checkbox,
  EmployeWrapper,
  Button,
} from 'components';
import { employeeRoleList, roles } from 'helpers';

export default ({
  state = [],
  selected,
  selectedRole,
  setSelected,
  handleSelectedRole,
  selectedPermissions,
  setSelectedPermissions,
  handleSubmit,
}) => (
  <EmployeWrapper curr={null}>
    <div className="col-span-7">
      <div className="flex border border-tertiary pt-4 pb-6 mb-7 mobile:flex mobile:flex-col">
        <div className="relative flex flex-col w-1/2 px-7 mobile:w-full">
          <span className="absolute h-full right-0 top-0 border-r border-tertiary mobile:border-r-0" />
          <div className="flex justify-between">
            <div>
              <Typography color="tertiary" variant="h2" bold>
                ROLES
              </Typography>
              <Radio
                value="mr"
                defaultValue={selectedRole}
                containerClass="mb-4"
                items={employeeRoleList.map(({ value, label, id }) => ({
                  value: roles[value],
                  label,
                  name: id,
                }))}
                name="role"
                variant="primary"
                onChange={handleSelectedRole}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-1/2 px-7 mobile:w-full">
          <Typography color="tertiary" variant="h2" bold>
            ALERTES
          </Typography>
          <Checkbox
            name="permissions"
            checked={selectedPermissions}
            multi
            items={state?.map(({ id, display_name }) => ({
              label: display_name,
              name: id,
            }))}
            onChange={setSelectedPermissions}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <Button color="fill-secondary" label="Valider" onClick={handleSubmit} />
      </div>
    </div>
  </EmployeWrapper>
);
