import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Typography, Input, Button } from "components";
import {
  isvalidPassword,
  isValidConfirmPassword,
  formMessageList,
} from "helpers";
import { publicRoute } from "routes";
import publicLayout from "layouts/public";
import { slogan } from "config";
import { request } from "services";

const ForgotPasswordPage = ({ history, alert }) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    submitted: false,
    token: null,
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { email, password, password_confirmation, token } = credentials;

    if (token) {
      if (!isvalidPassword(password))
        return alert.error(formMessageList.password);
      if (!isValidConfirmPassword(password, password_confirmation))
        return alert.error(formMessageList.confirmPasswordDifferent);
    } else if (!email) return;

    request({
      path: "forget-password",
      body: { email, token, password, password_confirmation },
    }).then(() => history.push(publicRoute.login));
  };
  useEffect(() => {
    const { search } = window.location;
    if (search?.includes("token=")) {
      setCredentials({ ...credentials, token: search.split("token=")[1] });
    }
  }, []);

  return (
    <div>
      <Typography type="h2" className="mt-16">
        Entre votre e-mail pour obtenir un lien de réinitialisation
      </Typography>

      <div className="mt-8">
        <div className="mt-6">
          <div method="POST">
            {credentials.token ? (
              <div>
                <Input
                  name="password"
                  type="password"
                  iconName="lock"
                  placeholder="Mot de passe"
                  onChange={onChange}
                />
                <Input
                  name="password_confirmation"
                  type="password"
                  iconName="lock"
                  placeholder="Confirmer le mot de passe"
                  onChange={onChange}
                />
              </div>
            ) : (
              <div>
                <Input
                  name="email"
                  type="email"
                  iconName="email"
                  placeholder="Email"
                  onChange={onChange}
                />
              </div>
            )}
            <div className="flex items-center w-full justify-center mt-10">
              <Button
                type="button"
                label="ENVOYER"
                className="mr-4 w-60"
                full
                halfPadding
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
      <Typography className="public-asl py-2">{slogan}</Typography>
    </div>
  );
};
const connectedForgotPasswordPage = publicLayout(
  withRouter(ForgotPasswordPage)
);
export { connectedForgotPasswordPage as ForgotPasswordPage };
