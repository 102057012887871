import { apiUrl } from "config";
import { storage } from "stores";

export const userService = {
  login,
  logout,
};

function login(email, password, code) {
  let body = {email, password};
  if (code !== null) {
    body.code = code;
  }

  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(body),
  };

  return fetch(`${apiUrl}/api/login`, requestOptions)
    .then(handleResponse)
    .then(({ user = {}, token = "" } = {}) => {
      if (token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        storage.setToken(token);
        storage.setUser(user);
      }

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  storage.removeUser();
  storage.removeToken();
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
