import React from "react";
import { Link } from "react-router-dom";
import { privateRoute } from "routes";
import {
  Button,
  Typography,
  Tabs,
  Steps,
  Table,
  Profile,
  LotTableInfo,
  FormSciInputs,
  Modal,
  TableDocument,
  MessageAdmin,
  FormSciUploads,
  ResetProfilePassword,
} from "components";
import { tableHeaders } from "helpers";

export default ({
  data,
  state,
  manager,
  handleContact,
  handleViewClick,
  onChange,
  handleUpload,
  setSelected,
  handlePaginate,
  handleResetPasswordSubmit,
  handleSubmit,
  open,
  setOpen,
  viewUrl,
}) => (
  <>
    {manager ? (
      <div>
        <div className="grid grid-cols-10 mobile:flex mobile:flex-col">
          <div className="col-span-3 pr-8 mobile:pr-0 mobile:pb-4">
            <div className="border border-white py-2 px-6">
              <Link
                to={privateRoute.coproperty(
                  state.copropriete?.id,
                  state.copropriete?.nom
                )}
                className="text-white block text-sm font-light hover:text-tertiary-500"
              >
                <Typography
                  color="white"
                  variant="subtitle1"
                  noMargin
                  className="font-bold"
                >
                  {state.copropriete?.nom}
                </Typography>
              </Link>
            </div>
            <div className="border border-white mt-4 py-2 px-6">
              <Link
                to={privateRoute.members(state.copropriete?.id)}
                className="text-white block text-sm font-light hover:text-tertiary-500"
              >
                <Typography
                  color="white"
                  variant="subtitle1"
                  noMargin
                  className="font-bold"
                >
                  LISTE DES ADHERENTS
                </Typography>
              </Link>
            </div>
            <div className="border bg-white text-primary border-white mt-4 py-2 px-6">
              <Typography variant="subtitle1" noMargin className="font-bold">
                GESTION DU COMPTE
              </Typography>
            </div>
          </div>
          <div className="col-span-7">
            <div className="flex border border-tertiary py-4 mb-7 mobile:flex-col">
              <div className="relative flex flex-col w-1/2 pt-6 px-7 mobile:w-full mobile:border-b mobile:border-tertiary">
                <span className="absolute h-full right-0 top-0 border-r border-tertiary mobile:border-r-0" />
                <div className="flex justify-between">
                  <div className="">
                    <Profile manager={manager} />
                  </div>
                  <ul>
                    <Steps step={state?.etape_adhesion} />
                  </ul>
                </div>
                <div className="flex justify-end mt-5">
                  <Button
                    small
                    color="fill-tertiary"
                    label="CONTACTER"
                    onClick={handleContact}
                  />
                </div>
              </div>
              <LotTableInfo data={state} />
            </div>
            <Tabs
              className="pb-8"
              data={[
                {
                  label: "Activité",
                  content: (
                    <Table
                      header={tableHeaders.activities}
                      content={manager?.user?.activites?.map(
                        ({ date, action }) => ({
                          date,
                          action: <div className="uppercase">{action}</div>,
                          user: <div className="uppercase">{manager?.nom}</div>,
                        })
                      )}
                    />
                  ),
                },
                {
                  label: "Informations SCI",
                  content: (
                    <div className="relative flex flex-row py-4 mobile:flex-col">
                      <div className="mobile:w-full flex-col w-1/2 px-8 border-r border-tertiary">
                        <FormSciInputs
                          disabled
                          values={manager?.sci || {}}
                          onChange={onChange}
                          handleUpload={handleUpload}
                        />
                      </div>
                      <div className="mobile:w-full flex-col w-1/2 px-8">
                        <FormSciUploads
                          state={state}
                          handleUpload={handleUpload}
                          noMargin
                        />
                        {manager?.sci?.documents?.map(
                          ({ id, url, originalName }) => (
                            <div
                              key={id}
                              className="relative cursor-pointer bg-tertiary text-primary text-sm text-center rounded py-2 font-bold uppercase mb-3"
                              onClick={() => handleViewClick(url)}
                            >
                              {originalName}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ),
                },
                {
                  label: "Envoi d’un document",
                  content: (
                    <div>
                      <div className="grid gap-6">
                        <TableDocument
                          isToggleable
                          copropertyId={state?.copropriete?.id}
                          documents={state?.copropriete?.documents}
                          onCheck={setSelected}
                          handlePaginate={handlePaginate}
                          showMembersList
                        />
                      </div>
                      <MessageAdmin
                        hideReceiver
                        predefinedMessage={data.messagePredefinis || []}
                        receiver={state?.lots || []}
                        onSubmit={handleSubmit}
                      />
                    </div>
                  ),
                },
                {
                  hide: !manager?.cgp,
                  label: "Information CGP",
                  content: (
                    <ResetProfilePassword
                      isCgp
                      cgp_can_view={state?.cgp_can_view}
                      cgp_receive_email={state?.cgp_receive_email}
                      user={manager?.cgp}
                      onSubmit={handleResetPasswordSubmit}
                    />
                  ),
                },
                {
                  label: "Réinitialisation mot de passe",
                  className: "flex-1",
                  content: (
                    <ResetProfilePassword
                      user={manager}
                      onSubmit={handleResetPasswordSubmit}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
        <Modal open={open} setOpen={setOpen} fullView>
          <>
            <iframe src={viewUrl} className="w-full h-full"></iframe>
          </>
        </Modal>
      </div>
    ) : (
      ""
    )}
  </>
);
