import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { request } from "services";
import { COPROPERTY_QUERIES } from "queries";
import { AdminTableDocument } from "components";

const Coproperty = ({
  userHasPermission,
  match: { params: { id } = {} },
} = {}) => {
  const { error, data = {}, fetchMore, refetch } = useQuery(
    COPROPERTY_QUERIES,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(id) },
    }
  );
  const [state, setState] = useState(null);
  const [selected, setSelected] = useState([]);
  const [apiId, setaAiId] = useState(null);
  const [dataState, setDataState] = useState(false);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => setState(data));
  const handleValidate = () =>
    request({ path: `copropriete/validate/${id}`, method: "GET" }).then(() =>
      setDataState(true)
    );
  const handleValidateApiId = async () => {
    try {
      const res = await request({
        path: `copropriete/update-info/${state.id}`,
        body: { riv_partenaire_id: apiId },
      });
    } catch (error) {}
  };
  const onApiIdChange = (e, value) => setaAiId(value);

  useEffect(() => {
    if (error && !data) return;
    const coproperty = data?.coproprietes?.data
      ? data?.coproprietes?.data[0]
      : null;

    setState(coproperty);
    setaAiId(coproperty?.riv_partenaire_id);
  }, [data]);
  const isValidata = dataState || state?.validate;

  return (
    <>
      {state ? (
        <AdminTableDocument
          userHasPermission={!!userHasPermission.validate_coproperty}
          id={id}
          showInfo
          apiId={apiId}
          showMembersList
          type="copropriete"
          coproperty={state}
          selected={selected}
          isValidata={isValidata}
          documents={state?.documents}
          refetch={refetch}
          onCheck={setSelected}
          onApiIdChange={onApiIdChange}
          setSelected={setSelected}
          handleValidateApiId={handleValidateApiId}
          onMembersList={console.log}
          handleValidate={handleValidate}
          handlePaginate={handlePaginate}
        />
      ) : (
        ""
      )}
    </>
  );
};

const connectedCoproperty = DashboardLayout(Coproperty, { withSidebar: true });
export { connectedCoproperty as Coproperty };
