import React, { useState } from "react";
import { clsx } from "helpers";
import { Modal } from "components";

const Btn = ({
  children,
  className,
  type,
  name,
  label,
  noMargin,
  multiple,
  onClick,
  accept,
  onChange,
}) => (
  <div
    className={clsx(
      "relative bg-tertiary text-primary text-sm text-center rounded py-2 font-bold uppercase",
      noMargin ? "" : "mb-3",
      className
    )}
  >
    {label}
    <input
      type={type}
      multiple={multiple}
      onClick={onClick}
      name={name}
      accept={accept}
      onChange={onChange}
      className="absolute top-0 left-0 cursor-pointer opacity-0 w-full h-full z-10"
    />
    {children}
  </div>
);
export const UploadSingle = ({
  name,
  label,
  data = {},
  accept = ".png, .jpg, .jpeg, .pdf",
  noMargin = false,
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  const onFileChange = ({ target: { files } }) => {
    if (!files?.length) return;

    onChange && onChange({ name, file: files[0], data });
  };
  const handleClick = (e) => {
    if (!data?.url) return;
    e.preventDefault();
    setOpen(true);
  };

  return (
    <Btn
      name={name}
      label={label}
      type="file"
      accept={accept}
      multiple={false}
      noMargin={noMargin}
      onClick={handleClick}
      onChange={onFileChange}
    >
      <Modal open={open} setOpen={setOpen} fullView>
        <>
          <Btn
            name={name}
            label="Mettre à jour"
            type="file"
            className="w-11/12 m-auto"
            accept={accept}
            multiple={false}
            noMargin={noMargin}
            onChange={onFileChange}
          />
          <iframe src={data?.url} className="w-full h-full auto"></iframe>
        </>
      </Modal>
    </Btn>
  );
};
const Upload = () => {
  const [state, setState] = useState({ selectedFile: null });

  // On file select (from the pop up)
  const onFileChange = (e) => {
    // Update the state
    setState({ selectedFile: e.target.files });
  };

  // On file upload (click the upload button)
  const onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("myFile", state.selectedFile, state.selectedFile.name);

    // Request made to the backend api
    // Send formData object
    // axios.post("api/uploadfile", formData);
  };

  return <input type="file" multiple onChange={onFileChange} />;
};
export default Upload;
