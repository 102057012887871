import React from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { history, alertActions } from "stores";
import Routes from "routes/router";
import "react-toastify/dist/ReactToastify.css";

class App extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  render() {
    const { user } = this.props;

    return (
      <div>
        <Routes user={user} />
        <ToastContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication: { loggedIn, user } = {} } = state;

  return {
    user: loggedIn ? user : null,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
