import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { AdminTableDocument, WithCoproListSidebar } from "components";
import { privateRoute } from "routes";
import { COPROPERTY_QUERIES } from "queries";

const AdminDocumentPage = ({
  userHasPermission,
  history,
  match: { params: { coproId } = {} } = {},
}) => {
  const { loading, error, data = {}, fetchMore, refetch } = useQuery(
    COPROPERTY_QUERIES,
    {
      fetchPolicy: "network-only",
    }
  );
  const [state, setState] = useState([]);
  const [curr, setCurr] = useState(null);
  const [selected, setSelected] = useState([]);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) =>
      setState(data?.lots?.data)
    );
  const handleSidebar = (index) => {
    const curr = state[index];

    if (!curr) return;

    history.push(privateRoute.documentsPage(curr.id));
    setCurr(curr);
  };
  useEffect(() => {
    if (!loading && error) return;
    const newData = data?.coproprietes?.data || [];

    setState(newData);
    setCurr(coproId ? newData.find((e) => e.id == coproId) : newData[0]);
  }, [data?.coproprietes?.data]);

  return (
    <WithCoproListSidebar
      curr={curr}
      coproprietes={data?.coproprietes?.data}
      onSelect={handleSidebar}
    >
      {!!curr && (
        <>
          <AdminTableDocument
            userHasPermission={!!userHasPermission.validate_coproperty}
            id={curr?.id}
            showMembersList
            type="copropriete"
            selected={selected}
            documents={curr.documents}
            refetch={refetch}
            onCheck={setSelected}
            setSelected={setSelected}
            onMembersList={console.log}
            handlePaginate={handlePaginate}
          />
        </>
      )}
    </WithCoproListSidebar>
  );
};

const connectedAdminHomePage = DashboardLayout(AdminDocumentPage);
export { connectedAdminHomePage as AdminDocumentPage };
