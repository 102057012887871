import { gql } from "@apollo/client";

export const MEMBERS_PAGE_QUERIES = gql`
  query($first: Int, $page: Int, $copropriete_id: Int, $query: Mixed) {
    lots(first: $first, page: $page, copropriete_id: $copropriete_id) {
      paginatorInfo {
        total
        perPage
        hasMorePages
      }
      data {
        id
        sent
        numero_lot
        etat_adhesionFr
        etat_adhesion
        etape_adhesion
        adherents {
          id
          nom
          prenom
        }
        documents {
          id
          url
          signed
          originalName
        }
        manager {
          id
          nom
          prenom
        }
      }
    }
    clients(
      where: {
        OR: [
          { column: NOM, operator: LIKE, value: $query }
          { column: PRENOM, operator: LIKE, value: $query }
        ]
      }
    ) {
      data {
        nom
        adresse
      }
    }
    coproprietes(id: $copropriete_id) {
      data {
        nom
        validate
        riv_partenaire_id
      }
    }
  }
`;

export const MEMBERS_QUERIES = gql`
  query($id: Int) {
    coproprietes(id: $id) {
      data {
        id
        nom
        adresse
        validate
        description
        riv_partenaire_id
        lots {
          id
          sent
          numero_lot
          etat_adhesionFr
          etat_adhesion
          etape_adhesion
          manager {
            nom
            prenom
          }
          documents {
            id
            url
            signed
            originalName
          }
        }
      }
    }
  }
`;

export const MEMBER_QUERIES = gql`
  query($id: Int) {
    lots(id: $id) {
      data {
        copropriete {
          id
          nom
          adresse
          documents {
            id
            url
            name
            type
            originalName
            location
            mediable_type
            mediable_id
            created_at
          }
        }
        id
        souscription_pack
        numero_lot
        numero_edd
        etage
        pack
        superficie
        montant_travaux
        montant_total
        budget_asl
        etape_adhesion
        cgp_can_view
        cgp_receive_email
        fiscalite {
          id
          nom
        }

        manager {
          id
          nom
          prenom
          sexe
          email
          telephone
          adresse
          user {
            activites {
              id
              date
              action
            }
          }
          sci {
            id
            raison_social
            capital
            adresse
            ville
            rcs
            code_postal
            sci_adresse
            sci_ville
            sci_code_postal
            user {
              id
              email
            }
          }
          cgp {
            id
            nom
            sexe
            email
            prenom
            telephone
            user {
              id
              email
            }
          }
        }
        managerStatus {
          id
          name
          url
        }
        managerKbis {
          id
          name
          url
        }
        managerIdCard {
          id
          name
          url
        }
        managerAutorisation {
          id
          name
          url
        }
        clients {
          id
          nom
          prenom
          sexe
          telephone
        }
      }
    }
    messagePredefinis {
      data {
        id
        titre
        contenu
      }
    }
  }
`;
