import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Typography,
  LotTableInfo,
  CustomCheckbox,
} from "components";
import { profileFields, formFields } from "helpers";

export const Profile = ({ manager }) => (
  <table className="border-separate border-spacing">
    <tbody>
      {Object.keys(profileFields).map((key) => (
        <tr key={key} className="mb-5">
          <td className="text-right">
            <Typography noMargin color="secondary" variant="subtitle2">
              {profileFields[key].label} :
            </Typography>
          </td>
          <td>
            <Typography
              noMargin
              color="white"
              variant="subtitle2"
              className="pl-4 break-all"
            >
              {" "}
              {manager && manager[profileFields[key].name]
                ? manager[profileFields[key].name]
                : "-"}
            </Typography>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export const ProfileInfo = ({ data = {} }) => (
  <div className="flex border border-tertiary py-4 mb-7">
    <div className="relative flex flex-col w-1/2 pt-6 px-7">
      <span className="absolute h-full right-0 top-0 border-r border-tertiary" />
      <div className="flex justify-between">
        <div className="">
          <Profile manager={data.manager} />
        </div>
      </div>
    </div>
    <LotTableInfo data={data} />
  </div>
);

export const ResetProfilePassword = ({
  user = {},
  cgp_can_view,
  cgp_receive_email,
  isCgp,
  onSubmit,
}) => {
  const [state, setState] = useState({});

  const onChange = ({ target: { name, value } }) =>
    setState({ ...state, [name]: value });
  const handleSubmit = () => {
    onSubmit(state, user.email, () => {
      setState({});
    });
  };

  useEffect(() => setState({}), [user]);

  return (
    <div className="mobile:flex-col relative flex flex-row py-4">
      <div className="mobile:w-full flex-col w-1/2 px-8 border-r border-tertiary">
        <Profile manager={user} />
      </div>
      <div className="mobile:w-full flex flex-col w-1/2 px-8 justify-center text-center">
        <div className="relative">
          {isCgp && (
            <div className="flex justify-between mb-8">
              <CustomCheckbox
                label="ACCES AU COMPTE"
                checked={!!cgp_can_view}
              />
              <CustomCheckbox
                label="SUIVI DE MAIL"
                checked={!!cgp_receive_email}
              />
            </div>
          )}
          {!!onSubmit && (
            <>
              <Input
                name="password"
                type="password"
                iconName="lock"
                value={state.password}
                iconProps={{ color: "white" }}
                placeholder="Mot de passe"
                placeholderColor="white"
                className="text-center"
                color="white"
                bordered
                onChange={onChange}
                noMargin
              />
              <Input
                name="password_confirmation"
                value={state.password_confirmation}
                type="password"
                iconName="lock"
                iconProps={{ color: "white" }}
                placeholder="Confirmer le mot de passe"
                placeholderColor="white"
                className="text-center"
                color="white"
                bordered
                onChange={onChange}
              />
            </>
          )}
        </div>
        {!!onSubmit && (
          <Button
            small
            color="fill-tertiary"
            label="RÉINITIALISATION DU MOT DE PASSE"
            className="mt-2"
            onClick={handleSubmit}
            noMargin
            full
          />
        )}
      </div>
    </div>
  );
};

export const UpdateEmployeInfo = ({
  postData = {},
  onChange,
  sideBySide = false,
}) => (
  <>
    <div className={`grid ${sideBySide ? "grid-cols-2 gap-8" : ""}`}>
      <div>
        <Typography color="secondary" className="mt-3">
          Prénom
        </Typography>
        <Input
          name={formFields.firstName.name}
          type="text"
          color="white"
          placeholderColor="secondary"
          placeholder={formFields.firstName.label}
          value={postData.prenom}
          onChange={onChange}
        />
      </div>
      <div>
        <Typography color="secondary" className="mt-3">
          Nom
        </Typography>
        <Input
          name={formFields.lastName.name}
          type="text"
          color="white"
          placeholderColor="secondary"
          placeholder={formFields.lastName.label}
          value={postData.nom}
          onChange={onChange}
        />
      </div>
    </div>
    <div className={`grid ${sideBySide ? "grid-cols-2 gap-8" : ""}`}>
      <div>
        <Typography color="secondary" className="mt-3">
          Email
        </Typography>
        <Input
          name={formFields.email.name}
          type="text"
          color="white"
          placeholderColor="secondary"
          placeholder={formFields.email.label}
          value={postData.email}
          onChange={onChange}
        />
      </div>
      <div>
        <Typography color="secondary" className="mt-3">
          Téléphone
        </Typography>
        <Input
          name={formFields.phone.name}
          type="text"
          color="white"
          placeholderColor="secondary"
          placeholder={formFields.phone.label}
          value={postData.telephone}
          onChange={onChange}
        />
      </div>
    </div>
  </>
);
