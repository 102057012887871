import { userService } from "services";
import { storage } from "stores";
import { toast } from "react-toastify";
import { userConstants } from "../constants";
import { alertActions } from "./alert.actions";

export const userActions = {
  login,
  logout,
  update,
};

function login(email, password, code = null, credentials = null, setCredentials = null) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password, code).then(
      (user) => dispatch(success(user)),
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

        if (error === 'Vérification email nécessaire') {
          setCredentials({...credentials, code: ''})

          toast.info(error);
        } else {
          toast.error(error);
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function update(user, etape_adhesion) {
  return (dispatch) => {
    dispatch(updateUser(user));

    if (etape_adhesion === 6) {
      window.location.reload();
    }
    storage.setUser(user);
  };

  function updateUser(user) {
    return { type: userConstants.UPDATE_SUCCESS, user };
  }
}
function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}
