import React from "react";
import PropTypes from "prop-types";
import { shared } from "lib";
import { clsx } from "helpers";
import * as icons from "static/svg";

const SIZES = {
  auto: "auto",
  tiny: 10,
  small: 16,
  medium: 24,
  large: 48,
  nearBig: 52,
  big: 67,
};
const ICON_COLORS = {
  ...shared,
  gray: "gray",
  inherit: "inherit",
};
export const iconTypes = Object.keys(icons);
export const colorTypes = Object.keys(ICON_COLORS);
export const sizeTypes = Object.keys(SIZES);

const Icon = ({
  type,
  color,
  size,
  customSize,
  strokeColor,
  rotate = 0,
  className,
}) => {
  const SvgIcon = icons[type];
  const svgSize = SIZES[size];
  const stroke = ICON_COLORS[strokeColor || color] ?? color;

  return (
    <SvgIcon
      className={clsx(
        "stroke-0 fill-current stroke-current",
        stroke,
        className
      )}
      style={{
        ...(customSize || {
          width: svgSize,
          height: svgSize,
        }),
        ...(rotate ? { transform: `rotate(${rotate}deg)` } : {}),
      }}
    />
  );
};

Icon.propTypes = {
  type: PropTypes.oneOf(iconTypes).isRequired,
  color: PropTypes.oneOf(colorTypes),
  size: PropTypes.oneOf(sizeTypes),
  customSize: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  strokeColor: PropTypes.string,
  className: PropTypes.string,
  rotate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Icon.defaultProps = {
  color: "primary",
  size: "medium",
  customSize: undefined,
  strokeColor: undefined,
  className: undefined,
  rotate: 0,
};

export default Icon;
