import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { request, websockets } from "services";
import { isCgp } from "helpers";
import View from "./ClientLotDocumentPage";

const MEMBERS_QUERIES = gql`
  query($adherent_id: Int) {
    lots(manager_id: $adherent_id) {
      data {
        id
        souscription_pack
        numero_lot
        numero_edd
        etage
        superficie
        montant_travaux
        budget_asl
        etat_adhesion
        etape_adhesion
        souscription_pack

        copropriete {
          nom
          adresse
          description
        }

        manager {
          id
          nom
          prenom
          yousign_client_id
        }

        documents {
          id
          url
          type
          location
          created_at
          originalName
          details {
            signed
            yousign_file_id
          }
        }
      }
    }
  }
`;

const ClientLotDocumentPage = ({ user = {}, memberId }) => {
  const { loading, error, data = {}, fetchMore, refetch } = useQuery(
    MEMBERS_QUERIES,
    {
      variables: {
        adherent_id: parseInt(memberId),
      },
    }
  );
  const [state, setState] = useState([]);
  const [curr, setCurr] = useState(null);
  const [open, setOpen] = useState(false);
  const [viewUrl, setViewUrl] = useState(false);
  const [selected, setSelected] = useState([]);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) =>
      setState(data?.lots?.data)
    );
  const handleSearch = ({ target: { value } }) =>
    fetchMore({ variables: { query: `%${value}%` } }).then(({ data }) =>
      setState(data)
    );
  const handleValidateLot = () =>
    request({ path: `lot/valider-admin/${selected}` });
  const handleViewClick = (data) => {
    setOpen(true);
    setViewUrl(data.url);
  };
  const hanleSidebar = (e, index) => {
    e.preventDefault();
    setCurr(state[index]);
  };
  const updateState = (data) => {
    const newData = data?.lots?.data || [];
    setState(newData);
    setCurr(newData[0]);
  };
  useEffect(() => {
    if (!error) updateState(data);
  }, [loading]);
  useEffect(() => {
    websockets.documentSigned(() => {
      refetch()?.then(({ data }) => {
        updateState(data);
      });
    }, user?.id);
  }, []);

  const isClient = !isCgp(user);

  return (
    <View
      open={open}
      curr={curr}
      data={state}
      viewUrl={viewUrl}
      isClient={isClient}
      setOpen={setOpen}
      hanleSidebar={hanleSidebar}
      onViewClick={handleViewClick}
      handlePaginate={handlePaginate}
      handleValidateLot={handleValidateLot}
      onCheck={isClient ? setSelected : undefined}
    />
  );
};

export default DashboardLayout(ClientLotDocumentPage);
