import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { Icon } from "./form";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sharedBtn = {
  display: "block",
  position: "absolute",
  top: "50%",
  cursor: "pointer",
  zIndex: 3,
  borderRadius: "100%",
};

const SampleNextArrow = ({ className, style, onClick, arrowSize }) => (
  <div
    className={className}
    style={{
      ...style,
      right: "1.6rem",
      ...sharedBtn,
      width: arrowSize === "small" ? "26px" : "20px",
      height: arrowSize === "small" ? "26px" : "20px",
    }}
    onClick={onClick}
  >
    <Icon type="triangle" size={arrowSize} rotate="180" />
  </div>
);

const SamplePrevArrow = ({ className, style, onClick, arrowSize }) => (
  <div
    className={className}
    style={{
      ...style,
      left: "1.6rem",
      ...sharedBtn,
      width: arrowSize === "small" ? "26px" : "20px",
      height: arrowSize === "small" ? "26px" : "20px",
    }}
    onClick={onClick}
  >
    <Icon type="triangle" size={arrowSize} />
  </div>
);

const Carousel = ({
  list = [],
  dots = false,
  arrows = false,
  autoplay = false,
  arrowSize = "tiny",
}) => {
  const settings = {
    infinite: true,
    dots,
    arrows,
    autoplay,
    nextArrow: <SampleNextArrow arrowSize={arrowSize} />,
    prevArrow: <SamplePrevArrow arrowSize={arrowSize} />,
  };

  return (
    <Slider {...settings}>
      {list.map((path, index) => (
        <div key={`${path}-${index}`}>
          <div
            style={{ backgroundImage: `url(${path})` }}
            className="bg-cover"
          />
        </div>
      ))}
    </Slider>
  );
};

Carousel.propTypes = {
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  autoplay: PropTypes.bool,
  arrowSize: PropTypes.oneOf(["small", "tiny"]),
};
export default Carousel;
