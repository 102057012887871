import React, { useState } from "react";
import { DashboardLayout } from "layouts";
import { connect } from "react-redux";
import {
  pick,
  unPick,
  isvalidPassword,
  isValidConfirmPassword,
  formMessageList,
} from "helpers";
import { userActions, storage } from "stores";
import { request } from "services";
import View from "./AdminSetupPage";

const AdminSetupPage = ({ dispatch, user = {}, alert }) => {
  const [state, setState] = useState({
    ...pick(user, ["email"]),
    ...unPick(user?.linkable, ["id"]),
  });

  const onChange = ({ target: { name, value } }) =>
    setState({ ...state, [name]: value });

  const handleSubmit = () => {
    if (state.password || state.password_confirmation) {
      if (!isvalidPassword(state.password)) {
        return alert.error(formMessageList.password);
      } else if (
        !isValidConfirmPassword(state.password, state.password_confirmation)
      ) {
        return alert.error(formMessageList.confirmPasswordDifferent);
      }
    }

    request({ path: `update-my-profile`, body: state, method: "PUT" }).then(
      ({ user, token }) => {
        storage.setToken(token);
        dispatch(userActions.update(user));
        alert.success(formMessageList.informationUpdated);
      }
    );
  };

  return <View state={state} onChange={onChange} handleSubmit={handleSubmit} />;
};

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
}

const connectedAdminSetupPage = DashboardLayout(
  connect(mapStateToProps)(AdminSetupPage)
);
export { connectedAdminSetupPage as AdminSetupPage };
