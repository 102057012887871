import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Checkbox, Icon, Pagination } from "components";
import { toggleArray } from "helpers";
import { request } from "services";

const Table = ({
  header = [],
  content = [],
  noHeader,
  onCheck,
  onToggle,
  selected = [],
  onViewClick,
  isOpen,
  isToggleable,
  paginateProps,
}) => {
  const [isToggle, setIsToggle] = useState(isOpen);
  const [checked, setChecked] = useState([]);
  const [page, setPage] = useState(0);
  const [isAllChecked, setAllChecked] = useState(false);
  const [currContent, setCurrContent] = useState(content);

  const toggleCheck = (id) => {
    const checkList = toggleArray(checked, id);

    setChecked(checkList);
    if (isAllChecked) setAllChecked(false);
    if (onCheck) onCheck(checkList);
  };
  const handleSelectAll = (checked) => {
    const checkList = checked ? currContent.map((data) => data.id) : [];

    setAllChecked(checked);
    setChecked(checkList);
    if (onCheck) onCheck(checkList);
    if (isToggleable && checked && !isToggle) setIsToggle(true);
  };
  const handleToggle = () => {
    const _isToggle = !isToggle;

    setIsToggle(_isToggle);
    onToggle && onToggle(_isToggle);
  };
  const handleViewClick = (data) => async (e) => {
    if (!onViewClick || data.redirectable) return;
    e.preventDefault();
    try {
      await request({
        path: "documents/set-seen-stats",
        body: { data: [data.id] },
      });
      onViewClick(data);
    } catch (error) {}
  };
  const handlePaginate = (index) => {
    let p = index - 1;

    setPage(p);
    p *= paginateProps.perPage;

    setCurrContent(content.slice(p, p + paginateProps.perPage));
  };
  useEffect(() => setIsToggle(isOpen), [isOpen]);
  useEffect(() => {
    const currLength = currContent.length;

    setChecked(selected);
    setAllChecked(currLength != 0 && selected.length == currLength);
  }, [selected, currContent]);
  useEffect(() => {
    let v = content;

    if (paginateProps?.autoPaginate) {
      let p = page * paginateProps.perPage;

      // console.log(p, page, Math.floor(content.length / paginateProps.perPage));
      // p = p >= 0 ? p : 0;
      // console.log(p, p + paginateProps.perPage, content.length);
      v = content.slice(p, p + paginateProps.perPage);
    }
    setCurrContent(v);
  }, [content, content.length, page]);

  const headerFields = header.map((head) => head.field);

  return (
    <>
      <div className="flex flex-col mobile:overflow-scroll hideSrollBar">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-secondary">
              <table className="min-w-full divide-y divide-gray-200">
                {noHeader ? (
                  ""
                ) : (
                  <thead
                    className={`bg-secondary ${
                      isToggleable ? "cursor-pointer" : ""
                    }`}
                    onClick={isToggleable ? handleToggle : undefined}
                  >
                    <tr className="border-b border-secondary">
                      {onCheck && currContent.length ? (
                        <th
                          className="px-6 py-3 text-left text-xs font-medium text-primary uppercase tracking-wider w-3 cursor-default"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Checkbox
                            checked={isAllChecked}
                            variant="fill-primary"
                            onChange={handleSelectAll}
                            inline
                          />
                        </th>
                      ) : null}
                      {header.map(({ headerName, field }, key) => (
                        <th
                          key={key}
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-primary uppercase tracking-wider"
                        >
                          {field === "view" && isToggleable ? (
                            <Icon
                              type="triangle"
                              color="tertiary"
                              size="small"
                              className="ml-auto"
                              rotate={isToggle ? "-90" : "0"}
                            />
                          ) : (
                            headerName
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                )}
                <tbody className="divide-y divide-gray-200">
                  {((isToggleable && isToggle) || !isToggleable) &&
                    currContent &&
                    currContent.map((data, key) => (
                      <tr key={key} className="border-b border-secondary">
                        {onCheck && (
                          <td className="px-6 py-3 text-left text-xs font-medium text-primary uppercase tracking-wider">
                            <Checkbox
                              value="mr"
                              name="gender"
                              checked={
                                isAllChecked || checked.includes(data.id)
                              }
                              variant="fill-primary"
                              onChange={() => toggleCheck(data.id)}
                              inline
                            />
                          </td>
                        )}
                        {headerFields.map((elem) => (
                          <td
                            key={elem}
                            className={`px-6 py-4 ${
                              elem != "date" ? "" : "whitespace-nowrap"
                            } text-sm font-medium text-secondary`}
                          >
                            {elem !== "view" ? (
                              data[elem]
                            ) : (
                              <Link
                                onClick={handleViewClick(data)}
                                to={data[elem] || "#"}
                                className="flex uppercase items-center cursor-pointer text-xs text-white hover:text-tertiary justify-end"
                              >
                                {data.viewTitle ? data.viewTitle : "CONSULTER"}
                                <Icon
                                  type="eye"
                                  color="white"
                                  size="small"
                                  className="ml-4"
                                />
                              </Link>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {paginateProps && ((isToggleable && isToggle) || !isToggleable) && (
        <Pagination
          {...paginateProps}
          {...(paginateProps.autoPaginate ? { onChange: handlePaginate } : {})}
          className="mt-10"
        />
      )}
    </>
  );
};

Table.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
    })
  ),
  paginateProps: PropTypes.shape({
    autoPaginate: PropTypes.bool,
    perPage: PropTypes.number,
    count: PropTypes.number,
    onChange: PropTypes.func,
  }),
  content: PropTypes.array,
  noHeader: PropTypes.bool,
  onCheck: PropTypes.func,
  selected: PropTypes.array,
  onToggle: PropTypes.func,
  onViewClick: PropTypes.func,
  isToggleable: PropTypes.bool,
  isOpen: PropTypes.bool,
};
Table.defaultProps = {
  selected: [],
  content: [],
  isOpen: false,
  noHeader: false,
  onCheck: undefined,
  isToggleable: false,
  onToggle: undefined,
  onViewClick: undefined,
  paginateProps: undefined,
};
export default Table;
