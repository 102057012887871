import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import { clsx, ucFirst, toggleArray } from 'helpers';

const variants = {
  primary: {
    variantClass: 'text-white hover:text-gray-400',
    inputClass:
      'focus:ring-primary-600 focus:ring-offset-primary bg-primary h-4 w-4 text-primary-700 border-secondary',
  },
  secondary: {
    variantClass: 'text-white hover:text-gray-80',
    inputClass: 'focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300',
  },
  white: {
    variantClass: 'text-primary hover:text-primary-700',
    inputClass: 'focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300',
  },
};
export const variantList = Object.keys(variants);

const Radio = ({
  name: defaultName,
  defaultValue,
  title,
  containerClass,
  className,
  inline,
  disabled,
  items = [],
  inlineIcon,
  label,
  variant,
  checkbox,
  onChange,
}) => {
  const { variantClass, inputClass } = variants[variant];
  const [state, setState] = useState(checkbox ? [] : defaultValue);

  const handleCheck = (e, name, props) => {
    const val = checkbox ? toggleArray(state, name) : name;
    setState(val);
    onChange && onChange(val, e, props);
  };

  useEffect(() => setState(defaultValue), [defaultValue]);

  return (
    <div
      className={clsx(
        'mb-4',
        inline ? 'flex items-center gap-x-10' : '',
        containerClass
      )}
    >
      {title ? (
        <span className={`text-sm font-medium ${variantClass}`}>
          {ucFirst(title)}
        </span>
      ) : (
        ''
      )}
      <div
        className={
          inline ? 'flex justify-between items-center mt-0 gap-x-10' : 'mt-1'
        }
      >
        {label ? (
          <Typography color="white" variant="subtitle1" noMargin>
            {label}
          </Typography>
        ) : (
          ''
        )}
        <div
          className={clsx(
            'relative items-center',
            inline ? 'flex justify-between mt-0' : 'mt-1'
          )}
        >
          {items.map(({ name, label, ...props }, index) => (
            <div
              key={`${name}${index}`}
              className={clsx(
                'flex pr-4 items-center',
                inline ? 'items-center justify-between w-full' : ''
              )}
            >
              <input
                disabled={disabled}
                id={defaultName + name}
                name={defaultName}
                type="checkbox"
                checked={checkbox ? state.includes(name) : state == name}
                className={clsx(
                  'focus:ring-transparent cursor-pointer rounded checked:border checked:border-secondary',
                  inputClass,
                  className
                )}
                onChange={(e) => handleCheck(e, name, props)}
              />
              <div className={`${inlineIcon ? 'ml-5' : 'ml-3'} text-sm"`}>
                <label
                  htmlFor={defaultName + name}
                  className={`font-medium text-sm cursor-pointer ${variantClass}`}
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  defaultValue: PropTypes.any,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  inlineIcon: PropTypes.bool,
  checkbox: PropTypes.bool,
  variant: PropTypes.oneOf(variantList),
};
Radio.defaultProps = {
  title: undefined,
  label: undefined,
  defaultValue: undefined,
  variant: 'primary',
  containerClass: '',
  className: '',
  inline: false,
  disabled: false,
  inlineIcon: false,
  checkbox: false,
};

export default Radio;
