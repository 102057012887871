import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { clsx } from 'helpers';

export const colors = {
  primary: 'text-white bg-primary hover:bg-tertiary hover:text-primary',
  'fill-primary':
    'text-primary border-primary hover:bg-primary hover:text-white',
  secondary: 'bg-secondary border-secondary text-white hover:bg-secondary-700',
  'fill-secondary':
    'text-secondary border-secondary hover:bg-tertiary hover:text-primary',
  tertiary:
    'text-primary bg-tertiary border-tertiary hover:bg-white hover:text-primary',
  'fill-tertiary':
    'text-tertiary border-tertiary hover:border-white hover:text-white',
  white: 'bg-white border-primary text-primary hover:bg-gray-50',
};
export const ButtonColors = Object.keys(colors);
export const ButtonTypes = ['button', 'submit'];

const Button = ({
  type,
  color,
  label,
  btnClass,
  full,
  uppercase,
  disabled = false,
  small = false,
  to,
  halfPadding,
  onClick,
  noMargin,
  className,
}) => {
  const colorClass = colors[color];
  const newClassName = clsx(
    colorClass,
    full ? 'block text-center w-full h-full' : 'block',
    `${halfPadding ? 'py-4' : 'py-2'} px-4`,
    small ? 'text-xs' : 'text-sm',
    'border rounded-md shadow-sm font-normal focus:outline-none focus:ring-2 focus:ring-offset-2',
    uppercase ? 'uppercase' : '',
    btnClass
  );

  return (
    <div
      className={clsx(
        noMargin ? '' : 'mb-4',
        className,
        disabled ? 'opacity-50 cursor-default' : ''
      )}
    >
      {to ? (
        <Link to={to} className={newClassName}>
          {label}
        </Link>
      ) : (
        <button
          disabled={disabled}
          type={type}
          className={newClassName}
          onClick={(e) => onClick && onClick(e)}
        >
          {label}
        </button>
      )}
    </div>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  btnClass: PropTypes.string,
  type: PropTypes.oneOf(ButtonTypes),
  full: PropTypes.bool,
  uppercase: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  noMargin: PropTypes.bool,
  to: PropTypes.string,
  halfPadding: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(ButtonColors),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Button.defaultProps = {
  color: 'primary',
  label: undefined,
  full: false,
  uppercase: false,
  disabled: false,
  noMargin: false,
  to: undefined,
  halfPadding: false,
  type: 'button',
  btnClass: '',
  className: '',
  onClick: undefined,
};

export default Button;
