import React from "react";
import { Link } from "react-router-dom";
import { privateRoute } from "routes";
import {
  Typography,
  Tabs,
  Radio,
  Table,
  Profile,
  Modal,
  Checkbox,
  SingleCheckbox,
  SidebarUsers,
  EmployeWrapper,
  FormSciUploads,
  ResetProfilePassword,
  UpdateEmployeInfo,
  Button,
} from "components";
import { tableHeaders, employeeRoleList } from "helpers";

export default ({
  open,
  role,
  state,
  manager,
  setOpen,
  viewUrl,
  coproperties,
  handleViewClick,
  handleRoleChange,
  onChange,
  handleUpload,
  handleInformations,
  hanleSidebar,
  handlePermissions,
  onPermissionChange,
  selectedPermissions,
  handleResetPasswordSubmit,
}) => (
  <>
    {manager ? (
      <EmployeWrapper curr={null} hanleSidebar={hanleSidebar}>
        <div className="col-span-7">
          <div className="flex border border-tertiary py-4 mb-7">
            <div className="relative flex flex-col w-1/2 pt-6 px-7">
              <span className="absolute h-full right-0 top-0 border-r border-tertiary" />
              <div className="flex justify-between">
                <div className="">
                  <Profile manager={manager} />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/2 pt-6 px-7">
              <Typography color="tertiary" variant="h2" bold>
                Role
              </Typography>
              <Radio
                name="role"
                defaultValue={role?.id}
                items={employeeRoleList.map(({ label, id }) => ({
                  label,
                  name: id,
                }))}
                onChange={handleRoleChange}
              />
            </div>
          </div>
          <Tabs
            className="pb-8"
            data={[
              {
                // hide: true,
                label: "Activité",
                content: (
                  <Table
                    header={tableHeaders.activities}
                    content={manager?.user?.activites?.map(
                      ({ date, action }) => ({
                        date,
                        action: <div className="uppercase">{action}</div>,
                        user: <div className="uppercase">{manager?.nom}</div>,
                      })
                    )}
                  />
                ),
              },
              {
                // hide: true,
                label: "Informations",
                content: (
                  <div className="p-4">
                    <UpdateEmployeInfo
                      sideBySide
                      postData={manager}
                      onChange={onChange}
                    />
                    <Button
                      label="Valider"
                      btnClass="m-auto mt-5 text-center"
                      onClick={handleInformations}
                    />
                  </div>
                ),
              },
              {
                // hide: true,
                label: "Mot de passe",
                content: (
                  <ResetProfilePassword
                    cgp_can_view={state?.cgp_can_view}
                    cgp_receive_email={state?.cgp_receive_email}
                    user={manager}
                    onSubmit={handleResetPasswordSubmit}
                  />
                ),
              },
              {
                // hide: true,
                label: "Permissions",
                className: "flex-1",
                content: (
                  <div className="flex px-8 py-4 justify-between">
                    <Checkbox
                      name="permissions"
                      checked={state?.user?.employeCoproprietes}
                      multi
                      items={coproperties?.map(({ id, nom }) => ({
                        label: nom,
                        name: id,
                      }))}
                      onChange={onPermissionChange}
                    />
                    <div className="flex items-center">
                      <Button
                        small
                        color="fill-secondary"
                        label="Valider"
                        className="mt-2"
                        onClick={handlePermissions}
                        noMargin
                        full
                      />
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
        <Modal open={open} setOpen={setOpen} fullView>
          <>
            <iframe src={viewUrl} className="w-full h-full"></iframe>
          </>
        </Modal>
      </EmployeWrapper>
    ) : (
      ""
    )}
  </>
);
