import PropTypes from "prop-types";
import { clsx } from "helpers";
import { shared } from "lib";
import Loader from "static/img/loader.gif";

export const Loading = ({ fullView, background }) => (
  <div
    className={clsx(
      "flex justify-center items-center",
      fullView ? "w-screen h-screen" : "",
      background ? `bg-${background}` : ""
    )}
  >
    <img src={Loader} alt="" className="w-8 h-8" />
  </div>
);

Loading.propTypes = {
  background: PropTypes.oneOf(Object.keys(shared)),
  fullView: PropTypes.bool,
};
Loading.defaultProps = {
  background: "primary",
  fullView: false,
};
