import { Typography } from "components";

export const LegalPage = () => (
  <div className=" pt-5">
    <Typography type="h2" className="text-center font-semibold">
      Association Syndicale Libre
    </Typography>
    <div className="flex justify-center py-7 px-4 sm:px-5 lg:flex-none lg:px-20">
      <div className="pr-5">
        <div className="mb-4">
          <Typography type="h3" color="black">
            1. Propriété et Responsabilité éditoriale
          </Typography>
          <Typography color="primary">
            Le présent site est la propriété de la société STRAT&FI SARL,
            société à responsabilité limitée, au capital de 10.040 €, inscrite
            au registre du commerce et des sociétés de Paris sous le n°433 325
            420 dont le siège social se situe sis 28, Boulevard Malesherbes –
            75008 PARIS – Tél. : 01.73.04.28.37.
          </Typography>
          <Typography color="primary">
            Président Responsable éditoriaux et Responsable de publication :
            Monsieur Guillaume LANGE Mail de contact : contact@stratefi.com
            Numéro de TVA intracommunautaire : FR23433325420 Immatriculée à
            l’ORIAS au registre unique des intermédiaires en assurance, banque
            et finance sous le numéro 07025674.
          </Typography>

          <Typography className="mt-2">
            - Activité de : o Conseiller en investissement financiers (CIF),
            membre de la CNCIF, association agrée par l’Autorité des Marchés
            Financiers. o Courtier d’assurance (COA) et Courtier en opérations
            de banque et en services de paiement (COBSP), sous le contrôle de
            l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), 4, Place
            de Budapest, 75346 Paris Cedex 9. o Transactions immobilières (sans
            maniement de fonds), activité régie par la loi Hoguet sous couvert
            de la carte T n°14181 délivrée par la Préfecture de Paris. -
            Garanties financière et Responsabilité civile professionnelle
            souscrites auprès des MMA IARD SA, 14 boulevard Marie et Alexandre
            Oyon 72030 Le Mans Cedex 9 o Responsabilité civile professionnelle :
            3.300.000 € par sinistre, sans limite par an, o Garantie financière
            : 115.000 € par année d’assurance, o Garantie financière immobilière
            : 110.000 € par année d’assurance.
          </Typography>
        </div>
        <div className="mb-4">
          <Typography type="h3" color="black" className="mt-4">
            2. Hébergement
          </Typography>
          <Typography color="primary">
            Le prestataire assurant l’hébergement du site est la société ONLINE
            ONLINE SAS BP 438 75366 PARIS CEDEX 08 FRANCE Société par Actions
            Simplifiée au capital de 214 410,50 Euros SIREN : 433 115 904 RCS
            Paris Siège social : 8 rue de la Ville l’Evêque, 75008 Paris N° de
            TVA intracommunautaire : FR 35 433115904
          </Typography>
          <Typography type="h3" color="black" className="mt-4">
            3. Crédits
          </Typography>
          <Typography color="primary">
            Le site a été conçu et développé par Hayakum. En application de la
            règlementation destinée à protéger les investisseurs, les
            conseillers en investissements financiers, les intermédiaires en
            assurance et les intermédiaires en opérations de banque et en
            services de paiement sont dans l’obligation de remettre à leur
            clientèle, avant la formulation d’un contrat, un document d’entrée
            en relation conforme aux articles 325-5 du Règlement général de
            l’AMF, R520-1 du Code des assurances et R519-20 du Code monétaire et
            financier et consultable en annexe 2.
          </Typography>
        </div>
      </div>
      <div className="pl-5">
        <Typography type="h3" color="black">
          4. Tarification
        </Typography>
        <Typography color="primary">
          Dans le cadre de ses prestations de conseil en investissements
          financiers (CIF) et de courtage en opérations de banque et en services
          de paiement (COBSP), fournies à titre non indépendant, le Family
          Office perçoit, conformément à la réglementation, des rémunérations de
          la part de ses partenaires afin d’améliorer le service rendu au client
          et d’agir au mieux de ses intérêts. De façon générale, le Conseiller
          est rémunéré par : - des honoraires : au forfait, au temps passé ou au
          résultat, - des rémunérations liées aux décisions d’investissement que
          le Client prendra à l’issue de la prestation de conseil, le plus
          souvent assises sur les montants investis et pouvant être fonction de
          la durée de l’investissement.
        </Typography>
        <Typography type="h3" color="black" className="mt-4">
          5. Politique de gestion des conflits d’intérêts
        </Typography>
        <Typography color="primary">
          STRAT&FI a formalisé une politique de gestion des conflits d’intérêts
          et mis en place des dispositions spécifiques en termes d’organisation
          (moyens et procédures) et de contrôle afin de prévenir, d’identifier
          et de gérer les situations de conflits d’intérêts pouvant porter
          atteinte aux intérêts de ses clients. Le Family Office travaille avec
          une liste étendue et non fermée, d’établissements bancaires,
          d’assureurs et de maisons de gestion de son choix. STRAT & FI noue
          régulièrement de nouveaux partenariats pour répondre de manière ciblée
          au besoin spécifique de chaque client. STRAT & FI n’a aucun lien
          capitalistique ni aucune obligation d’exclusivité contractuelle avec
          aucun d’entre eux.
        </Typography>
      </div>
    </div>
  </div>
);
