import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Tabs = ({ data: defaultData = [], tab = 0, className = "" }) => {
  const [state, setState] = useState(tab);
  const [data, setData] = useState(defaultData);
  const onClick = (index) => () => setState(index);

  useEffect(() => {
    setData(defaultData.filter((e) => !e.hide));
  }, [defaultData]);

  return (
    <div className={className}>
      <div className="mobile:flex-col mobile:gap-1 flex gap-5">
        {data.map(({ label, className = "" }, index) => (
          <div
            key={label}
            onClick={onClick(index)}
            className={`
              ${
                state === index
                  ? "text-tertiary border-tertiary"
                  : "text-secondary border-secondary"
              }
              ${className} text-center cursor-pointer border px-4 py-3 mb-3 font-medium text-sm rounded-md`}
          >
            {label}
          </div>
        ))}
      </div>
      <div className="border border-tertiary ">{data[state]?.content}</div>
    </div>
  );
};
Tabs.propTypes = {
  tab: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      hide: PropTypes.bool,
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      className: PropTypes.string,
    }).isRequired
  ),
  className: PropTypes.string,
};
export default Tabs;
