import React from "react";
import { TableEmployeePermission, Button } from "components";

const AdminCopropertyPermissionPage = ({
  userHasPermission,
  handleSubmit,
  ...props
}) => (
  <>
    <TableEmployeePermission
      {...props}
      onCheck={userHasPermission ? props.onCheck : null}
    />
    {userHasPermission && (
      <div className="flex justify-end mt-10 gap-8">
        <Button
          color="fill-tertiary"
          label="Synchroniser"
          onClick={handleSubmit}
        />
      </div>
    )}
  </>
);

export default AdminCopropertyPermissionPage;
