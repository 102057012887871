import React from "react";
import PropTypes from "prop-types";

const ClientSidebar = ({ currStep, onClick }) => (
  <nav aria-label="Progress" className="border-none">
    <ol className="flex items-center">
      {[1, 2, 3, 4, 5].map((i) => (
        <li key={i} className={`relative ${i !== 5 ? "w-1/3" : ""}`}>
          <div className="absolute inset-0 flex items-center">
            <div className="h-px w-full bg-secondary"></div>
          </div>
          <div
            onClick={() => onClick && onClick(i)}
            className={`${
              onClick ? "cursor-pointer" : ""
            } select-none relative w-8 h-8 flex items-center justify-center ${
              i > currStep
                ? "bg-primary border border-secondary"
                : "bg-secondary"
            } rounded-full`}
          >
            {i >= currStep ? (
              <span
                className={`${
                  i > currStep ? "text-secondary" : "text-white"
                } text-sm`}
              >
                {i}
              </span>
            ) : (
              <svg
                className="w-5 h-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        </li>
      ))}
    </ol>
  </nav>
);

ClientSidebar.propTypes = {
  currStep: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default ClientSidebar;
