import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { TableMembers } from "components";
import { paginateLimit } from "helpers";
import { websockets } from "services";
import { MEMBERS_PAGE_QUERIES } from "queries";

const Members = ({
  userHasPermission,
  match: {
    params: { id },
  },
}) => {
  const { error, data = {}, fetchMore, refetch } = useQuery(
    MEMBERS_PAGE_QUERIES,
    {
      fetchPolicy: "network-only",
      variables: {
        first: paginateLimit,
        page: 1,
        copropriete_id: parseInt(id),
        query: "",
      },
    }
  );
  const [state, setState] = useState(data);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => setState(data));
  const handleSearch = ({ target: { value } }) =>
    fetchMore({ variables: { query: `%${value}%` } }).then(({ data }) =>
      setState(data)
    );

  useEffect(() => !error && setState(data), [data]);
  useEffect(() => {
    websockets.lotStepChange(() => {
      refetch()?.then((data) => setState(data));
    });
  }, []);

  return (
    <>
      {state?.lots?.data ? (
        <TableMembers
          userHasPermission={!!userHasPermission.validate_coproperty}
          coproperty={state?.coproprietes?.data[0]}
          handlePaginate={handlePaginate}
          lots={state.lots}
          refetch={refetch}
        />
      ) : (
        ""
      )}
    </>
  );
};

const connectedMembers = DashboardLayout(Members, { withSidebar: true });
export { connectedMembers as Members };
