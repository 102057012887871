import React from "react";
import PropTypes from "prop-types";
import { clsx } from "helpers";

const sizes = {
  tiny: "",
  small: "",
  large: "",
  big: "",
  full: "h-full w-full",
  wFull: "w-full",
  hFull: "h-full",
};

const Img = ({ src, alt = "", size = "small", className }) => (
  <img
    className={clsx("object-cover", sizes[size], className)}
    src={require(`../static/img/${src}`).default}
    alt={alt}
  />
);
Img.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)),
};
Img.defaultProps = {
  className: "",
  alt: "",
  size: "small",
};
export default Img;
