import { Modal, Input, Typography, Radio } from "components";
import { formFields, employeeRoleList } from "helpers";

export const AdminUserModal = ({
  open,
  isPost,
  setOpen,
  postData,
  onChange,
  setPostData,
  handleConfirm,
  handleCancel,
}) => (
  <Modal
    open={open}
    setOpen={setOpen}
    onConfirm={handleConfirm}
    onCancel={handleCancel}
    cancelText="ANNULER"
    confirmText={isPost ? "CONFIRMER" : "MODIFIER"}
  >
    <div className="pb-36">
      <Typography variant="h2" color="tertiary" className="text-center">
        {isPost
          ? "Ajouter un nouveau utilisateur"
          : `Modifier les informations de ${postData.fullName}`}
      </Typography>
      <div className="grid gap-4 grid-cols-2">
        <div>
          <Typography color="secondary" className="mt-5">
            Prénom
          </Typography>
          <Input
            name={formFields.firstName.name}
            type="text"
            color="white"
            placeholderColor="secondary"
            placeholder={formFields.firstName.label}
            value={postData.prenom}
            onChange={onChange}
          />
        </div>
        <div>
          <Typography color="secondary" className="mt-5">
            Nom
          </Typography>
          <Input
            name={formFields.lastName.name}
            type="text"
            color="white"
            placeholderColor="secondary"
            placeholder={formFields.lastName.label}
            value={postData.nom}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="grid gap-4 grid-cols-2">
        <div>
          <Typography color="secondary" className="mt-5">
            Email
          </Typography>
          <Input
            name={formFields.email.name}
            type="text"
            color="white"
            placeholderColor="secondary"
            placeholder={formFields.email.label}
            value={postData.email}
            onChange={onChange}
          />
        </div>
        <div>
          <Typography color="secondary" className="mt-5">
            Téléphone
          </Typography>
          <Input
            name={formFields.phone.name}
            type="text"
            color="white"
            placeholderColor="secondary"
            placeholder={formFields.phone.label}
            value={postData.telephone}
            onChange={onChange}
          />
        </div>
      </div>
      <Typography color="secondary" className="mt-5">
        Role
      </Typography>
      <Radio
        name="role"
        defaultValue={postData.role}
        items={employeeRoleList.map(({ label, id }) => ({
          label,
          name: id,
        }))}
        onChange={(role) => setPostData({ ...postData, role: role + "" })}
      />
    </div>
  </Modal>
);
