import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "../Typography";
import { clsx, ucFirst, toggleArray } from "helpers";

const variants = {
  primary: {
    variantClass: "text-white hover:text-gray-400",
    inputClass:
      "focus:ring-primary-600 focus:ring-offset-primary bg-primary h-4 w-4 text-primary-700 border-secondary",
  },
  "fill-primary": {
    variantClass: "text-white hover:text-gray-400",
    inputClass:
      "focus:ring-primary-600 focus:ring-offset-primary bg-primary h-4 w-4 text-primary-700 border-secondary",
  },
  secondary: {
    variantClass: "text-white hover:text-gray-80",
    inputClass: "focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300",
  },
  white: {
    variantClass: "text-primary hover:text-primary-700",
    inputClass: "focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300",
  },
};

export const CustomCheckbox = ({ label, checked = false, className = "" }) => (
  <div className={`flex ${className}`}>
    <input
      type="checkbox"
      className="mr-2 focus:ring-transparent rounded checked:border checked:border-secondary focus:ring-primary-600 focus:ring-offset-primary bg-primary h-4 w-4 text-primary-700 border-secondary"
      label={label}
      disabled
      checked={checked}
    />
    <Typography
      color="secondary"
      variant="subtitle2"
      noMargin
      className="w-5/6"
    >
      {label}
    </Typography>
  </div>
);

export const SingleCheckbox = ({
  label,
  name,
  checked,
  handleChecked,
  inlineIcon,
  variantClass = variants.primary.variantClass,
  inputClass = variants.primary.inputClass,
  className,
  disabled,
}) => (
  <div className="pr-4 flex items-center">
    <input
      id={`${name}-${label}`}
      name={name}
      type="checkbox"
      checked={checked}
      className={clsx(
        "focus:ring-transparent cursor-pointer rounded checked:border checked:border-secondary",
        inputClass,
        className
      )}
      disabled={disabled}
      onChange={handleChecked}
    />
    <div className={`${inlineIcon ? "ml-5" : "ml-3"} text-sm text-left`}>
      <label
        htmlFor={`${name}-${label}`}
        className={`font-medium cursor-pointer ${variantClass}`}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </div>
  </div>
);

const Checkbox = ({
  title,
  name: mainName,
  containerClass,
  className,
  inline,
  items = [""],
  multi,
  inlineIcon,
  label,
  disabled,
  checked: defaultChecked,
  variant,
  onChange,
}) => {
  const [checked, setChecked] = useState(defaultChecked);
  const { variantClass, inputClass } = variants[variant];
  const handleChecked = (e) => {
    const isCheck = e.target.checked;
    const arr = toggleArray(checked, e.target.name);

    setChecked(multi ? arr : isCheck);
    if (onChange) multi ? onChange(arr) : onChange(isCheck, e);
  };
  useEffect(() => setChecked(defaultChecked), [defaultChecked]);

  return (
    <div
      className={clsx(
        inline ? "flex items-center gap-x-10" : "",
        containerClass
      )}
    >
      {title ? (
        <span className={`text-sm font-medium ${variantClass}`}>
          {ucFirst(title)}
        </span>
      ) : (
        ""
      )}
      <div
        className={
          inline ? "flex justify-between items-center mt-0 gap-x-10" : "mt-1"
        }
      >
        {label ? (
          <Typography color="white" variant="subtitle1" noMargin>
            {label}
          </Typography>
        ) : (
          ""
        )}
        <div
          className={clsx(
            "relative items-center",
            inline
              ? "flex justify-between flex-col items-start mt-0 flex-wrap "
              : "mt-1"
          )}
        >
          {items.map(({ name, label }, index) => (
            <SingleCheckbox
              key={mainName + name + index}
              label={label}
              name={name}
              checked={multi ? checked?.includes(name) : checked}
              disabled={disabled}
              handleChecked={handleChecked}
              inlineIcon={inlineIcon}
              variantClass={variantClass}
              inputClass={inputClass}
              className={className}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  inlineIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.any,
  multi: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(variants)),
};
Checkbox.defaultProps = {
  title: undefined,
  label: undefined,
  variant: "primary",
  containerClass: "",
  className: "",
  inline: false,
  checked: false,
  multi: false,
  disabled: false,
  inlineIcon: false,
};

export default Checkbox;
