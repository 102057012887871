import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Img from "components/Img";
import {
  Icon,
  Crumbs,
  DropDown,
  Typography,
  AdminMenuLists,
  AdminSidebar,
} from "components";
import { adminDahsboarList, clientDahsboarList, privateRoute } from "routes";
import { unDashed, isSuperAdminOrAdmin, isMember, isCgp } from "helpers";
import { userActions } from "stores";
import { slogan } from "config";
import { overrideTailwindClasses } from "tailwind-override";

const mapStateToProps = (state) => {
  const { authentication } = state;
  const { user } = authentication;

  return {
    user,
  };
};

const DashboardLayout = (Component, { withSidebar } = {}) =>
  connect(mapStateToProps)(
    withRouter(({ user, alert, crumbs, dispatch, ...props }) => {
      const onClick = () => dispatch(userActions.logout());
      const [isOpen, setIsOpen] = useState(false);

      const toggleOpen = () => setIsOpen(!isOpen);
      const handleSearch = (e) => console.log(e);
      const params = props?.match?.params;
      const copropertyName = unDashed(params?.nom);
      const userIsMember = isMember(user);
      const memberId = isCgp(user)
        ? user?.linkable?.clients[0]?.id
        : user?.linkable?.id;
      const contextualMenuList = userIsMember
        ? clientDahsboarList
        : adminDahsboarList(user);
      const menuList = [
        {
          label: "Aide",
          path: 'mailto:contact@associationsyndicalelibre.website',
          props: {
            onClick: () => window.location.href = 'mailto:contact@associationsyndicalelibre.website',
          },
        },
        {
          label: "Se déconnecter",
          props: {
            onClick,
          },
        },
      ];

      return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
          {isOpen && (
            <div
              className="fixed inset-0 z-40 md:hidden mobile:w-full tablet:w-72 hidden tablet:flex"
              role="dialog"
              aria-modal="true"
            >
              <div className="relative flex-1 flex flex-col w-full pt-5 pb-4 bg-secondary">
                <div className="flex-shrink-0 flex items-center justify-between px-4">
                  <div
                    className="flex-shrink-0 text-white text-2xl"
                    aria-hidden="true"
                    onClick={toggleOpen}
                  >
                    x
                  </div>
                  <Icon
                    color="white"
                    type="logo"
                    customSize={{ height: "40" }}
                    alt="ASL"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    <AdminMenuLists
                      menuList={contextualMenuList}
                      onClick={() => setIsOpen(false)}
                    />
                  </nav>
                  <div className="px-7 hidden mobile:block">
                    <div className="mt-8 pt-8 border-t border-primary">
                      <h3 className="mb-6 border-l-2 pl-4 text-primary">
                        {user?.linkable?.nom}
                      </h3>
                      {menuList.map(({ label, path = "", props = {} }) => (
                        <Link
                          key={label}
                          to={path}
                          className="block text-white justify-center py-2 text-xs border-b border-secondary"
                          role="menuitem"
                          tabIndex="-1"
                          {...props}
                        >
                          {label}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="m-auto w-full hidden mobile:hidden tablet:block ">
                  <Img
                    className="w-full"
                    src="dashboard_motifs.png"
                    alt="ASL"
                  />
                  <Typography
                    onClick={onClick}
                    className="flex mt-14 ml-2 w-fit cursor-pointer hover:text-tertiary"
                  >
                    <Icon type="logout" color="inherit" className="mr-2" />
                    Quitter
                  </Typography>
                </div>
              </div>
            </div>
          )}
          <div className="tablet:hidden bg-secondary flex flex-shrink-0">
            <div className="flex flex-col w-72">
              <div className="flex flex-col flex-grow pb-4 overflow-y-auto">
                <div className="flex items-center h-20 border-b-8 border-primary flex-shrink-0 pl-10">
                  <Link
                    to={
                      userIsMember
                        ? privateRoute.clientDashboard
                        : privateRoute.home
                    }
                  >
                    <Icon
                      color="white"
                      type="logo"
                      customSize={{ height: "40" }}
                      alt="ASL"
                    />
                  </Link>
                </div>
                <div className="mt-3 flex-1 flex flex-col">
                  <Typography
                    color="white"
                    variant="subtitle1"
                    className="pl-10 mb-5 font-semibold"
                  >
                    {slogan}
                  </Typography>
                  <nav>
                    <AdminMenuLists menuList={contextualMenuList} />
                  </nav>
                  <div className="m-auto w-full">
                    <Img
                      className="w-full"
                      src="dashboard_motifs.png"
                      alt="ASL"
                    />
                    <Typography
                      onClick={onClick}
                      className="flex mt-14 ml-2 w-fit cursor-pointer hover:text-tertiary"
                    >
                      <Icon type="logout" color="inherit" className="mr-2" />
                      Quitter
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="admin-dashboard flex flex-col w-0 flex-1 overflow-hidden">
            <div className="relative z-10 flex-shrink-0 flex h-20 bg-white shadow">
              <div className="flex-1 px-4 flex justify-end bg-primary border-b-8 border-secondary">
                <div className="absolute items-center left-0 top-0 h-full flex">
                  <Typography
                    variant="subtitle2"
                    color="tertiary"
                    className="pl-12 uppercase tablet:hidden"
                  >
                    <Crumbs list={crumbs} />
                  </Typography>
                  <div
                    className="pl-2 tablet:pl-4 hidden tablet:block"
                    onClick={toggleOpen}
                  >
                    <Icon type="burger" />
                  </div>
                </div>
                {/* <div className="absolute left-1/2 flex tablet:hidden transform -translate-x-1/2 items-center justify-center h-full mr-5 cursor-pointer"></div> */}
                <div className="flex items-center justify-center h-full mr-5 cursor-pointer">
                  <Icon type="ring" size="auto" color="secondary" />
                </div>
                <DropDown
                  title={user?.linkable?.nom}
                  menuList={menuList}
                  className="tablet:hidden sm:flex"
                />
              </div>
            </div>
            <main
              className={overrideTailwindClasses(
                `mobile:block flex px-12 py-14 relative overflow-y-auto bg-primary focus:outline-none mobile:py-3 mobile:px-4 mobile:flex-col ${
                  withSidebar ? "tablet:flex-col tablet:overflow-x-hidden" : ""
                }`
              )}
            >
              {withSidebar ? (
                <div className="w-3/12 pr-8 admin-sidebar mobile:w-full mobile:pr-0 mobile:pb-4 tablet:w-full tablet:pr-0 tablet:pb-4">
                  <AdminSidebar
                    isSuperAdminOrAdmin={isSuperAdminOrAdmin(user)}
                    handleSearch={handleSearch}
                    copropertyName={copropertyName}
                    copropertyId={params?.id}
                    location={props?.location?.pathname}
                  />
                </div>
              ) : (
                ""
              )}
              <div
                className={`${
                  withSidebar ? "w-9/12" : ""
                } flex-1 mobile:overflow-x-hidden tablet:overflow-scroll`}
              >
                <Component
                  {...props}
                  user={user}
                  memberId={memberId}
                  alert={alert}
                />
              </div>
            </main>
          </div>
        </div>
      );
    })
  );

export default DashboardLayout;
