import React from 'react';
import { Typography, Input, Button } from 'components';
import { formFields } from 'helpers';

const AdminSetupPage = ({ state = {}, onChange, handleSubmit }) => (
  <>
    {state && (
      <div className="pb-36">
        <Typography variant="h2" color="tertiary" className="text-center">
          Modifier mes informations
        </Typography>
        <div className="grid gap-4 grid-cols-2 mobile:flex mobile:flex-col mobile:gap-0">
          <div>
            <Typography color="secondary" className="mt-5">
              Prénom
            </Typography>
            <Input
              name={formFields.firstName.name}
              type="text"
              color="white"
              placeholderColor="secondary"
              placeholder={formFields.firstName.label}
              value={state.prenom}
              onChange={onChange}
            />
          </div>
          <div>
            <Typography color="secondary" className="mt-5">
              Nom
            </Typography>
            <Input
              name={formFields.lastName.name}
              type="text"
              color="white"
              placeholderColor="secondary"
              placeholder={formFields.lastName.label}
              value={state.nom}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="grid gap-4 grid-cols-2 mobile:flex mobile:flex-col">
          <div>
            <Typography color="secondary" className="mt-5">
              Email
            </Typography>
            <Input
              name={formFields.email.name}
              type="text"
              color="white"
              placeholderColor="secondary"
              placeholder={formFields.email.label}
              value={state.email}
              onChange={onChange}
            />
          </div>
          <div>
            <Typography color="secondary" className="mt-5">
              Téléphone
            </Typography>
            <Input
              name={formFields.phone.name}
              type="text"
              color="white"
              placeholderColor="secondary"
              placeholder={formFields.phone.label}
              value={state.telephone}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="grid gap-4 grid-cols-2 mobile:flex mobile:flex-col">
          <div>
            <Typography color="secondary" className="mt-5">
              Mot de passe
            </Typography>
            <Input
              name="password"
              type="password"
              iconName="lock"
              value={state.password}
              iconProps={{ color: 'white' }}
              placeholder="Mot de passe"
              placeholderColor="white"
              className="text-center"
              color="white"
              bordered
              onChange={onChange}
              noMargin
            />
          </div>
          <div>
            <Typography color="secondary" className="mt-5">
              Confirmer le mot de passe
            </Typography>
            <Input
              name="password_confirmation"
              value={state.password_confirmation}
              type="password"
              iconName="lock"
              iconProps={{ color: 'white' }}
              placeholder="Confirmer le mot de passe"
              placeholderColor="white"
              className="text-center"
              color="white"
              bordered
              onChange={onChange}
            />
          </div>
        </div>
        <Button
          small
          color="fill-tertiary"
          label="Valider"
          className="mt-16 flex align-middle justify-center"
          onClick={handleSubmit}
          noMargin
        />
      </div>
    )}
  </>
);

export default AdminSetupPage;
