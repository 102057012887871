import {
  isSuperAdminOrAdmin,
  isSuperAdmin,
  isClient,
  isCgp,
  dashed,
} from "helpers";

const users = "/users";
const steps = "/steps";
const intro = "/intro";
const upload = "/upload";
const member = "/member";
const members = "/members";
const clientDashboard = "/";
const documents = "/documents";
const coproperties = "/coproperties";
const clientWaitingForValidation = "/clientWaitingForValidation";

export const privateRoute = {
  users,
  home: "/",
  members: (id = ":coproId") => `${members}/${id}?`,
  coproperties,
  clientDashboard,
  clientSetupPage: "/setup",
  rulesAndPermissionsPage: "/permisions",
  documentsPage: (id = ":coproId") => `${documents}/${id}?`,
  clientMessagesPage: "/messages",
  clientLotSelectionPage: "/select-lot",
  upload: (id = ":id") => `${upload}/${id}`,
  intro: (id = ":id") => `${intro}/${id}`,
  steps: (id = ":id") => `${steps}/${id}`,
  clientWaitingForValidation: (id = ":id") =>
    `${clientWaitingForValidation}/${id}`,
  coproperty: (id = ":id", nom = ":nom") =>
    `${coproperties}/${id}/${dashed(nom)}`,
  copropertyLots: (id = ":id", nom = ":nom") =>
    `${coproperties}/${id}/${dashed(nom)}/lots`,
  copropertyMembers: (id = ":id", nom = ":nom") =>
    `${coproperties}/${id}/${dashed(nom)}${members}`,
  copropertyAddDocument: (id = ":id", nom = ":nom") =>
    `${coproperties}/${id}/${dashed(nom)}/add-document`,
  copropertyPermissions: (id = ":id", nom = ":nom") =>
    `${coproperties}/${id}/${dashed(nom)}/coproperty-permissions`,
  employe: (id = ":id", clientFullName = ":clientfullName") =>
    `${users}/${dashed(clientFullName)}/${id}`,
  member: (id = ":id", nom = ":nom", clientFullName = ":clientfullName") =>
    `${members}/${dashed(nom)}${member}/${dashed(clientFullName)}/${id}`,
};
export const publicRoute = {
  login: "/login",
  forgotPassword: "/forgot-password",
};
export const clientSteps = [
  privateRoute.intro(),
  privateRoute.steps(),
  privateRoute.upload(),
  privateRoute.clientLotSelectionPage,
  privateRoute.clientWaitingForValidation(),
];
export const redirectClientRoute = (user) => {
  if (!user?.linkable) return clientDashboard;

  const { lots = [] } = user?.linkable;
  let lot = lots[0];

  if (lots.length > 1) {
    if (!user?.currentLotId) return privateRoute.clientLotSelectionPage;
    lot = lots.find((e) => e.id == user?.currentLotId);
  }

  return lot?.etape_adhesion == 0
    ? privateRoute.intro(lot.id)
    : lot?.etape_adhesion < 6
    ? privateRoute.steps(lot.id)
    : lot?.etape_adhesion < 7
    ? privateRoute.upload(lot.id)
    : lot?.etape_adhesion >= 7 && lot?.etat_adhesion == 1
    ? privateRoute.clientWaitingForValidation(lot.id)
    : clientDashboard;
};
export const adminDahsboarList = (user = {}) => ({
  home: { path: "/", label: "Accueil", props: { exact: true } },
  coproperties: { path: "/coproperties", label: "Copropriétés" },
  ...(isSuperAdminOrAdmin(user)
    ? { documents: { path: "/documents", label: "Documents" } }
    : {}),
  members: { path: "/members", label: "Adhérents" },
  ...(isSuperAdminOrAdmin(user)
    ? { messages: { path: "/messages", label: "Messages" } }
    : {}),
  ...(isSuperAdmin(user)
    ? { users: { path: "/users", label: "Utilisateurs" } }
    : {}),
  setup: { path: "/setup", label: "Réglages" },
});
export const clientDahsboarList = {
  home: { path: clientDashboard, label: "Accueil", props: { exact: true } },
  documents: { path: "/documents", label: "Documents" },
  setup: { path: "/setup", label: "Réglages" },
};
export const clientHasBeenValidate = (user) => {
  if (isCgp(user)) return true;
  const lot = user?.linkable?.lots[0];

  return isClient(user) && lot.etat_adhesion == "2";
};
