import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { connect } from "react-redux";
import {
  Button,
  Wysiwyg,
  Tabs,
  ProfileInfo,
  ResetProfilePassword,
} from "components";
import { MEMBER_LOTS_QUERIES } from "queries";
import { userActions, storage } from "stores";
import {
  formMessageList,
  isvalidPassword,
  isValidConfirmPassword,
} from "helpers";
import { request } from "services";

const Member = ({ dispatch, memberId, alert }) => {
  const { loading, error, data = {} } = useQuery(MEMBER_LOTS_QUERIES, {
    variables: { adherent_id: parseInt(memberId) },
  });
  const [state, setState] = useState(data);

  const handleUpdateProfile = (body) => {
    if (body.password || body.password_confirmation) {
      if (!isvalidPassword(body.password)) {
        return alert.error(formMessageList.password);
      } else if (
        !isValidConfirmPassword(body.password, body.password_confirmation)
      ) {
        return alert.error(formMessageList.confirmPasswordDifferent);
      }
    }

    request({ path: `update-my-profile`, body, method: "PUT" }).then(
      ({ user, token }) => {
        storage.setToken(token);
        dispatch(userActions.update(user));
        alert.success(formMessageList.informationUpdated);
      }
    );
  };

  useEffect(() => {
    if (error || !data?.lots?.data) return;
    const newData = data?.lots?.data[0];
    setState({
      ...newData,
      manager: {
        ...newData.manager,
        ...newData.manager.user,
      },
    });
  }, [loading]);

  return (
    <>
      {state && (
        <div>
          <div className="grid grid-cols-10">
            <div className="col-span-2 pr-4">
              <div className="pt-4 pb-1 px-6"></div>
            </div>
            <div className="col-span-8">
              <ProfileInfo data={state} enableSouscription={false} />
              <Tabs
                data={[
                  {
                    label: "Envoi d’un message",
                    content: (
                      <div className="p-4">
                        <Wysiwyg />
                        <div className="flex mt-5 mb-2 justify-end">
                          <div className="relative self-end mr-2">
                            <Button label="AJOUTER UN DOCUMENT" noMargin />
                            <input
                              type="file"
                              className="absolute cursor-pointer w-full h-full top-0 left-0 opacity-0"
                            />
                          </div>
                          <Button label="ENVOYER" color="tertiary" noMargin />
                        </div>
                      </div>
                    ),
                  },
                  {
                    label: "Réinitialisation mot de passe",
                    className: "flex-1",
                    content: (
                      <ResetProfilePassword
                        user={state.manager}
                        onSubmit={handleUpdateProfile}
                      />
                    ),
                  },
                  {
                    hide: !state.manager?.cgp,
                    label: "Information CGP",
                    content: (
                      <ResetProfilePassword
                        isCgp
                        cgp_can_view={state?.cgp_can_view}
                        cgp_receive_email={state?.cgp_receive_email}
                        user={state.manager?.cgp}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;

  return {
    loggingIn,
  };
}

export default DashboardLayout(connect(mapStateToProps)(Member));
