import React from "react";
import { connect } from "react-redux";
import { Button, Typography, Stepper } from "components";
import { slogan } from "config";
import { userActions } from "stores";

const mapStateToProps = (state) => {
  const { authentication } = state;
  const { user } = authentication;

  return {
    user,
  };
};

const ClientLotSelectionPage = ({ dispatch, user }) => {
  const handleSelect = (id) =>
    dispatch(userActions.update({ ...user, currentLotId: id }));

  return (
    <div className="intro-container bg-primary container flex space-x-10 mx-auto min-h-screen min-w-full justify-center">
      <div className="flex flex-col  space-y-10 items-center w-1/2 min-h-screen px-10 bg-primary">
        <Typography
          color="white"
          className="relative intro-public-asl inline-block mt-4 margin py-2 uppercase italic"
        >
          {slogan}
        </Typography>
        <ul role="list" className="grid grid-cols-2 gap-6 w-full">
          {user?.linkable?.lots?.map((e) => (
            <li
              key={e.id}
              className="justify-between p-4 col-span-1 flex flex-col text-center bg-white rounded-lg shadow hover:bg-gray-200"
            >
              <Typography className="text-center font-semibold divide-y border-gray-200 border-b">
                {e.copropriete?.nom}
                <br />
                Lot n° {e.numero_lot}
              </Typography>
              <Button
                label="Accéder"
                color="fill-primary"
                noMargin
                className="my-6 m-auto"
                onClick={() => handleSelect(e.id)}
              />
              <Stepper currStep={e.etape_adhesion} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default connect(mapStateToProps)(ClientLotSelectionPage);
