const token = "token";
const user = "user";

export const storage = {
  // user storage
  user: () => JSON.parse(window.localStorage.getItem(user)),
  setUser: (data) => window.localStorage.setItem(user, JSON.stringify(data)),
  removeUser: () => window.localStorage.removeItem(user),

  // token storage
  token: () => window.localStorage.getItem(token),
  setToken: (data) => window.localStorage.setItem(token, data),
  removeToken: () => window.localStorage.removeItem(token),
};
