import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const defaultNodeame = "SPAN";
const useToggleOpen = ({
  nodeName = defaultNodeame,
  isOpen: defaultIsOpen = false,
} = {}) => {
  const [isOpen, setOpen] = useState(defaultIsOpen);
  const node = useRef();

  const toggleOpen = (e) => {
    if (
      node?.current?.contains(e?.target) &&
      isOpen &&
      e?.target?.nodeName === nodeName
    )
      setOpen(false);
    else if (node.current?.contains(e.target) && !isOpen) setOpen(true);
    else if (!node.current?.contains(e.target)) setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", toggleOpen);
    return () => document.removeEventListener("mousedown", toggleOpen);
  }, [isOpen]);

  return { node, isOpen, toggleOpen, setOpen };
};

useToggleOpen.propTypes = {
  nodeName: PropTypes.string,
  isOpen: PropTypes.bool,
};
useToggleOpen.defaultProps = {
  nodeName: defaultNodeame,
  isOpen: false,
};

const useFormErrors = () => {
  const [errors, addErrors] = useState({});

  const setErrors = (errsArray) => {
    const newErrors = { ...errors };
    errsArray.forEach(({ key, value }) => {
      newErrors[key] = value;
    });

    addErrors(newErrors);
  };

  const clearErrors = () => {
    addErrors({});
  };

  return { errors, setErrors, clearErrors };
};

export { useToggleOpen, useFormErrors };
