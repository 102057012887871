import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { Typography, DropDown, Loading, Icon, Modal } from "components";
import { privateRoute } from "routes";
import { userActions } from "stores";
import { slogan, mailContact } from "config";
import { STEPS_LOT_QUERIES } from "queries";
import { LegalPage } from "components/client/LegalPage";

const ClientLayout = (Component, { hideWrapper } = {}) =>
  connect()(
    ({ dispatch, user = {}, alert, match: { params: { id } } = {} }) => {
      const [open, setOpen] = useState(false);
      const { loading, data, refetch } = useQuery(STEPS_LOT_QUERIES, {
        variables: { lot_id: parseInt(id), fetchPolicy: "network-only" },
      });
      const [state, setState] = useState({});

      const menuList = [
        {
          label: "REPRENDRE MON ADHÉSION",
          className: "hover:text-secondary",
          path: privateRoute.intro(id),
        },
        {
          label: "SIGNALER UN PROBLÈME",
          className: "hover:text-secondary",
          props: {
            onClick: (e) => {
              e.preventDefault();
              window.location.href = `mailto:${mailContact}`;
            },
          },
        },
        {
          path: "#",
          label: "MENTIONS LÉGALES",
          className: "hover:text-secondary",
          props: {
            onClick: () => setOpen(true),
          },
        },
        {
          label: "Se déconnecter",
          className: "hover:text-secondary",
          props: {
            onClick: () => dispatch(userActions.logout()),
          },
        },
      ];
      useEffect(() => {
        if (data) {
          const { lots } = data;
          const lot = lots?.data[0];

          setState({
            ...lot?.manager,
            ...lot?.manager?.user,
            ...lot,
          });
        }
      }, [data]);

      if (loading) return <Loading fullView background="primary" />;

      return (
        <>
          {hideWrapper ? (
            <Component data={state} user={user} dispatch={dispatch} />
          ) : (
            <>
              <header className="flex justify-between items-center py-4 bg-primary border-b-8 border-secondary">
                <div className="mobile:hidden flex-1 pl-11">
                  <Typography
                    type="h3"
                    noMargin
                    color="white"
                    className="border-r border-white-500 inline-block pr-3"
                  >
                    {state.lot?.manager?.adresse}
                  </Typography>
                </div>
                <div className="flex-1 ">
                  <Link to={privateRoute.intro(id)}>
                    <Icon
                      color="white"
                      type="logo"
                      customSize={{ margin: "auto", height: "40" }}
                      alt="ASL"
                    />
                  </Link>
                </div>
                <div className="relative flex-1 flex justify-end mr-11">
                  <DropDown
                    title="AIDE"
                    menuList={menuList}
                    className="pl-20 w-56"
                  />
                </div>
              </header>
              <div className="flex flex-col client-container bg-primary pt-5">
                <Typography
                  color="secondary"
                  className="text-center bg-primary font-semibold"
                >
                  {slogan}
                </Typography>
                <div className="relative flex items-center flex-grow px-4 sm:px-5 lg:px-20">
                  <Component
                    data={state}
                    user={user}
                    dispatch={dispatch}
                    refetch={refetch}
                    alert={alert}
                  />
                </div>
              </div>
              <footer className="client-footer bg-primary pb-2 pt-6">
                <div></div>
              </footer>
              <Modal open={open} setOpen={setOpen} className="bg-white">
                <LegalPage />
              </Modal>
            </>
          )}
        </>
      );
    }
  );

export default ClientLayout;
