import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { paginateLimit } from "helpers";
import { request } from "services";
import { EMPLOYEES_QUERIES } from "queries";
import View from "./AdminCopropertyPermissionPage";

const AdminCopropertyPermissionPage = ({
  userHasPermission,
  match: {
    params: { id },
  },
}) => {
  const { loading, error, data = {}, fetchMore, refetch } = useQuery(
    EMPLOYEES_QUERIES,
    {
      fetchPolicy: "network-only",
      variables: {
        page: 1,
        query: "",
        first: paginateLimit,
        copropriete_id: parseInt(id),
      },
    }
  );
  const [state, setState] = useState(data);
  const [selected, setSelected] = useState([]);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => setState(data));
  const handleSearch = ({ target: { value } }) =>
    fetchMore({ variables: { query: `%${value}%` } }).then(({ data }) =>
      setState(data)
    );
  const handleSubmit = async () => {
    try {
      await request({
        path: `copropriete/grant-users/${id}`,
        body: {
          users: selected,
        },
      });
      await refetch();
    } catch (error) {}
  };
  const updateState = (data) => {
    if (!data) return;
    const usersId = data?.coproprietes?.data[0]?.usersId ?? [];

    setState(data);
    setSelected(usersId);
  };
  useEffect(() => {
    if (!error) {
      updateState(data);
    }
  }, [loading]);

  const employees = state?.employes?.data ?? [];

  return (
    <View
      userHasPermission={!!userHasPermission.attribute_coproperty_access}
      onCheck={setSelected}
      data={state?.employes}
      list={employees}
      selected={selected}
      handlePaginate={handlePaginate}
      handleSubmit={handleSubmit}
    />
  );
};

const connectedAdminCopropertyPermissionPage = DashboardLayout(
  AdminCopropertyPermissionPage,
  {
    withSidebar: true,
  }
);
export { connectedAdminCopropertyPermissionPage as AdminCopropertyPermissionPage };
