const isArray = (arr) => Array.isArray(arr);
const isFn = (fn) => fn && typeof fn === "function";
const isObj = (obj) =>
  Object.prototype.toString.call(obj) === "[object Object]";
const isBool = (data) => data && typeof data == "boolean";
const clsx = (...classes) => classes.join(" ").trim();
const ucFirst = (name = "", restLowercase = false) =>
  name
    ? name.charAt(0).toUpperCase() +
      (restLowercase ? name.slice(1).toLocaleLowerCase() : name.slice(1))
    : "";

const pluralize = (name, count, suffix = "s") =>
  `${name}${count === undefined || count > 1 ? suffix : ""}`;

const dashed = (name) => name?.split(" ").join("-");
const unDashed = (name) => name?.split("-").join(" ");
const toggleArray = (array, name) => {
  let found = false;

  if (!isArray(array)) return array;

  const data = array.filter((elem) => {
    if (elem === name) found = true;

    return elem !== name;
  });

  if (!found) data.push(name);
  return data;
};
const pick = (object = {}, keys = []) =>
  keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = object[key];
    }

    return obj;
  }, {});
const spaceCurrency = (x, spacer = " ") =>
  x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, spacer) : x;
const unPick = (object = {}, keys) =>
  pick(
    object,
    Object.keys(object).filter((e) => !keys.includes(e))
  );
const arrayToObject = (arr = []) =>
  arr?.reduce((a, v) => ({ ...a, [v]: v }), {});
const truncate = (str, nb) => {
  if (!str) return "";
  const arr = str?.split(" ");
  const truc = arr.slice(0, nb) || [];

  return truc.join(" ")?.concat(arr.length > nb ? "..." : "");
};
const ordinalize = (nb) => (isNaN(nb) || nb < 1 ? "" : nb === 1 ? "er" : "ème");

export {
  clsx,
  isObj,
  ucFirst,
  pluralize,
  dashed,
  unDashed,
  toggleArray,
  isArray,
  arrayToObject,
  ordinalize,
  pick,
  unPick,
  truncate,
  isFn,
  isBool,
  spaceCurrency,
};
