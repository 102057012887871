import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { request, websockets } from "services";
import {
  isvalidPassword,
  isValidConfirmPassword,
  formMessageList,
  unPick,
} from "helpers";
import { EMPLOYE_QUERIES } from "queries";
import View from "./Employe";

const Employe = ({
  match: {
    params: { id },
  },
  alert,
}) => {
  const { loading, error, data = {}, refetch } = useQuery(EMPLOYE_QUERIES, {
    variables: { id: parseInt(id) },
  });
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(data);
  const [viewUrl, setViewUrl] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleViewClick = (url) => {
    setOpen(true);
    setViewUrl(url);
  };
  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => updateState(data));
  const hanleSidebar = (roleId) => {
    // setSelectedRole(roleId);
    // handleFilterRole(state, roleId);
  };
  const handleUpload = async ({ name, file, data }) => {
    try {
      const body = new FormData();

      body.append(name, file);

      await request({
        path: data?.id
          ? `document/replace/${data.id}`
          : `document/attach/${id}?model=lot`,
        body,
        multiform: true,
      });

      const res = await refetch();

      updateState(res.data);
    } catch (error) {}
  };
  const onChange = ({ target: { name, value } }) =>
    setState({ ...state, [name]: value });
  const handleSubmit = (data) => {
    request({
      path: `message`,
      body: {
        titre: "ASL",
        documents: selected,
        ...data,
        manager_id: 1,
      },
    });
  };

  const requestFn = async (args) => {
    try {
      await request({
        method: "PUT",
        path: `update-user-profile/${id}`,
        body: args,
      });
      await refetch();
    } catch (error) {}
  };
  // Reset password
  const handleResetPasswordSubmit = (
    { password, password_confirmation } = {},
    email,
    cb
  ) => {
    if (!isvalidPassword(password))
      return alert.error(formMessageList.password);
    if (!isValidConfirmPassword(password, password_confirmation))
      return alert.error(formMessageList.confirmPasswordDifferent);
    if (!confirm("Confirmez-vous le changement de mots de passe ?")) return;

    requestFn({ password, password_confirmation });
  };

  // Informations
  const handleInformations = () => requestFn(unPick(state, ["user"]));

  // change user role
  const handleRoleChange = (roleId) =>
    requestFn({
      role: roleId,
    });

  // permission Tab

  const handlePermissions = async () => {
    try {
      await request({
        path: "copropriete/grant-coproprietes",
        body: {
          userId: id,
          coproprietes: selectedPermissions,
        },
      });
      await refetch();
    } catch (error) {}
  };

  const updateState = (data = {}) =>
    data?.employes?.data ? setState(data?.employes?.data[0]) : "";

  useEffect(() => {
    if (!error) updateState(data);
  }, [loading]);

  useEffect(() => {
    websockets.lotStepChange(() => {
      refetch()?.then(({ data }) => {
        updateState(data);
      });
    });
  }, []);
  const manager = state;

  return (
    <View
      open={open}
      state={state}
      coproperties={data?.coproprietes?.data}
      viewUrl={viewUrl}
      manager={manager}
      role={manager?.user?.roles[0]}
      selectedPermissions={selectedPermissions}
      setOpen={setOpen}
      onChange={onChange}
      setSelected={setSelected}
      hanleSidebar={hanleSidebar}
      handleSubmit={handleSubmit}
      handleUpload={handleUpload}
      handlePaginate={handlePaginate}
      handleViewClick={handleViewClick}
      handleRoleChange={handleRoleChange}
      handlePermissions={handlePermissions}
      handleInformations={handleInformations}
      onPermissionChange={setSelectedPermissions}
      handleResetPasswordSubmit={handleResetPasswordSubmit}
    />
  );
};

const connectedEmploye = DashboardLayout(Employe);
export { connectedEmploye as Employe };
