import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { DashboardLayout } from "layouts";
import {
  Typography,
  Icon,
  Button,
  Pagination,
  TableActivities,
  Loading,
  Carousel,
} from "components";
import { privateRoute } from "routes";
import { pluralize } from "helpers";
import { useSubscription } from "services";

const DASHBOARD_QUERIES = gql`
  query($first: Int, $page: Int) {
    stats {
      nbSignedDocs
      signedDocs {
        id
      }

      nbFinalizedLots
      finalizedLots {
        id
      }

      nbNewCoproperties
      newCoproperties {
        id
        nom
      }
    }

    coproprietes(first: $first, page: $page) {
      paginatorInfo {
        total
        perPage
        hasMorePages
      }
      data {
        id
        nom
        validate
        images {
          id
          url
          type
          name
          signed
          location
          created_at
        }
      }
    }
    # notifications {
    #   data {
    #     id
    #     message {
    #       titre
    #     }
    #     copropriete {
    #       nom
    #     }
    #   }
    # }
    activites(first: 5) {
      data {
        id
        date
        action
        user {
          linkable_type
          linkable {
            id
            nom
            prenom
          }
        }
        # employe {
        #   id
        #   nom
        #   prenom
        # }
        # copropriete {
        #   id
        #   nom
        # }
      }
    }
  }
`;
const stats = {
  signedDocs: {
    title: "Documents signés",
    nbKey: "nbSignedDocs",
    subTitle: (count = 0) =>
      `${count} ${pluralize("nouveau", count, "x")} ${pluralize(
        "document",
        count,
        "s"
      )} ${pluralize("signé", count)}`,
    iconName: "paper",
    to: privateRoute.documentsPage(),
  },
  finalizedLots: {
    title: "Dossier finalisé",
    nbKey: "nbFinalizedLots",
    subTitle: (count = 0) =>
      `${count} ${pluralize("nouveau", count, "x")} ${pluralize(
        "dossier",
        count
      )}  ${pluralize("finalisé", count)}`,
    iconName: "folder",
    to: "#",
  },
  newCoproperties: {
    title: "Nouvelle copropriété",
    nbKey: "nbNewCoproperties",
    subTitle: (count = 0) =>
      `${count} ${pluralize("nouvelle", count)} ${pluralize(
        "copropriété",
        count
      )}`,
    iconName: "house",
    to: privateRoute.coproperties,
  },
};

const AdminHomePage = () => {
  const { loading, error, data = {}, fetchMore, refetch } = useQuery(
    DASHBOARD_QUERIES,
    {
      fetchPolicy: "network-only",
      variables: { first: 4, page: 1 },
    }
  );
  const [state, setState] = useState(null);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => setState(data));

  useEffect(() => {
    if (!error) setState(data);
  }, [loading]);

  useSubscription("reload-client", "ReloadClient", console.log);
  useSubscription(
    "admin-dashboard-update",
    "AdminDashboardUpdate",
    async () => {
      try {
        const { data } = await refetch();

        setState(data);
      } catch (error) {}
    }
  );

  if (loading) return <Loading />;

  return (
    <>
      <Typography color="white">Statistiques sur les 30 derniers jours</Typography>
      <div className="grid grid-cols-3 gap-4 mobile:grid-cols-1 mobile:gap-x-0">
        {Object.keys(stats).map((key) => {
          const { title, subTitle, iconName, to, nbKey } = stats[key];
          const nb = state?.stats ? state?.stats[nbKey] : 0;

          return (
            <div
              key={key}
              className="relative border border-secondary px-5 pt-5 pb-7 mobile:pl-6 mobile:pr-3"
            >
              {nb ? (
                <Icon
                  type="adminTriagle"
                  className="inline-block absolute top-1 left-1 mobile:top-2 mobile:left-2"
                  color="secondary"
                />
              ) : (
                ""
              )}
              <div className="mobile:flex mobile:flex-row-reverse mobile:justify-between">
                <div className="text-right">
                  <Icon
                    type={iconName}
                    className="inline-block"
                    color="secondary"
                    size="large"
                  />
                </div>
                <div>
                  <Typography color="white" noMargin className="italic">
                    {title}
                  </Typography>
                  <Typography
                    color={nb ? "tertiary" : "secondary"}
                    noMargin
                    className="mt-5 uppercase mobile:mt-0"
                  >
                    <Link to={to}>{subTitle(nb)}</Link>
                  </Typography>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="my-12">
        <TableActivities
          activites={state.activites}
          handlePaginate={console.log}
        />
      </div>
      {state?.coproprietes ? (
        <div className="">
          <div className="mx-auto grid gap-6 grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-1 pb-8">
            {state.coproprietes.data?.map(
              ({ id, nom, validate, images = [] }) => (
                <div
                  key={id}
                  className="relative flex flex-col rounded-xl rounded-tr-none rounded-bl-none shadow-lg overflow-hidden"
                >
                  {!validate ? (
                    <div className="absolute top-0 left-0 w-full flex justify-center z-10">
                      <div className="bg-tertiary text-primary py-1 px-2 rounded-b-lg">
                        NOUVEAU
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex-shrink-0 bg-white p-px h-36">
                    <Carousel dots arrows list={images?.map((e) => e.url)} />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between items-center">
                    <Typography variant="subtitle1">ASL - {nom}</Typography>
                    <Button
                      to={privateRoute.coproperty(id, nom)}
                      label="DÉCOUVRIR"
                      noMargin
                      className="mt-2"
                      btnClass="py-2"
                    />
                  </div>
                </div>
              )
            )}
          </div>
          <Pagination
            perPage={state.coproprietes.paginatorInfo?.perPage}
            count={state.coproprietes.paginatorInfo?.total}
            onChange={handlePaginate}
            className="mt-10 text-center"
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const connectedAdminHomePage = DashboardLayout(AdminHomePage);
export { connectedAdminHomePage as AdminHomePage };
