import React from 'react';
import { userActions } from 'stores';
import { Typography, Button } from 'components';
import ClientLayout from 'layouts/client';

const WaitingForValidationPage = ({ dispatch }) => (
  <>
    <div className="bg-primary container flex space-x-10 mx-auto min-w-full justify-center">
      <div className="flex flex-col justify-between xs:w-32 space-y-20 items-center px-20 bg-primary mobile:px-0">
        <div className="flex flex-1 pb-5 flex-col space-y-20 items-center">
          <Typography
            variant="h3"
            color="white"
            className="relative inline-block my-10 margin py-2 leading-loose text-center font-semibold"
          >
            Merci, votre adhésion est finalisée.
            <br />
            Nous reviendrons rapidement vers vous afin de vous confirmer la
            validation de celle-ci.
          </Typography>
          <Typography
            color="white"
            className="relative inline-block my-10 margin py-2 leading-loose text-center font-semibold italic"
          >
            L’équipe ASL
          </Typography>
          <Button
            color="fill-tertiary"
            label="Fermer"
            className="w-2/4"
            halfPadding
            full
            onClick={() => dispatch(userActions.logout())}
          />
        </div>
      </div>
    </div>
  </>
);

export default ClientLayout(WaitingForValidationPage);
