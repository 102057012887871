import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { clsx, ucFirst, useToggleOpen } from "helpers";
import { shared } from "lib";
import Icon, { iconTypes } from "./Icon";

export const InputTypes = [
  "text",
  "textarea",
  "text",
  "email",
  "number",
  "password",
  "search",
  "tel",
];

const InputBase = ({
  className,
  name,
  color,
  suffix,
  label,
  options = [],
  placeholderColor,
  iconName,
  iconProps = {},
  error,
  type,
  noBorder,
  bordered,
  noMargin,
  onChange,
  onEnterPress,
  onSelect,
  value,
  focus,
  ...props
}) => {
  const isSearch = type === "search";
  const { node, isOpen, setOpen } = useToggleOpen({
    isOpen: isSearch,
  });
  const [state, setState] = useState(value);

  const handleChange = (e) => {
    const value = e.target.value;
    setState(value);
    if (!isOpen) setOpen(true);
    onChange && onChange(e, value);
  };
  const handleEnterPress = (e) => {
    if (!onEnterPress || e.charCode != 13) return;
    onEnterPress(e);
  };
  const handleSelect = (value) => {
    setState(value);
    setOpen(false);
    onSelect && onSelect(value);
  };
  useEffect(() => setState(value), [value]);

  return (
    <div ref={node} className={`relative ${noMargin ? "" : "mb-4"}`}>
      {label ? (
        <label
          htmlFor={name}
          className={`block text-sm font-medium ${shared[color]}`}
        >
          {ucFirst(label)}
        </label>
      ) : (
        ""
      )}
      <div
        className={`flex items-center ${noMargin ? "" : "mt-1"} ${
          suffix ? "suffix" : ""
        }`}
      >
        {iconName ? (
          <Icon
            type={iconName}
            customSize={{ width: 17 }}
            {...iconProps}
            className={clsx("mr-3", iconProps.className)}
          />
        ) : (
          ""
        )}
        {type !== "textarea" ? (
          <input
            className={clsx(
              "block text-xs bg-transparent focus:ring-0 border-0 border-b w-full border-gray focus:border-secondary-700",
              shared[color],
              isSearch ? "focus:border-0" : "",
              placeholderColor ? `placeholder-${placeholderColor}` : "",
              noBorder ? "border-none" : "",
              bordered ? "border border-secondary rounded-md" : "",
              error ? "border-red-300 text-red-300 placeholder-red-300" : "",
              className
            )}
            name={name}
            type={type}
            onChange={handleChange}
            value={state}
            onKeyPress={handleEnterPress}
            autoFocus={focus}
            {...props}
          />
        ) : (
          <textarea
            className={clsx(
              "block text-xs bg-transparent focus:ring-0 border-0 border-b w-full border-gray focus:border-secondary-700",
              shared[color],
              isSearch ? "focus:border-0" : "",
              placeholderColor ? `placeholder-${placeholderColor}` : "",
              noBorder ? "border-none" : "",
              bordered ? "border border-secondary rounded-md" : "",
              error ? "border-red-300 text-red-300 placeholder-red-300" : "",
              className
            )}
            name={name}
            type={type}
            onChange={handleChange}
            autoFocus={focus}
            {...props}
            value={state}
          />
        )}
      </div>
      <div className="absolute bottom-0 transform translate-y-full bg-white w-full">
        {isSearch &&
          isOpen &&
          options.map(({ name, value }) => (
            <div
              key={value}
              className="cursor-pointer p-2 text-sm text-secondary hover:bg-gray-50"
              onClick={() => handleSelect(value)}
            >
              {name}
            </div>
          ))}
      </div>
    </div>
  );
};
const samePropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onEnterPress: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOf(Object.keys(shared)),
  placeholderColor: PropTypes.oneOf(Object.keys(shared)),
  iconName: PropTypes.oneOf(iconTypes),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  iconProps: PropTypes.object,
  type: PropTypes.oneOf(InputTypes),
  placeholder: PropTypes.string,
  error: PropTypes.any,
  suffix: PropTypes.string,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  noBorder: PropTypes.bool,
  bordered: PropTypes.bool,
  noMargin: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};
const sameDefaultProps = {
  value: "",
  label: undefined,
  onEnterPress: undefined,
  onSelect: undefined,
  iconName: undefined,
  error: undefined,
  suffix: undefined,
  disabled: false,
  focus: false,
  noBorder: false,
  bordered: false,
  noMargin: false,
  options: undefined,
  iconProps: {},
  type: "text",
  color: "primary",
  placeholderColor: "primary",
  className: "",
  placeholder: "",
};
InputBase.propTypes = samePropTypes;
InputBase.defaultProps = sameDefaultProps;

export default InputBase;
