import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { Table, CopropertyInfo } from "components";
import { privateRoute } from "routes";
import { tableHeaders, paginateProps } from "helpers";
import { request } from "services";

const COPROPERTY_QUERIES = gql`
  query($id: Int) {
    coproprietes(id: $id) {
      paginatorInfo {
        total
        perPage
        hasMorePages
      }
      data {
        id
        nom
        validate
        adresse
        nombre_lots
        nombre_adherents
        images {
          id
          url
          type
          name
          signed
          location
          created_at
        }
        lots {
          id
          numero_lot
          superficie
          manager {
            nom
            prenom
          }
        }
        documents {
          id
        }
      }
    }
  }
`;

const Lots = ({ match: { params: { id } = {} } } = {}) => {
  const { error, data = {}, fetchMore } = useQuery(COPROPERTY_QUERIES, {
    variables: { id: parseInt(id) },
  });
  const [state, setState] = useState(data);
  const [dataState, setDataState] = useState(false);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => setState(data));
  const handleValidate = () =>
    request({ path: `copropriete/valider/${id}`, method: "GET" }).then(() =>
      setDataState(true)
    );
  useEffect(() => {
    if (!error) setState(data);
  }, [data]);
  const coproperty = state?.coproprietes?.data
    ? state?.coproprietes?.data[0]
    : null;
  const isValidata = dataState || coproperty?.validate;

  return (
    <>
      {coproperty ? (
        <div>
          <CopropertyInfo
            coproperty={coproperty}
            handleValidate={handleValidate}
            isValidata={isValidata}
          />
          <div className="grid mt-7">
            <Table
              header={tableHeaders.lots}
              paginateProps={paginateProps({
                data: coproperty?.lots,
                autoPaginate: true,
              })}
              content={coproperty?.lots.map(
                ({ id, numero_lot, superficie, manager = {} }) => ({
                  id,
                  numero_lot,
                  superficie: superficie + " m²",
                  user: manager?.nom,
                  view: privateRoute.member(
                    id,
                    coproperty?.nom,
                    `${manager?.prenom || ""} ${manager?.nom || ""}`
                  ),
                })
              )}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const connectedCoproperty = DashboardLayout(Lots, { withSidebar: true });
export { connectedCoproperty as Lots };
