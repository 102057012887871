import React, { useState } from 'react';
import {
  Radio,
  Modal,
  Typography,
  FormSciInputs,
  FormSciUploads,
} from 'components';
import { formFields, ucFirst, clientUserTypes } from 'helpers';

const ClientStepsPage = ({
  onChange,
  state = {},
  errors = [],
  handleCheck,
  handleUpload,
  handleLegalForm,
} = {}) => {
  const [open, setOpen] = useState(false);
  const {
    clients = [],
    fiscalite,
    copropriete: { fiscalites = [] } = {},
  } = state;

  const onCheck = (val) => {
    setOpen(val === 'available_sci');
    handleLegalForm(val);
  };
  const handleConfirm = () => setOpen(false);

  return (
    <>
      <div className="grid grid-flow-col grid-cols-2 gap-4 mobile:flex mobile:flex-col">
        <div>
          <Typography variant="h2" color="secondary" className="mb-4">
            PERSONNE REPRÉSENTANTE
          </Typography>
          <Radio
            disabled
            defaultValue={state.client_id}
            items={clients
              .filter((e) => e.type == clientUserTypes.client)
              .map((elem) => ({
                label: `${elem.sexe || ''} ${ucFirst(elem.prenom)} ${ucFirst(
                  elem.nom
                )}`,
                name: elem.id,
              }))}
            name={'client_id'}
            variant="primary"
            onChange={handleCheck}
            error={errors.includes(formFields.gender.name)}
          />
          <br />
          <br />
          <Typography variant="h2" color="secondary" className="mb-4">
            FORME JURIDIQUE
          </Typography>
          <Radio
            defaultValue={
              state?.sci
                ? formFields.available_sci.name
                : formFields.proper_name.name
            }
            items={[
              formFields.proper_name,
              formFields.available_sci,
              formFields.new_sci,
            ]}
            name={formFields.dirigentUser.name}
            variant="primary"
            onChange={onCheck}
            error={errors.includes(formFields.gender.name)}
          />
        </div>
        <div>
          <Typography variant="h2" color="secondary" className="mb-4">
            OPTION FISCALE
          </Typography>
          <Radio
            defaultValue={fiscalite?.id || fiscalites[0]?.id}
            items={[
              ...fiscalites.map(({ id, nom }) => ({
                label: ucFirst(nom),
                name: id,
              })),
              formFields.fiscalityLater,
            ]}
            name="fiscalite_id"
            variant="primary"
            onChange={handleCheck}
            error={errors.includes(formFields.gender.name)}
          />
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} onConfirm={handleConfirm}>
        <>
          <FormSciInputs values={state} errors={errors} onChange={onChange} />
          <FormSciUploads state={state} handleUpload={handleUpload} />
        </>
      </Modal>
    </>
  );
};

export default ClientStepsPage;
