import React, { useState } from "react";
import { connect } from "react-redux";
import { Typography, Input, Checkbox, Button } from "components";
import publicLayout from "layouts/public";
import { userActions } from "stores";
import { publicRoute } from "routes";
import { slogan } from "config";

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
}
const LoginPage = ({ dispatch }) => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    submitted: false,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (e) => {
    const { email, password, code } = credentials;

    e.preventDefault();
    if (email && password) {
      dispatch(userActions.login(email.trim(), password.trim(), code?.trim(), credentials, setCredentials));
    }
  };

  return (
    <div>
      <Typography variant="h2" className="mt-16 text-center">
        Bienvenue sur votre interface de gestion
      </Typography>

      <div className="mt-8">
        <div className="mt-6">
          <form action="/login" method="POST">
            <div>
              <Input
                focus
                name="email"
                type="email"
                iconName="user"
                placeholder="Identifiant"
                onChange={onChange}
                onEnterPress={handleSubmit}
              />
            </div>
            <div className="space-y-1">
              <Input
                name="password"
                type="password"
                iconProps={{
                  className: "text-primary fill-current",
                }}
                iconName="lock"
                placeholder="Mot de passe"
                onChange={onChange}
                onEnterPress={handleSubmit}
              />
            </div>
            {credentials.code !== undefined && <div className="space-y-1">
              <Input
                focus
                name="code"
                type="text"
                iconProps={{
                  className: "text-primary fill-current",
                }}
                iconName="lock"
                placeholder="Code de vérification"
                onChange={onChange}
                onEnterPress={handleSubmit}
              />
            </div>}
            <Checkbox
              name="remember"
              variant="white"
              containerClass="mb-4"
              items={[{ name: "remember", label: "Se souvenir de moi" }]}
              onChange={console.log}
            />
            <div className="gap-x-4 flex items-center w-full justify-center mt-10">
              <Button
                label="VALIDER"
                className="w-2/4"
                full
                halfPadding
                onClick={handleSubmit}
              />
              <Button
                to={publicRoute.forgotPassword}
                color="fill-primary"
                label="Mot de passe perdu"
                className="w-2/4"
                halfPadding
                full
              />
            </div>
          </form>
        </div>
      </div>
      {/* <Button label="Usign" onClick={handleUsign} /> */}
      <Typography className="public-asl py-2">{slogan}</Typography>
    </div>
  );
};
const connectedLoginPage = publicLayout(connect(mapStateToProps)(LoginPage));
export { connectedLoginPage as LoginPage };
