import React, { useState } from "react";

import { Typography, Checkbox, Icon, Modal } from "components";
import { publiPath } from "helpers";

const ClientStepsPage = ({ handleCheck } = {}) => {
  const [open, setOpen] = useState(false);
  const [viewUrl, setViewUrl] = useState(false);
  const handleViewClick = (path) => {
    setOpen(true);
    setViewUrl(publiPath(path));
  };

  return (
    <>
      <div
        className="flex items-center mb-2 mt-3 cursor-pointer"
        onClick={() =>
          handleViewClick(
            "uploads/document_explicatif_du_pack_tranquillite.pdf"
          )
        }
      >
        <Icon type="pack" color="white" customSize={{ width: 50 }} />
        <Typography
          noMargin
          variant="h2"
          className="ml-4 bg-tertiary text-primary hover:bg-secondary hover:text-tertiary inline-block focus:ring-0 border-0 border-b px-7 py-2 rounded"
        >
          Consulter le pack tranquillité
        </Typography>
      </div>
      <div className="mb-8 pl-10">
        <Checkbox
          value="mr"
          containerClass="mb-4"
          items={[
            {
              name: "pack_document",
              label: "J’ai pris connaissance du pack tranquillité.",
            },
          ]}
          name="valide_asl"
          variant="primary"
          inlineIcon
          onChange={handleCheck}
        />
      </div>
      <Modal open={open} setOpen={setOpen} fullView>
        <iframe src={viewUrl} className="w-full h-full"></iframe>
      </Modal>
    </>
  );
};

export default ClientStepsPage;
