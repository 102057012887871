import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Wysiwyg = ({ defaultValue = "", onChange }) => {
  const [value, setValue] = useState("");

  const handleChange = (value) => {
    setValue(value);
    onChange && onChange(value);
  };

  useEffect(() => handleChange(defaultValue), [defaultValue]);

  return (
    <ReactQuill
      value={value}
      onChange={handleChange}
      defaultValue={defaultValue}
    />
  );
};
export default Wysiwyg;
