import { gql } from "@apollo/client";

export const COPROPERTY_QUERIES = gql`
  query($id: Int) {
    coproprietes(id: $id) {
      data {
        id
        nom
        adresse
        validate
        is_signed
        nombre_lots
        description
        nombre_adherents
        riv_partenaire_id
        images {
          id
          url
          type
          name
          signed
          location
          created_at
        }
        documents {
          id
          url
          type
          name
          signed
          location
          created_at
          mediable_id
          originalName
          mediable_type
          sharedWith {
            id
            nom
            prenom
            telephone
          }
        }
      }
    }
  }
`;
export const COPROPERTIES_QUERIES = gql`
  query($first: Int, $page: Int) {
    coproprietes(first: $first, page: $page) {
      paginatorInfo {
        total
        perPage
        hasMorePages
      }
      data {
        id
        nom
        validate
        adresse
        archived
        nombre_lots
        description
        nombre_adherents
        images {
          id
          url
          type
          name
          signed
          location
          created_at
        }
      }
    }
  }
`;
