const documents = [
  { field: "name", headerName: "Documents" },
  { field: "view" },
];
const tableHeaders = {
  documents,
  activities: [
    { field: "date", headerName: "DATE" },
    { field: "action", headerName: "MESSAGE" },
    { field: "user", headerName: "UTILISATEUR" },
  ],
  adminActivities: [
    { field: "date", headerName: "DATE" },
    { field: "action", headerName: "MESSAGE" },
    { field: "coproperty", headerName: "COPROPRIÉTÉ" },
    { field: "user", headerName: "UTILISATEUR" },
    { field: "view" },
  ],
  lots: [
    { field: "numero_lot", headerName: "NUMERO LOT" },
    { field: "superficie", headerName: "SUPERFICIE" },
    { field: "user", headerName: "ADHERENT" },
    { field: "view" },
  ],
  members: [
    { field: "sent", headerName: "Envoyé" },
    { field: "full_name", headerName: "Identifiant" },
    { field: "etat_adhesion", headerName: "Etat de l’adhésion" },
    { field: "etape_adhesion", headerName: "Etape d’adhésion" },
    { field: "documents" },
    { field: "view" },
  ],
  memberMessage: [
    { field: "full_name", headerName: "Identifiant" },
    { field: "telephone", headerName: "Téléphone" },
    { field: "view" },
  ],
  messages: [{ field: "titre", headerName: "SUJETS" }, { field: "view" }],
  users: [
    { field: "fullName", headerName: "IDENTIFIANT" },
    { field: "roleName", headerName: "RÔLES" },
    { field: "view" },
  ],
  documentsWithDate: [{ field: "date", headerName: "DATE" }, ...documents],
  adminDocumentsWithDate: [
    { field: "date", headerName: "DATE" },
    { field: "name", headerName: "Documents" },
    { field: "members" },
    { field: "view" },
  ],
};
const profileFields = {
  gender: { name: "sexe", label: "Civilité" },
  firstName: { name: "prenom", label: "Prénom" },
  lastName: { name: "nom", label: "Nom" },
  email: { name: "email", label: "Adresse mail" },
  phone: { name: "telephone", label: "Téléphone" },
};
const userFields = {
  ...profileFields,
  address: { name: "adresse", label: "Adresse postal" },
  postal: { name: "postal", label: "Code postal" },
  city: { name: "city", label: "Ville" },
  phone: { name: "telephone", label: "Téléphone" },
};
const sciFields = {
  raisonSocial: { name: "raison_social", label: "Raison sociale" },
  capital: { name: "capital", label: "Capital social" },
  rcs: { name: "rcs", label: "RCS 9 chiffres" },
  city: { name: "ville", label: "Ville" },
  adresseDirigeante: {
    name: "sci_adresse",
    label: "Adresse si différent de la personne représentante",
  },
  codePostalDirigeante: { name: "sci_code_postal", label: "Code postal" },
  companyCity: { name: "sci_ville", label: "Ville" },
};
const sciFormFields = {
  proper_name: { label: "En nom propre", name: "proper_name" },
  available_sci: { label: "SCI existante", name: "available_sci" },
  new_sci: { label: "SCI en cours de constitution", name: "new_sci" },
};
const fiscalityFormFields = {
  fiscalityLater: { label: "À renseigner plus tard", name: null },
};
const formFields = {
  ...userFields,
  ...sciFields,
  ...sciFormFields,
  ...fiscalityFormFields,
  dirigent: { name: "dirigent", label: "Personne représentante" },
  dirigentUser: { name: "dirigentUser", label: "Personne représentante" },
  sci: { name: "sci" },
  title: { name: "titre", label: "Titre" },
  role: { name: "role", label: "Role" },
  content: { name: "contenu", label: "Contenu" },
  pack: {
    name: "souscription_pack",
    label: "Souscription pack tranquillité",
  },
};

const lotDatas = {
  numero_edd: "N° de lot EDD",
  numero_lot: "N° de lot de commercialisation",
  etage: "Étage",
  superficie: "Superficie",
  montant_travaux: "Prix des travaux",
  budget_asl: "Budget ASL",
  pack: "Pack Tranquillité",
  montant_total: "Budget total travaux",
};
const employeeRoleList = [
  { id: 1, name: "super_admin", label: "SUPER ADMINISTRATEUR" },
  { id: 2, name: "admin", label: "ADMINISTRATEUR" },
  { id: 3, name: "user", label: "UTILISATEUR" },
];
const gendersObj = {
  mr: "Monsieur",
  ms: "Madame",
};
const isMr = (gender) => gender == gendersObj.mr;

export {
  tableHeaders,
  profileFields,
  userFields,
  formFields,
  lotDatas,
  employeeRoleList,
  gendersObj,
  isMr,
};
