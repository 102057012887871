export const slogan = "Association Syndicale Libre";
export const apiUrl = process.env.REACT_APP_URL;
export const yousignKey = process.env.REACT_APP_YOUSIGN_KEY;
export const yousignUrl = process.env.REACT_APP_YOUSIGN_ENDPOINT;
export const mailContact = process.env.REACT_APP_MAIL_CONTACT;
export const yousignAppUrl = process.env.REACT_APP_YOUSIGN_APP_URL;

export const wsHost = process.env.REACT_APP_WEBSOCKET_HOST;

export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const socketKey = process.env.REACT_APP_SOCKET_KEY;
export const socketCluster = process.env.REACT_APP_SOCKET_CLUSTER;
