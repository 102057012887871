import React from 'react';
import { Input, Typography, UploadSingle } from 'components';
import { formFields } from 'helpers';

export const FormSciInputs = ({
  values = {},
  errors = [],
  onChange,
  disabled = false,
}) => (
  <>
    <div className="flex gap-x-8 mobile:gap-x-0 mobile:flex-col">
      <div className="w-1/2 mobile:w-full">
        <Input
          disabled={disabled}
          name={formFields.raisonSocial.name}
          value={values[formFields.raisonSocial.name] || ''}
          type="text"
          color="white"
          placeholderColor="secondary"
          placeholder={formFields.raisonSocial.label}
          onChange={onChange}
          error={errors.includes(formFields.raisonSocial.name)}
        />
      </div>
      <div className="w-1/2 mobile:w-full">
        <Input
          disabled={disabled}
          suffix="€"
          name={formFields.capital.name}
          value={values[formFields.capital.name] || ''}
          type="number"
          color="white"
          placeholderColor="secondary"
          placeholder={formFields.capital.label}
          onChange={onChange}
          error={errors.includes(formFields.capital.name)}
        />
      </div>
    </div>
    <div className="flex gap-x-8 mobile:flex-col">
      <div className="w-1/2 mobile:w-full">
        <Input
          disabled={disabled}
          name={formFields.rcs.name}
          value={values[formFields.rcs.name] || ''}
          type="number"
          color="white"
          placeholderColor="secondary"
          placeholder={formFields.rcs.label}
          onChange={onChange}
          error={errors.includes(formFields.rcs.name)}
        />
      </div>
      <div className="w-1/2 mobile:w-full">
        <Input
          disabled={disabled}
          name={formFields.city.name}
          value={values[formFields.city.name] || ''}
          type="text"
          color="white"
          placeholderColor="secondary"
          placeholder={formFields.city.label}
          onChange={onChange}
          error={errors.includes(formFields.city.name)}
        />
      </div>
    </div>
    <div className="mt-4">
      <Input
        disabled={disabled}
        name={formFields.adresseDirigeante.name}
        value={values[formFields.adresseDirigeante.name] || ''}
        type="email"
        placeholderColor="secondary"
        color="white"
        placeholder={formFields.adresseDirigeante.label}
        onChange={onChange}
        error={errors.includes(formFields.adresseDirigeante.name)}
      />
    </div>
    <div className="flex gap-x-8 mobile:flex-col">
      <div className="w-1/2 mobile:w-full">
        <Input
          disabled={disabled}
          name={formFields.codePostalDirigeante.name}
          value={values[formFields.codePostalDirigeante.name] || ''}
          type="text"
          color="white"
          placeholderColor="secondary"
          placeholder={formFields.codePostalDirigeante.label}
          onChange={onChange}
          error={errors.includes(formFields.codePostalDirigeante.name)}
        />
      </div>
      <div className="w-1/2 mobile:w-full">
        <Input
          disabled={disabled}
          name={formFields.companyCity.name}
          value={values[formFields.companyCity.name] || ''}
          type="text"
          color="white"
          placeholderColor="secondary"
          placeholder={formFields.companyCity.label}
          onChange={onChange}
          error={errors.includes(formFields.companyCity.name)}
        />
      </div>
    </div>
  </>
);
export const FormSciUploads = ({ state = {}, handleUpload, noMargin }) => (
  <div className={`${noMargin ? '' : 'w-4/5'} m-auto mb-3`}>
    <Typography
      color="secondary"
      variant="subtitle2"
      className="mt-5 text-center"
    >
      Document format jpg, pdf, png
    </Typography>
    <UploadSingle
      name="id_card"
      label="Carte d’identité gérant recto verso"
      data={state.managerIdCard}
      onChange={handleUpload}
    />
    <UploadSingle
      name="kbis"
      label="kbis"
      data={state.managerKbis}
      onChange={handleUpload}
    />
    <UploadSingle
      name="status"
      data={state.managerStatus}
      label="COPIE DES STATUTS"
      onChange={handleUpload}
    />
    <UploadSingle
      name="autorisation"
      label="DÉLIBÉRATION AUTORISANT L’ACQUISITION"
      data={state.managerAutorisation}
      onChange={handleUpload}
    />
  </div>
);
