import React, { useState, useEffect } from "react";
import { Button, Table, FileUpload, Modal, CopropertyInfo } from "components";
import {
  tableHeaders,
  paginateProps,
  pluralize,
  yousignGetMemberSignatureUrl,
  isObj,
  hasValidateAdhesion,
} from "helpers";
import { request } from "services";
import { privateRoute } from "routes";
import { toast } from "react-toastify";

export const TableDocument = ({
  manager = null,
  onCheck,
  documents = [],
  selected = [],
  isToggleable,
  copropertyName,
  handlePaginate,
  showMembersList,
}) => {
  const [open, setOpen] = useState(false);
  const [viewUrl, setViewUrl] = useState(false);
  const [memberList, setMemberList] = useState(null);

  const handleViewClick = async (data = {}) => {
    let url = data.url;

    if (!showMembersList) {
      if (!data?.details?.signed)
        url = yousignGetMemberSignatureUrl(manager?.yousign_client_id);
    }

    setOpen(true);
    setMemberList(null);
    setViewUrl(url);
  };
  const handleReceivedMembers = (receivers) => {
    setMemberList(receivers);
    setOpen(true);
  };

  return (
    <div>
      <Table
        header={
          showMembersList
            ? tableHeaders.adminDocumentsWithDate
            : tableHeaders.documentsWithDate
        }
        isToggleable={isToggleable}
        onCheck={onCheck}
        onViewClick={handleViewClick}
        selected={selected}
        paginateProps={paginateProps({
          data: documents,
          autoPaginate: true,
          onChange: handlePaginate,
        })}
        content={documents?.map(
          ({
            id,
            originalName: name,
            created_at: date,
            url,
            details = {},
            sharedWith = [],
          } = {}) => ({
            id,
            date,
            name,
            url,
            details,
            viewTitle:
              details?.signed || showMembersList ? "CONSULTER" : "SIGNATURE",
            ...(showMembersList
              ? {
                  members: (
                    <span
                      className="text-tertiary cursor-pointer uppercase text-xs"
                      onClick={() => handleReceivedMembers(sharedWith)}
                    >
                      Destinataires
                    </span>
                  ),
                }
              : {}),
            ...(!showMembersList && !details?.signed
              ? {
                  view: "/",
                  redirectable: details?.signed,
                  viewTitle: <span className="text-tertiary">Nouveau</span>,
                }
              : {}),
          })
        )}
      />
      <Modal open={open} setOpen={setOpen} fullView>
        <>
          {memberList ? (
            <Table
              header={tableHeaders.memberMessage}
              content={memberList
                .filter(isObj)
                .map(({ id, nom, prenom, telephone } = {}) => ({
                  full_name: `${nom} ${prenom}`,
                  telephone,
                  view: privateRoute.member(
                    id,
                    copropertyName,
                    `${prenom || ""} ${nom || ""}`
                  ),
                }))}
            />
          ) : (
            <iframe src={viewUrl} className="w-full h-full"></iframe>
          )}
        </>
      </Modal>
    </div>
  );
};

export const AdminTableDocument = ({
  id,
  type,
  selected,
  documents,
  coproperty,
  isValidata,
  apiId,
  showInfo = false,
  userHasPermission,
  refetch,
  onCheck,
  setSelected,
  onApiIdChange,
  onMembersList,
  handleValidate,
  handlePaginate,
  handleValidateApiId,
} = {}) => (
  <>
    {!!showInfo && (
      <div className="mb-7">
        <CopropertyInfo
          apiId={apiId}
          coproperty={coproperty}
          isValidata={isValidata}
          onApiIdChange={onApiIdChange}
          handleValidate={handleValidate}
          userHasPermission={userHasPermission}
          handleValidateApiId={handleValidateApiId}
        />
      </div>
    )}
    <div className="grid mobile:block">
      <TableDocument
        showMembersList
        documents={documents}
        selected={selected}
        onCheck={onCheck}
        onMembersList={onMembersList}
        handlePaginate={handlePaginate}
      />
      {userHasPermission && (
        <FileUpload
          id={id}
          type={type}
          selected={selected}
          refetch={refetch}
          setSelected={setSelected}
        />
      )}
    </div>
  </>
);

export const TableMessage = ({
  messagePredefinis,
  onCheck,
  onViewClick,
  handlePaginate,
  selected,
}) => (
  <Table
    selected={selected}
    header={tableHeaders.messages}
    onCheck={onCheck}
    onViewClick={onViewClick}
    paginateProps={paginateProps({
      data: messagePredefinis,
      onChange: handlePaginate,
    })}
    content={messagePredefinis?.data?.map(({ id, titre, contenu } = {}) => ({
      id,
      titre,
      contenu,
      viewTitle: "Modifier",
    }))}
  />
);

export const TableMembers = ({
  userHasPermission,
  refetch,
  lots = {},
  coproperty = {},
  handlePaginate,
  autoPaginate = false,
}) => {
  const [open, setOpen] = useState(false);
  const [currLotId, setCurrLotId] = useState([]);
  const [selected, setSelected] = useState([]);
  const [viewUrl, setViewUrl] = useState(false);
  const [documentList, setDocumentList] = useState(null);

  const handleViewClick = (data) => {
    setOpen(true);
    setViewUrl(data.url);
  };
  const handleReceivedMembers = (lotId) => {
    setCurrLotId(lotId);
    setOpen(true);
  };
  const handleCancel = () => {
    if (!viewUrl) {
      setOpen(false);
      setCurrLotId(null);
      setDocumentList(null);
    }
    setViewUrl(false);
  };
  const handleDeleteLot = () => {
    if (
      !confirm(
        `Confirmez-vous la suppression ${
          selected.length > 1 ? "des lots" : "du lot"
        } ?`
      )
    )
      return;
    request({
      path: `lot`,
      method: "DELETE",
      body: {
        lots: selected,
      },
    }).then(() => {
      refetch();
      setSelected([]);
    });
  };
  const handleSendToPartner = async () => {
    try {
      const res = await request({
        path: "lot/send-to",
        body: { lots: selected },
      });

      toast.success("Dossier envoyé");
    } catch (error) {}
  };

  const handleValidateLot = () => {
    if (!coproperty?.validate) return alert("Copropriété non validé");

    request({ path: `lot/validate/${selected}` }).then(() => {
      refetch();
      setSelected([]);
    });
  };
  const handleMembership = () => {
    if (!coproperty?.validate) return alert("Copropriété non validé");

    request({
      path: `lot/send-adhesion`,
      body: {
        lots: selected,
      },
    }).then(() => {
      refetch();
      setSelected([]);
    });
  };
  useEffect(() => {
    if (currLotId) {
      setDocumentList(lots?.data?.find((e) => e.id == currLotId)?.documents);
    }
  }, [lots, currLotId]);

  let canSend;
  const content = lots?.data?.map((e = {}) => {
    const {
      id,
      sent,
      numero_lot,
      etat_adhesionFr,
      etape_adhesion,
      manager = {},
    } = e;

    if (selected.includes(e.id) && canSend !== false)
      canSend = hasValidateAdhesion(e);

    return {
      id,
      sent: sent ? "Oui" : "Non",
      full_name: (
        <div className="uppercase">{`${numero_lot} ${manager?.nom}`}</div>
      ),
      etat_adhesion: (
        <div className="text-white uppercase">{etat_adhesionFr}</div>
      ),
      etape_adhesion: (
        <div>{`${pluralize(
          "ÉTAPE",
          etape_adhesion,
          "S"
        )} ${etape_adhesion}`}</div>
      ),
      documents: (
        <span
          className="text-tertiary cursor-pointer uppercase text-xs"
          onClick={() => handleReceivedMembers(id)}
        >
          DOCUMENTS
        </span>
      ),
      view: privateRoute.member(
        id,
        coproperty?.nom,
        `${manager?.prenom || ""} ${manager?.nom || ""}`
      ),
    };
  });

  return (
    <>
      <Table
        header={tableHeaders.members}
        selected={selected}
        onCheck={userHasPermission ? setSelected : null}
        paginateProps={paginateProps({
          data: lots,
          autoPaginate,
          onChange: handlePaginate,
        })}
        content={content}
      />
      {userHasPermission && (
        <div className="flex justify-between mt-10 mobile:flex-col">
          {!!selected.length ? (
            <div className="flex justify-start items-center gap-8">
              <Button
                color="secondary"
                label="SUPPRIMER"
                onClick={handleDeleteLot}
                className="flex-1"
              />
              <Button
                color="tertiary"
                label="DOSSIER AVOCAT"
                onClick={handleSendToPartner}
                disabled={!coproperty?.riv_partenaire_id || !canSend}
              />
            </div>
          ) : (
            <div></div>
          )}
          <div className="flex gap-8 justify-end">
            <Button
              disabled={!selected.length}
              color="tertiary"
              label="ENVOYER L’ADHÉSION"
              onClick={handleMembership}
            />
            <Button
              color="fill-tertiary"
              label="VALIDER LE COMPTE"
              onClick={handleValidateLot}
            />
          </div>
        </div>
      )}

      <Modal open={!!open} setOpen={setOpen} onCancel={handleCancel} fullView>
        <>
          {documentList && !viewUrl ? (
            <>
              <Table
                onCheck={setSelected}
                paginateProps={paginateProps({
                  data: documentList,
                  autoPaginate: true,
                })}
                header={tableHeaders.documents}
                onViewClick={handleViewClick}
                content={documentList?.map(({ id, originalName, url }) => ({
                  id,
                  name: originalName,
                  url,
                }))}
              />
              <FileUpload
                id={currLotId}
                selected={selected}
                setSelected={setSelected}
                refetch={refetch}
              />
            </>
          ) : (
            <iframe src={viewUrl || ""} className="w-full h-full"></iframe>
          )}
        </>
      </Modal>
    </>
  );
};

const activityView = ({ copropriete, linkable = {}, linkable_type }) => {
  if (linkable_type?.includes("copro"))
    return privateRoute.coproperty(copropriete.id, copropriete?.nom);
  return privateRoute.member(
    linkable?.id,
    copropriete?.nom,
    `${linkable?.prenom || ""} ${linkable?.nom || ""}`
  );
};
export const TableActivities = ({
  onCheck,
  handlePaginate,
  activites = {},
}) => (
  <Table
    header={tableHeaders.adminActivities}
    onCheck={onCheck}
    paginateProps={paginateProps({
      data: activites,
      onChange: handlePaginate,
    })}
    content={activites?.data?.map(
      ({
        id,
        type,
        date,
        action,
        user = {},
        manager = {},
        copropriete = {},
      } = {}) => {
        const { linkable_type, linkable } = user || {};

        return {
          id,
          date,
          action: <div className="text-white uppercase">{action}</div>,
          coproperty: <div className="uppercase">{copropriete?.nom}</div>,
          user: <div className="uppercase">{linkable?.nom}</div>,
          view: activityView({ copropriete, linkable, linkable_type }),
        };
      }
    )}
  />
);

export const TableEmployeePermission = ({
  data = {},
  list = [],
  selected = [],
  onCheck,
  handlePaginate,
  handleViewClick,
}) => (
  <Table
    selected={selected}
    header={tableHeaders.users}
    onCheck={onCheck}
    onViewClick={handleViewClick}
    paginateProps={paginateProps({
      data: data.employes,
      onChange: handlePaginate,
    })}
    content={list?.map(({ nom, prenom, telephone, user = {} } = {}) => ({
      id: user?.id,
      nom,
      prenom,
      telephone,
      email: user?.email,
      roleName: <div className="uppercase">{user?.roles[0]?.name}</div>,
      role: user?.roles[0]?.id,
      viewTitle: "MODIFIER",
      fullName: `${nom?.toUpperCase()} ${prenom?.toUpperCase()}`,
    }))}
  />
);
