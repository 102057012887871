import React from "react";
import PropTypes from "prop-types";
import { clsx } from "helpers";
import { shared } from "lib";

const variants = {
  h1: { type: "h1", className: "text-2xl" },
  h2: { type: "h2", className: "text-xl" },
  h3: { type: "h3", className: "text-lg" },
  p: { type: "p", className: "text-base" },
  subtitle1: { type: "p", className: "text-sm" },
  subtitle2: { type: "p", className: "text-xs" },
};

const aligns = ["inherit", "left", "center", "right", "justify"];

const Typography = ({
  children,
  align,
  noMargin,
  color = "primary",
  variant = "p",
  className = "",
  bold,
  ...props
}) => {
  const newVariant = variants[variant];
  const newColor = shared[color];

  return (
    <newVariant.type
      className={clsx(
        newVariant.className,
        newColor,
        noMargin ? "" : "mb-2",
        bold ? "font-bold" : "",
        align,
        className
      )}
      {...props}
    >
      {children}
    </newVariant.type>
  );
};
Typography.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(Object.keys(shared)),
  variant: PropTypes.oneOf(Object.keys(variants)),
  align: PropTypes.oneOf(aligns),
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  bold: PropTypes.bool,
  onClick: PropTypes.func,
};
Typography.defaultProps = {
  variant: "p",
  align: "left",
  color: "primary",
  className: "",
  noMargin: false,
  bold: false,
  onClick: undefined,
};

export default Typography;
