import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ClientLayout from "layouts/client";
import { ucFirst, isBool } from "helpers";
import { userActions } from "stores";
import { request, websockets } from "services";
import View from "./Upload";

const UploadStepPage = ({
  data = {},
  user = {},
  dispatch,
  refetch,
  match: { params: { id } } = {},
}) => {
  const [state, setState] = useState([]);
  const [cgpInfo, setCgpInfo] = useState({
    cgp_can_view: false,
    cgp_receive_email: false,
    no_cgp: false,
  });
  const [open, setOpen] = useState(null);
  const [signature, setSignature] = useState(null);
  const onFileChange = ({ target: { files, name } }) => {
    if (!files) return;
  };
  const handleCheck = (value, { target: { name } }) => {
    let tmp = { ...cgpInfo };
    if (name == "no_cgp") {
      tmp.cgp_can_view = false;
      tmp.cgp_receive_email = false;
    } else {
      tmp.no_cgp = false;
    }
    setCgpInfo({ ...tmp, [name]: value });
  };
  const handleValidate = () => setOpen(2);
  const handleSubmit = () => {
    let newUser = { ...user };
    let { lots } = user.linkable;
    const index = lots.findIndex((e) => (e.id = data.id));

    if (index < 0) return;
    return request({
      path: `lot/step/${data.id}/${lots[index].etape_adhesion}`,
      body: cgpInfo,
    }).then(async () => {
      try {
        if (!state.no_cgp) {
          await request({ path: `lot/send-cgp-adhesion/${id}` });
        }
      } catch (error) {}
      lots[index].etape_adhesion += 1;
      newUser.linkable.lots = lots;
      dispatch(userActions.update(newUser));
    });
  };
  const handleSignClick = () => {
    setSignature(true);
    setOpen(true);
  };
  const handleModalCancel = async () => {
    if (isBool(open)) await refetch();

    setOpen(null);
    setSignature(null);
  };
  const updateState = (data) => {
    setState(
      data?.documents?.map(
        (e) =>
          ({
            ...e,
            originalName: ucFirst(e.originalName.split("_").join(" ")),
          } || [])
      )
    );
  };
  useEffect(() => {
    websockets.documentSigned(() => {
      refetch()?.then(({ data }) => {
        updateState(data);
      });
    }, user?.id);
  }, []);

  useEffect(() => (data?.id ? updateState(data) : null), [data]);

  return (
    <View
      loading={
        !data?.documents?.find((e) => e.name.includes("lettre_adhesion"))
      }
      state={state}
      open={open}
      data={data}
      signature={signature}
      setOpen={setOpen}
      cgpInfo={cgpInfo}
      onFileChange={onFileChange}
      handleValidate={handleValidate}
      handleSignClick={handleSignClick}
      handleModalCancel={handleModalCancel}
      handleCheck={handleCheck}
      handleSubmit={handleSubmit}
    />
  );
};

const mapStateToProps = (state) => {
  const { loggingIn, user } = state.authentication;

  return {
    loggingIn,
    user,
  };
};
const connectedUploadStepPage = ClientLayout(
  connect(mapStateToProps)(withRouter(UploadStepPage))
);
export default connectedUploadStepPage;
