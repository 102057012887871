import {
  roles,
  employeeRoles,
  employeeAdminSupRoles,
  permissions,
} from "helpers";
import {
  Lots,
  Member,
  Members,
  Messages,
  StepsPage,
  LoginPage,
  Employe,
  UploadPage,
  Coproperty,
  Coproperties,
  MembersPage,
  AdminHomePage,
  IntroStepPage,
  AdminSetupPage,
  ClientHomePage,
  AdminUsersPage,
  ClientSetupPage,
  AdminDocumentPage,
  ForgotPasswordPage,
  ClientLotDetailPage,
  RulesAndPermissions,
  WaitingForValidation,
  CopropertyAddDocument,
  ClientLotDocumentPage,
  ClientLotSelectionPage,
  AdminCopropertyPermissionPage,
} from "pages";
import { publicRoute, privateRoute } from "./index";

export const RouteList = [
  //    admin routes
  {
    roles: "*",
    path: privateRoute.home,
    components: {
      admin: AdminHomePage,
      client: ClientHomePage,
    },
  },
  {
    title: "Copropriété",
    roles: employeeRoles,
    path: privateRoute.coproperties,
    components: { admin: Coproperties },
  },
  {
    title: ":nom",
    roles: "*",
    path: privateRoute.coproperty(),
    components: { admin: Coproperty, client: ClientLotDetailPage },
    permissionsList: [permissions.validate_coproperty],
  },
  {
    title: "liste lots",
    roles: employeeRoles,
    path: privateRoute.copropertyLots(),
    components: { admin: Lots },
  },
  {
    title: "liste adhérents",
    roles: employeeRoles,
    path: privateRoute.copropertyMembers(),
    components: { admin: Members },
    permissionsList: [permissions.validate_coproperty],
  },
  {
    title: "liste adhérents",
    roles: employeeRoles,
    path: privateRoute.members(),
    components: { admin: MembersPage },
    permissionsList: [permissions.validate_coproperty],
  },
  {
    title: "télécharger un document",
    roles: employeeRoles,
    path: privateRoute.copropertyAddDocument(),
    components: { admin: CopropertyAddDocument },
  },
  {
    title: "Permissions",
    roles: employeeAdminSupRoles,
    path: privateRoute.copropertyPermissions(),
    components: { admin: AdminCopropertyPermissionPage },
    permissionsList: [permissions.attribute_coproperty_access],
  },
  {
    title: ":clientfullName",
    roles: employeeRoles,
    path: privateRoute.employe(),
    components: { admin: Employe },
  },
  {
    title: ":nom",
    roles: employeeRoles,
    path: privateRoute.member(),
    components: { admin: Member },
  },
  {
    title: "Utilisateurs",
    roles: [roles.superAdmin],
    path: privateRoute.users,
    components: { admin: AdminUsersPage },
  },
  {
    title: "Permissions",
    roles: [roles.superAdmin],
    path: privateRoute.rulesAndPermissionsPage,
    components: { admin: RulesAndPermissions },
  },
  {
    title: "Messages",
    roles: employeeAdminSupRoles,
    path: privateRoute.clientMessagesPage,
    components: { admin: Messages },
  },
  //    client routes
  {
    roles: [roles.client, roles.cgp],
    path: privateRoute.intro(),
    components: { client: IntroStepPage },
  },
  {
    roles: [roles.client, roles.cgp],
    path: privateRoute.steps(),
    components: { client: StepsPage },
  },
  {
    roles: [roles.client, roles.cgp],
    path: privateRoute.upload(),
    components: { client: UploadPage },
  },
  {
    title: "Documents",
    roles: [...employeeAdminSupRoles, roles.client, roles.cgp],
    path: privateRoute.documentsPage(),
    components: {
      client: ClientLotDocumentPage,
      admin: AdminDocumentPage,
    },
  },
  {
    roles: [roles.client, roles.cgp],
    path: privateRoute.clientWaitingForValidation(),
    components: { client: WaitingForValidation },
  },
  {
    roles: [roles.client, roles.cgp],
    path: privateRoute.clientLotSelectionPage,
    components: { client: ClientLotSelectionPage },
  },
  {
    roles: "*",
    path: privateRoute.clientSetupPage,
    components: { admin: AdminSetupPage, client: ClientSetupPage },
  },
  // Public routes
  {
    path: publicRoute.login,
    components: LoginPage,
  },
  {
    path: publicRoute.forgotPassword,
    components: ForgotPasswordPage,
  },
];
