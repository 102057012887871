import { Stepper, Typography, Button, Icon, Loading } from "components";
import { mailContact } from "config";

export default ({
  currStep,
  title,
  subTitle,
  iconName,
  Comp,
  state,
  files,
  onChange,
  handleCheck,
  handleUpload,
  handleLegalForm,
  updateUser,
  handleNext,
  setCurrStep,
}) =>
  !Comp ? (
    <Loading />
  ) : (
    <div className="container flex flex-col space-x-10 mx-auto mobile:space-x-0">
      <div className="container flex space-x-10 mx-auto mobile:flex-col mobile:space-x-0">
        <div className="w-full mobile:mb-8">
          <div>
            <Typography
              variant="h2"
              color="white"
              className="border-l-4 pl-5 border-tertiary"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <Typography
              variant="subtitle1"
              color="white"
              className="mobile:mb-4 mt-4 mb-12 pl-5"
              dangerouslySetInnerHTML={{ __html: subTitle }}
            />
            <div className="mobile:mt-2 pl-5 mt-12">
              <Icon
                type={iconName}
                color="tertiary"
                customSize={{ width: "auto", height: "auto" }}
              />
            </div>
          </div>
        </div>
        <form method="POST" className="w-full mobile:mt-4">
          <Comp
            state={state}
            files={files}
            onChange={onChange}
            handleCheck={handleCheck}
            handleUpload={handleUpload}
            handleLegalForm={handleLegalForm}
            onNextPress={handleNext}
            updateUser={updateUser}
          />
          {currStep != 5 && (
            <div className="w-full mt-10">
              <Button
                label="SUIVANT"
                color="secondary"
                full
                halfPadding
                className="w-2/4 mobile:w-full"
                onClick={handleNext}
              />
              <Button
                label="SIGNALER UNE ERREUR"
                color="fill-secondary"
                className="mt-6"
                halfPadding
                btnClass="text-xs mobile:w-full"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `mailto:${mailContact}`;
                }}
              />
            </div>
          )}
        </form>
      </div>
      <div className="mt-10">
        <Stepper currStep={currStep} onClick={setCurrStep} />
      </div>
    </div>
  );
