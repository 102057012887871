import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { DashboardLayout } from "layouts";
import { TableDocument, MessageAdmin } from "components";
import { paginateLimit } from "helpers";
import { request } from "services";

const MEMBERS_QUERIES = gql`
  query($copropriete_id: Int) {
    coproprietes(id: $copropriete_id) {
      data {
        id
        nom
        validate
        adresse
        nombre_lots
        nombre_adherents
        documents {
          id
          url
          name
          type
          originalName
          location
          mediable_type
          mediable_id
          created_at
        }
        lots {
          id
          manager {
            id
            nom
            prenom
            user {
              id
              email
            }
          }
        }
      }
    }
    messagePredefinis {
      data {
        id
        titre
        contenu
      }
    }
  }
`;

const CopropertyAddDocument = ({
  match: {
    params: { id },
  },
}) => {
  const { loading, error, data = {}, fetchMore } = useQuery(MEMBERS_QUERIES, {
    fetchPolicy: "network-only",
    variables: {
      page: 1,
      query: "",
      first: paginateLimit,
      copropriete_id: parseInt(id),
    },
  });
  const [state, setState] = useState(data);
  const [selected, setSelected] = useState([]);

  const handlePaginate = (page) =>
    fetchMore({ variables: { page } }).then(({ data }) => setState(data));
  const handleSearch = ({ target: { value } }) =>
    fetchMore({ variables: { query: `%${value}%` } }).then(({ data }) =>
      setState(data)
    );
  const handleSubmit = (data) => {
    request({
      path: `message`,
      body: {
        documents: selected,
        ...data,
        adherent_id: 1,
      },
    }).then(console.log);
  };

  useEffect(() => {
    if (!error) setState(data);
  }, [data, loading]);

  const coproperty = state?.coproprietes?.data
    ? state?.coproprietes?.data[0]
    : null;

  return (
    <>
      {coproperty ? (
        <div>
          <div className="grid gap-6">
            <TableDocument
              isToggleable
              copropertyId={coproperty?.id}
              documents={coproperty?.documents}
              onCheck={setSelected}
              handlePaginate={handlePaginate}
              showMembersList
            />
          </div>
          <MessageAdmin
            predefinedMessage={data.messagePredefinis}
            receiver={coproperty?.lots}
            onSubmit={handleSubmit}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const connectedCopropertyAddDocument = DashboardLayout(CopropertyAddDocument, {
  withSidebar: true,
});
export { connectedCopropertyAddDocument as CopropertyAddDocument };
